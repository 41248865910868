import * as React from "react";

import AddCircleIcon from '@mui/icons-material/AddCircle';
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Grid from "@mui/material/Grid2";
import PropTypes from "prop-types";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";

import FormBox from "../../../components/FormBox";
import FormTextField from '../../../components/FormTextField';
import FormButtonGroup from '../../../components/FormButtonGroup';
import { removeEmptyStrings, useFormSection, } from '../../../components/misc/useForm';


function InputConfig({
    cameraUpdate = {},
    setIsUpdated = () => { },
    hasPermission = false,
    cameraInputConfig = [],
    setCameraInputConfig = () => { },
    cameraEncodingTemplate = {},
    handleChangeCameraEncodingTemplate = () => { },
    videoCodecOptions = [],
    audioCodecOptions = [],
}) {
    /**
     * Parst eine URL, die ggf. mit "playout:" beginnt.
     * Entfernt den "playout:"‑Präfix und liefert ein Objekt mit den
     * einzelnen Bestandteilen der URL zurück.
     */
    function parseAddress(addressStr = "") {
        let isPlayout = false;
        // Prüfen und Präfix entfernen, falls vorhanden
        if (addressStr.startsWith("playout:")) {
            isPlayout = true;
            addressStr = addressStr.slice("playout:".length);
        }
        let isPtz = false;
        // Prüfen und Präfix entfernen, falls vorhanden
        if (addressStr.includes("ptz:")) {
            isPtz = true;
            addressStr = addressStr.slice("ptz:".length);
        }
        try {
            // URL-Klasse verwenden: Die URL muss ein Protokoll haben!
            const urlObj = new URL(addressStr);
            return {
                isPlayout,
                isPtz,
                protocol: urlObj.protocol.replace(":", ""), // z.B. "rtsp"
                username: urlObj.username,
                password: urlObj.password,
                host: urlObj.host,           // Domain + Port, falls vorhanden
                hostname: urlObj.hostname,   // Nur die Domain
                port: urlObj.port,
                pathname: urlObj.pathname,   // Pfad ab der Domain
            };
        } catch (error) {
            // Bei Fehlern einen sinnvollen Default zurückgeben oder den Fehler weiterreichen
            console.error("Fehler beim Parsen der Adresse:", error);
            return {};
        }
    };

    // Hilfsfunktion, um die erste Adresse aus cameraUpdate zu parsen
    function getPrimaryAddress() {
        const addressStr = cameraUpdate.input_config[0]?.address || "";
        return parseAddress(addressStr);
    };

    // Erzeugt die Inputs-Liste, indem für jeden Eintrag die Pfadkomponente genutzt wird
    function generateInputs() {
        return cameraUpdate.input_config.map((item, index) => {
            const { pathname = "" } = parseAddress(item.address);
            return {
                id: index.toString(),
                path: pathname,
            };
        });
    };

    // Hauptfunktion, die die vorbereiteten Eingabedaten zurückliefert
    function prepareInputs() {
        try {
            const parsed = getPrimaryAddress();
            return {
                playout: parsed.isPlayout ? "true" : "false",
                protocol: parsed.protocol || "rtsp",
                hostname: parsed.hostname || "",
                port: parsed.port || "",
                user: parsed.username || "",
                password: parsed.password || "",
                ptz_enabled: parsed.isPtz,
                inputs: generateInputs(),
            };
        } catch (error) {
            console.error("Fehler in prepareInputs:", error);
            return {
                playout: "false",
                protocol: "rtsp",
                hostname: "",
                port: "",
                user: "",
                password: "",
                ptz_enabled: false,
                inputs: [],
            };
        }
    };

    React.useEffect(() => {
        const updatedInputs = prepareInputs();
        if (cameraInputConfig !== updatedInputs) {
            setCameraInputConfig((prev) => {
                return updatedInputs
            });
        }
    }, [cameraUpdate]);

    const handleChange = (e) => {
        const { name, value, type } = e.target;
        setCameraInputConfig((prev) => {
            const updated = { ...prev };
            if (type === "checkbox") {
                updated[name] = e.target.checked;
            } else {
                updated[name] = value;
            }
            return updated;
        });
        setIsUpdated(true);
    };

    const handleChangeInput = (index, e) => {
        setCameraInputConfig((prev) => {
            const updated = { ...prev };
            updated.inputs[index] = {
                ...updated.inputs[index],
                [e.target.name]: e.target.value
            };
            return updated;
        });
        setIsUpdated(true);
    };

    const handleCreate = () => {
        setCameraInputConfig((prev) => {
            const updated = { ...prev };
            updated.inputs.push({
                id: updated.inputs.length.toString(),
                path: "",
            });
            return updated;
        });
    }

    const handleDelete = (index) => () => {
        setCameraInputConfig((prev) => {
            const updated = { ...prev };
            updated.inputs.splice(index, 1);
            return updated;
        });
        setIsUpdated(true);
    }

    const protocolOptions = [
        { value: "https", label: "HTTPS" },
        { value: "rtsp", label: "RTSP" },
        { value: "rtmp", label: "RTMP" },
        { value: "rtmps", label: "RTMPS" },
        { value: "srt", label: "SRT" },
    ];

    const playoutOptions = [
        { value: "true", label: "Aktiv" },
        { value: "false", label: "Ohne" },
    ];

    const cameraProducer = [
        { value: "axis", label: "Axis Communication" },
        { value: "hikvision", label: "Hikvision" },
        { value: "hanwha", label: "Hanwha (SamsungTechwin Europe)" },
        { value: "bosch", label: "Bosch" },
        { value: "dahua", label: "Dahua" },
        { value: "eneo", label: "Eneo" },
        { value: "vivotek", label: "Vivotek" },
        { value: "hyundai", label: "Hyundai" },
        { value: "instar", label: "Instar" },
        { value: "other", label: "Andere" },
    ];

    const cameraProducerPlaceholder = [
        { value: "axis", label: "/axis-media/media.amp" },
        { value: "hikvision", label: "/Streaming/Channels/101" },
        { value: "hanwha", label: "/profile1/media.smp" },
        { value: "bosch", label: "/" },
        { value: "dahua", label: "/cam/realmonitor?channel=1&subtype=0" },
        { value: "eneo", label: "/1/stream1" },
        { value: "vivotek", label: "/live.sdp" },
        { value: "hyundai", label: "/H264" },
        { value: "instar", label: "/h264" },
        { value: "other", label: "" },
    ];

    if (!cameraInputConfig) {
        return null;
    }

    return (
        <>
            <Stack
                direction="column"
                spacing={1}
                sx={{
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    width: "100%",
                }}
            >
                <FormTextField
                    name="playout"
                    label="Ausfallschutz"
                    object={cameraInputConfig}
                    handleChange={handleChange}
                    hasPermission={hasPermission}
                    select
                    selectOptions={playoutOptions}
                    required
                />
                <Stack
                    direction="row"
                    spacing={1}
                    sx={{
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        width: "100%",
                    }}
                >
                    <FormTextField
                        name="video_codec"
                        label="Videocodec"
                        object={cameraEncodingTemplate}
                        handleChange={handleChangeCameraEncodingTemplate}
                        hasPermission={hasPermission}
                        required
                        select
                        selectOptions={videoCodecOptions}
                    />
                    <FormTextField
                        name="audio_codec"
                        label="Audiocodec"
                        object={cameraEncodingTemplate}
                        handleChange={handleChangeCameraEncodingTemplate}
                        hasPermission={hasPermission}
                        required
                        select
                        selectOptions={audioCodecOptions}
                    />
                </Stack>
                <FormTextField
                    name="protocol"
                    label="Protokoll"
                    object={cameraInputConfig}
                    handleChange={handleChange}
                    hasPermission={hasPermission}
                    required
                    select
                    selectOptions={protocolOptions}
                />
                <Stack
                    direction="row"
                    spacing={1}
                    sx={{
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        width: "100%",
                    }}
                >
                    <FormTextField
                        name="hostname"
                        label="Hostname"
                        object={cameraInputConfig}
                        handleChange={handleChange}
                        hasPermission={hasPermission}
                        required
                        error={cameraInputConfig.hostname === "" || cameraInputConfig.hostname.includes("/") || cameraInputConfig.hostname.includes(":")}
                        helperText={cameraInputConfig.hostname === "" ? "Hostname ist erforderlich" : cameraInputConfig.hostname.includes("/") || cameraInputConfig.hostname.includes(":") ? "Ungültiger Hostname" : ""}
                    />
                    <FormTextField
                        name="port"
                        label="Port"
                        object={cameraInputConfig}
                        handleChange={handleChange}
                        hasPermission={hasPermission}
                        type="number"
                        sx={{ width: 75 }}
                    />
                </Stack>
                <Stack
                    direction="row"
                    spacing={1}
                    sx={{
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        width: "100%",
                    }}
                >
                    <FormTextField
                        name="user"
                        label="Benutzer"
                        object={cameraInputConfig}
                        handleChange={handleChange}
                        hasPermission={hasPermission}
                        maxLength={200}
                    />
                    <FormTextField
                        name="password"
                        label="Passwort"
                        object={cameraInputConfig}
                        handleChange={handleChange}
                        hasPermission={hasPermission}
                        maxLength={200}
                        password
                    />
                </Stack>
                <FormGroup>
                    <FormControlLabel
                        name="ptz_enabled"
                        label="PTZ-Koordinaten erfassen"
                        onChange={handleChange}
                        readOnly={!hasPermission}
                        disabled={cameraInputConfig.playout === "false"}
                        control={
                            <Checkbox
                                checked={cameraInputConfig.ptz_enabled}
                                color={"blue"}
                            />
                        }
                    />
                </FormGroup>
            </Stack>
            <Typography fontSize={16} fontWeight={500} marginTop={1}>
                Bitraten / Profile
            </Typography>
            {cameraInputConfig.inputs.map((item, index) => (
                <Stack
                    key={index}
                    direction="row"
                    spacing={2}
                    sx={{
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                        width: "100%",
                        paddingBottom: 1,
                        padding: "15px 20px 15px 20px",
                        //backgroundColor: 'rgba(0,0,0,.045)',
                        border: '1px solid rgba(0,0,0,.165)',
                        borderRadius: 1,
                    }}
                >
                    <Stack
                        direction="row"
                        spacing={1}
                        sx={{
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                            width: "100%",
                        }}
                    >
                        <FormTextField
                            name="path"
                            label="Pfad"
                            object={item}
                            handleChange={(e) => handleChangeInput(index, e)}
                            hasPermission={hasPermission}
                            maxLength={200}
                            required
                            placeholder="/axis-media/media.amp"
                        />
                    </Stack>
                    {hasPermission && (
                        <Stack
                            direction="row"
                            spacing={2}
                            sx={{
                                justifyContent: "flex-start",
                                alignItems: "flex-start",
                                paddingTop: .4,
                            }}
                        >
                            <IconButton aria-label="delete">
                                <DeleteIcon onClick={handleDelete(index)} />
                            </IconButton>
                        </Stack>
                    )}
                </Stack>
            ))}
            <Stack
                direction="row"
                spacing={1}
                sx={{
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                }}
            >
                <Button
                    onClick={handleCreate}
                    size="small"
                    sx={{
                        background: '#fff',
                        border: '1px solid rgba(0,0,0,.2)',
                        color: '#0085E9',
                        "&:hover": {
                            color: '#fff',
                            background: '#0085E9',
                        }
                    }}
                >
                    <AddCircleIcon sx={{ marginRight: 1 }} /> Neu
                </Button>
            </Stack>
        </>
    );
}


function CameraStreaming({
    camera = {},
    ingest = {},
    updateCamera = () => { },
    hasPermission = false,
    playerConfig,
}) {
    const cameraDefault = {
        mode: ingest?.mode || "active",
        service: ingest?.service || "rtmp",
        token: ingest?.token || generateHexToken(),
        encoding_template: ingest?.encoding_template || "h264_copy_virtual_aac",
        streaming_format: ingest?.streaming_format || ["hls"],
        input_config: ingest?.input_config || [],
    };

    const [cameraInputConfig, setCameraInputConfig] = React.useState(null);

    const {
        isUpdated,
        setIsUpdated,
        formUpdate: cameraUpdate,
        setFormUpdate: setCameraUpdate,
        handleChange,
        handleReset,
    } = useFormSection({
        formDefault: cameraDefault,
    });

    const [cameraEncodingTemplate, setCameraEncodingTemplate] = React.useState({});

    const exportEncodingTemplateCodecs = () => {
        // const encoding_template = [
        //     { value: "h264_copy_virtual_aac", label: "Video H.264" },
        //     { value: "h264_h264_virtual_aac", label: "Video H.264 (TV)" },
        //     { value: "h264_copy_aac_copy", label: "Video H.264, Audio AAC" },
        //     { value: "h265_copy_virtual_aac", label: "Video H.265" },
        //     { value: "h265_h264_virtual_aac", label: "Video H.265 (TV)" },
        //     { value: "h265_h264_aac_copy", label: "Video H.265, Audio AAC" },
        // ];

        function getVideoCodec() {
            const template = cameraUpdate.encoding_template;
            if (template.includes("h264_copy") || template.includes("h264_h264")) {
                return "h264";
            } else if (template.includes("h265_copy") || template.includes("h265_h264")) {
                return "h265";
            } else {
                return "h264";
            }
        };

        function getAudioCodec() {
            const template = cameraUpdate.encoding_template;
            if (template.includes("aac_copy")) {
                return "aac";
            } else {
                return "none";
            }
        };

        return {
            video_codec: getVideoCodec(),
            audio_codec: getAudioCodec(),
        };
    };

    const handleChangeCameraEncodingTemplate = (e) => {
        const { name, value } = e.target;
        setCameraEncodingTemplate((prev) => {
            const updated = { ...prev };
            if (value.includes("_tv")) {
                updated.audio_codec = "none";
                updated[name] = value;
            } else if (value.includes("aac") && updated.video_codec.includes("_tv")) {
                updated.audio_codec = "none";
            } else {
                updated[name] = value;
            }
            return updated;
        });
        setIsUpdated(true);
    };

    React.useEffect(() => {
        const updated = exportEncodingTemplateCodecs();
        if (cameraEncodingTemplate !== updated) {
            setCameraEncodingTemplate((prev) => {
                return updated;
            });
        }
    }, [cameraUpdate]);

    const updateEncodingTemplate = () => {
        const config = cameraEncodingTemplate;
        if (config.video_codec === "h264") {
            if (config.audio_codec === "none") {
                return "h264_copy_virtual_aac";
            } else if (config.audio_codec === "aac") {
                return "h264_copy_aac_copy";
            }
        } else if (config.video_codec === "h264_tv") {
            if (config.audio_codec === "none") {
                return "h264_h264_virtual_aac";
            } else if (config.audio_codec === "aac") {
                return "h264_h264_aac_copy";
            }
        } else if (config.video_codec === "h265") {
            if (config.audio_codec === "none") {
                return "h265_copy_virtual_aac";
            } else if (config.audio_codec === "aac") {
                return "h265_copy_aac_copy";
            }
        } else if (config.video_codec === "h265_tv") {
            if (config.audio_codec === "none") {
                return "h265_h264_virtual_aac";
            } else if (config.audio_codec === "aac") {
                return "h265_h264_aac_copy";
            }
        } else {
            return "h264_copy_virtual_aac";
        }
    };

    const updateInputConfig = () => {
        const config = cameraInputConfig;
        return config.inputs.map((item, index) => {
            return {
                id: index.toString(),
                address: `${config.playout === "true" ? "playout:" : ""}${(config.playout === "true" && config.ptz_enabled) ? "ptz:" : ""}${config.protocol}://${(config.user !== "" && config.password !== "") ? config.user + ':' + config.password + '@' : ""}${config.hostname}${config.port !== "" ? ':' + config.port : ""}${item.path}`,
                options: [],
                cleanup: null,
            };
        });
    };

    async function handleUpdate() {
        const update = { ...cameraUpdate };
        update.encoding_template = updateEncodingTemplate();
        update.input_config = updateInputConfig();
        updateCamera({
            camera_id: camera.id,
            camera: { ingest: removeEmptyStrings(update) }
        });
        setIsUpdated(false);
    }

    function generateHexToken(sizeInBytes = 18) {
        const randomBytes = new Uint8Array(sizeInBytes);
        window.crypto.getRandomValues(randomBytes);
        const token = Array.from(randomBytes, (byte) =>
            byte.toString(16).padStart(2, "0")
        ).join("");
        return token;
    }

    // {ingest.service}://wettercom-c1.livespotting.com/{cid}/{camera_id}.stream/{camera_id}_token:{token}
    const ingestingEndpointUrl = () => {
        if (cameraUpdate.mode === "passive") {
            const url = playerConfig.url.replace(
                "https",
                cameraUpdate.service
            ).replace("/memfs", "") + camera.id + ".stream/" + camera.id + "_token:" + cameraUpdate.token;
            return url;
        }
        return "";
    }

    const mode = [
        { value: "active", label: "Stream abrufen" },
        { value: "passive", label: "Stream empfangen" },
    ];

    const service = [
        { value: "rtmp", label: "RTMP" },
        // { value: "srt", label: "SRT" },
    ];

    const streaming_format = [
        { value: "hls", label: "HTTP Streaming (HLS)" },
        { value: "rtmp", label: "RTMP" },
        { value: "srt", label: "SRT" },
    ];

    const videoCodecOptions = [
        { value: "h264", label: "H.264" },
        { value: "h264_tv", label: "H.264 (TV)" },
        { value: "h265", label: "H.265/HEVC" },
        { value: "h265_tv", label: "H.265/HEVC (TV)" },
    ];

    const audioCodecOptions = [
        { value: "none", label: "Ohne" },
        { value: "aac", label: "AAC" },
    ];

    return (
        <>
            <Grid
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={1}
                width={"100%"}
            >
                <FormBox>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={1}
                        width={"100%"}
                    >
                        <Grid item size={12}>
                            <FormTextField
                                name="mode"
                                label="Modus"
                                object={cameraUpdate}
                                handleChange={handleChange}
                                hasPermission={hasPermission}
                                select
                                selectOptions={mode}
                            />
                        </Grid>
                        <Grid item size={12}>
                            <FormTextField
                                name="streaming_format"
                                label="Streaming Format"
                                object={cameraUpdate}
                                handleChange={handleChange}
                                hasPermission={hasPermission}
                                multiSelect
                                select
                                selectOptions={streaming_format}
                            />
                        </Grid>
                    </Grid>
                </FormBox>
                {cameraUpdate.mode === "active" && (
                    <>
                        <Grid item size={12}>
                            <Typography fontSize={16} fontWeight={500} marginTop={1}>
                                Einstellungen für den Abruf
                            </Typography>
                        </Grid>
                        <FormBox>
                            <InputConfig
                                cameraUpdate={cameraUpdate}
                                setCameraUpdate={setCameraUpdate}
                                setIsUpdated={setIsUpdated}
                                hasPermission={hasPermission}
                                cameraInputConfig={cameraInputConfig}
                                setCameraInputConfig={setCameraInputConfig}
                                cameraEncodingTemplate={cameraEncodingTemplate}
                                handleChangeCameraEncodingTemplate={handleChangeCameraEncodingTemplate}
                                videoCodecOptions={videoCodecOptions}
                                audioCodecOptions={audioCodecOptions}
                            />
                        </FormBox>
                    </>
                )}
                {cameraUpdate.mode && cameraUpdate.mode === "passive" && (
                    <>
                        <Grid item size={12}>
                            <Typography fontSize={16} fontWeight={500} marginTop={1}>
                                Einstellungen für den Empfang
                            </Typography>
                        </Grid>
                        <FormBox>
                            <Grid item size={12}>
                                <FormTextField
                                    name="service"
                                    label="Protokoll"
                                    object={cameraUpdate}
                                    handleChange={handleChange}
                                    hasPermission={hasPermission}
                                    select
                                    selectOptions={service}
                                />
                            </Grid>
                            <Grid item size={12}>
                                <Stack direction="row" spacing={1}>
                                    <FormTextField
                                        name="video_codec"
                                        label="Videocodec"
                                        object={cameraEncodingTemplate}
                                        handleChange={handleChangeCameraEncodingTemplate}
                                        hasPermission={hasPermission}
                                        required
                                        select
                                        selectOptions={videoCodecOptions}
                                    />
                                    <FormTextField
                                        name="audio_codec"
                                        label="Audiocodec"
                                        object={cameraEncodingTemplate}
                                        handleChange={handleChangeCameraEncodingTemplate}
                                        hasPermission={hasPermission}
                                        required
                                        select
                                        selectOptions={audioCodecOptions}
                                    />
                                </Stack>
                            </Grid>
                            <Grid item size={12}>
                                <FormTextField
                                    name="token"
                                    label="Token"
                                    object={cameraUpdate}
                                    handleChange={handleChange}
                                    hasPermission={hasPermission}
                                    maxLength={200}
                                    password
                                />
                            </Grid>
                            <Grid item size={12}>
                                <TextField
                                    label="Stream Endpunkt"
                                    value={ingestingEndpointUrl()}
                                    disabled={ingestingEndpointUrl() === ""}
                                    size="small"
                                    variant="standard"
                                    fullWidth
                                    slotProps={{
                                        input: {
                                            readOnly: true,
                                        },
                                        inputLabel: {
                                            shrink: true
                                        }
                                    }}
                                />
                            </Grid>
                        </FormBox>
                    </>
                )}
            </Grid>
            {hasPermission && isUpdated && (
                <Stack
                    direction="row"
                    spacing={1}
                    sx={{
                        justifyContent: "flex-end",
                        alignItems: "center",
                        width: "100%",
                        paddingTop: 2,
                    }}
                >
                    <FormButtonGroup
                        handleReset={handleReset}
                        handleUpdate={handleUpdate}
                    />
                </Stack>
            )}
        </>
    );
}

CameraStreaming.propTypes = {
    camera: PropTypes.object.isRequired,
    playerConfig: PropTypes.object.isRequired,
};

export default CameraStreaming;

import * as React from "react";

import IconButton from "@mui/material/IconButton";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import CloseIcon from "@mui/icons-material/Close";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Divider from "@mui/material/Divider";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import PropTypes from "prop-types";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const CopyURLButton = () => {
    const [tooltipOpen, setTooltipOpen] = React.useState(false);
    const [tooltipText, setTooltipText] = React.useState("Kopieren");

    const handleCopyClick = () => {
        const currentURL = window.location.href;

        navigator.clipboard
            .writeText(currentURL)
            .then(() => {
                setTooltipText("Kopiert");
                setTooltipOpen(true);
                setTimeout(() => {
                    setTooltipOpen(false);
                    setTooltipText("Kopieren");
                }, 2000);
            })
            .catch((error) => {
                setTooltipText("Kopieren");
            });
    };

    return (
        <Tooltip title={tooltipText} arrow open={tooltipOpen} placement="top">
            <IconButton
                size="small"
                onClick={handleCopyClick}
                onMouseEnter={() => setTooltipOpen(true)}
                onMouseLeave={() => setTooltipOpen(false)}
            >
                <ContentCopyIcon fontSize="inherit" />
            </IconButton>
        </Tooltip>
    );
};

function PageSidebarHeader({
    title = null,
    fullScreen = false,
    handleFullscreenToggle = null,
    handleToggle = null,
    share = true,
    submenu = null,
    chips = null,
    player = null,
}) {
    // NO FULLSCREEN OPTION ON MOBILE
    const theme = useTheme();
    const upSm = useMediaQuery(theme.breakpoints.up("sm"));

    return (
        <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={0}
            padding={"10px 20px 5px 20px"}
            width={"100%"}
        >
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
                width={"100%"}
                padding="5px 0px 15px 0px"
            >
                <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={2}
                    width={"100%"}
                >
                    {handleFullscreenToggle && !fullScreen && upSm && (
                        <IconButton
                            size="small"
                            onClick={handleFullscreenToggle}
                        >
                            <OpenInFullIcon />
                        </IconButton>
                    )}
                    {handleFullscreenToggle && fullScreen && upSm && (
                        <IconButton
                            size="small"
                            onClick={handleFullscreenToggle}
                        >
                            <CloseFullscreenIcon />
                        </IconButton>
                    )}
                    {handleToggle && (
                        <IconButton size="small" onClick={handleToggle}>
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    )}
                    {share &&(
                        <CopyURLButton />
                    )}
                </Stack>
                {submenu}
            </Stack>
            <Divider sx={{width: "100%", marginBottom: 1, border: "1px solid rgba(0,0,0,.1)", borderTop: "1px solid rgba(0,0,0,.2)"}} />
            {title && (
                <Stack
                    direction="row"
                    alignItems="center"
                    spacing={2}
                    sx={{
                        width: "100%",
                        padding: "10px 0px 15px 0px"
                    }}
                >
                    <Typography 
                        component="span" 
                        color="blue" 
                        fontSize={20}
                        fontWeight={500}
                    >
                        {title}
                    </Typography>
                </Stack>
            )}
            {chips && (
                <Stack
                    direction="row"
                    alignItems="center"
                    spacing={1}
                    justifyContent={'flex-start'}
                    sx={{
                        width: "100%",
                        padding: "0px 0px 10px 0px"
                    }}
                >
                    {chips}
                </Stack>
            )}
            {player && (
                <Stack
                    direction="row"
                    spacing={0}
                    sx={{
                        position: "relative",
                        width: "100%",
                        padding: "0px 0px 5px 0px"
                    }}
                >
                    {player}
                </Stack>
            )}
        </Stack>
    );
}

PageSidebarHeader.propTypes = {
    title: PropTypes.string,
    
    handleToggle: PropTypes.func,
    sidebarFullScreen: PropTypes.bool,
    handleFullscreenToggle: PropTypes.func,
    share: PropTypes.bool,
    submenu: PropTypes.node,
    chips: PropTypes.node,
    player: PropTypes.node,
};

export default PageSidebarHeader;

import * as React from "react";
import PropTypes from "prop-types";

import "moment-timezone";

import { Divider, Stack, TextField } from "@mui/material";

import CameraStatusChip from "../../../components/CameraStatusChip";
import FormBox from "../../../components/FormBox";
import PageSidebarHeader from "../../../components/PageSidebarHeader";
import SidebarAccordion from "../../../components/SidebarAccordion";
import VideoJS from "../../../components/VideoJS";

import PlayoutEventsForm from './PlayoutEventsForm';
import PlayoutMetadataForm from "./PlayoutMetadataForm";
import PlayoutLogoForm from "./PlayoutLogoForm";
import PlayoutTemplateForm from "./PlayoutTemplateForm";
import PlayoutStream from "./PlayoutStream";


function PlayoutSidebar({ 
    drawerWidth = "100%", 
    playout = null, 
    updatePlayout = () => {},
    playerConfig= {},
    cameras = null,
    hasPermission = false,
    openSidebar = false,
    handleSidebarToggle = () => {},
}) {

    // VIDEOJS
    const [videoJsOptions, setVideoJsOptions] = React.useState(null);
    React.useEffect(() => {
        if (openSidebar) {
            const options = {
                autoplay: true,
                controls: true,
                responsive: true,
                fluid: true,
                poster: playerConfig.url + playout.camera_id + "/live/main_240.jpg",
                playsinline: true,
                muted: true,
                sources: [
                    {
                        src: playerConfig.url + playout.camera_id + `/live/main.m3u8?token=` + playerConfig.token,
                        type: "application/vnd.apple.mpegurl",
                    },
                ],
            };
            if (videoJsOptions !== options) {
                setVideoJsOptions(options);
            };
        } else {
            setVideoJsOptions(null);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [playout, playerConfig, openSidebar]);

    const playoutCamera = () => {
        if (playout && playout.camera_id && cameras) {
            return cameras.find((camera) => camera.id === playout.camera_id);
        }
        return null;
    }

    return (
        <Stack
            direction="column"
            spacing={0}
            sx={{
                justifyContent: "flex-start",
                alignItems: "flex-start",
                width: "100%",
                paddingBottom: 4,
            }}
        >
            <PageSidebarHeader
                title={playout.name}
                share={true}
                handleToggle={handleSidebarToggle}
                //submenu={}
                chips={
                    <CameraStatusChip camera={playoutCamera()} />
                }
                player={
                    videoJsOptions && <VideoJS key={playout.id} {...videoJsOptions} />
                }
            />  
            <Stack
                direction="column"
                spacing={1}
                sx={{
                    width: "100%",
                    paddingBottom: 2,
                    paddingLeft: 2.5,
                    paddingRight: 2.5,
                }}
            >
                <FormBox>
                    <TextField
                        name="id"
                        label="ID"
                        value={playout.id}
                        readOnly
                        size="small"
                        variant="standard"
                        fullWidth
                    />
                </FormBox>
            </Stack>
            <Divider sx={{marginTop: 0, marginBottom: 0}}/>
            <SidebarAccordion
                first
                expanded
                title="Streammonitor"
                content={
                    playoutCamera() && (
                        <PlayoutStream camera={playoutCamera()} />
                    )
                }
            />
            <SidebarAccordion
                title="Metadaten"
                content={
                    <FormBox>
                        <PlayoutMetadataForm
                            playout={playout}
                            updatePlayout={updatePlayout}
                            hasPermission={hasPermission}
                        />
                    </FormBox>
                }
            />
            <SidebarAccordion
                title="Sendeplan"
                content={
                    <PlayoutEventsForm
                        playout={playout} 
                        updatePlayout={updatePlayout} 
                        hasPermission={hasPermission}
                    />
                }
            />
            <SidebarAccordion
                title="Template"
                content={
                    <FormBox>
                        <PlayoutTemplateForm
                            playout={playout}
                            updatePlayout={updatePlayout}
                            hasPermission={hasPermission}
                        />
                    </FormBox>
                }
            />
            <SidebarAccordion
                last
                title="Logo"
                content={
                    <FormBox>
                        <PlayoutLogoForm
                            playout={playout}
                            updatePlayout={updatePlayout}
                            hasPermission={hasPermission}
                        />
                    </FormBox>
                }
            />
        </Stack>
            
    );
}

PlayoutSidebar.propTypes = {
    drawerWidth: PropTypes.number,
    playout: PropTypes.object.isRequired,
    playerConfig: PropTypes.object.isRequired,
};

PlayoutSidebar.defaultProps = {
    drawerWidth: 450,
};

export default PlayoutSidebar;

import React from "react";
import { Button, IconButton, Stack } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from '@mui/icons-material/AddCircle';

import FormButtonGroup from "../../../components/FormButtonGroup";
import FormTextField from '../../../components/FormTextField';
import { useFormSection, validHttpUrl, validTimeFormat } from '../../../components/misc/useForm';


export default function PlayoutEventsForm({
    playout = {},
    updatePlayout = () => {},
    hasPermission = false,
}) {
    const playerDefault = [...playout.events] || [];
    const { 
        isUpdated, 
        setIsUpdated, 
        formUpdate: playoutUpdate,
        setFormUpdate: setPlayoutUpdate,
        handleReset,
    } = useFormSection({
        formDefault: playerDefault,
    });

    const handleChange = (index, e) => {
        setPlayoutUpdate((prevPlayoutUpdate) => {
            const updated = [...prevPlayoutUpdate];
            updated[index] = { 
                ...updated[index], 
                [e.target.name]: e.target.value
            };
            return updated;
        });
        setIsUpdated(true);
    };

    async function handleUpdate() {
        updatePlayout({
            playout_id: playout.id, 
            playout: {events: playoutUpdate}
        });
        setIsUpdated(false);
    }

    function updateValidation() {
        for (const item of playoutUpdate) {
            if (!validTimeFormat(item.start_time) || !validTimeFormat(item.end_time)) {
                return true;
            }
        }
        return false;
    }

    const handleCreate = () => {
        setPlayoutUpdate((prevPlayoutUpdate) => {
            const updated = [...prevPlayoutUpdate];
            updated.push({
                name: "",
                website: "",
                start_time: "",
                end_time: "",
            });
            return updated;
        });
    }

    const handleDelete = (index) => () => {
        setPlayoutUpdate((prevPlayoutUpdate) => {
            const updated = [...prevPlayoutUpdate];
            updated.splice(index, 1);
            return updated;
        });
        setIsUpdated(true);
    }

    return (
        <Stack spacing={2}>
            {playoutUpdate.map((item, i) => (
                <Stack
                    direction="row"
                    spacing={2}
                    sx={{
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                        width: "100%",
                        paddingBottom: 1,
                        padding: "15px 20px 15px 20px",
                        backgroundColor: 'rgba(0,0,0,.045)',
                        borderRadius: 1,
                    }}
                >
                    <Stack
                        direction="column"
                        spacing={1}
                        sx={{
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                            width: "100%",
                        }}
                    >
                        <FormTextField
                            name="name"
                            label="Name"
                            object={item}
                            handleChange={(e) => handleChange(i, e)}
                            hasPermission={hasPermission}
                            maxLength={100}
                            required
                            error={item.name === ""}
                            helperText={item.name === "" ? "Bitte einen Namen eingeben" : ""}
                        />
                        <FormTextField
                            name="website"
                            label="Webseite"
                            object={item}
                            handleChange={(e) => handleChange(i, e)}
                            hasPermission={hasPermission}
                            maxLength={100}
                            required
                            error={!validHttpUrl(item.website)}
                            helperText={!validHttpUrl(item.website) ? "Bitte eine gültige URL eingeben" : ""}
                        />
                        <Stack
                            direction="row"
                            spacing={1}
                            sx={{
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "100%",
                            }}
                        >
                            <FormTextField
                                name="start_time"
                                label="Startzeit"
                                object={item}
                                handleChange={(e) => handleChange(i, e)}
                                hasPermission={hasPermission}
                                maxLength={30}
                                required
                                error={!validTimeFormat(item.start_time)}
                                helperText={!validTimeFormat(item.start_time) ? "Bitte eine gültige Zeit im Format HH:MM eingeben" : ""}
                            />
                            <FormTextField
                                name="end_time"
                                label="Endzeit"
                                object={item}
                                handleChange={(e) => handleChange(i, e)}
                                hasPermission={hasPermission}
                                maxLength={30}
                                required
                                error={!validTimeFormat(item.end_time)}
                                helperText={!validTimeFormat(item.end_time) ? "Bitte eine gültige Zeit im Format HH:MM eingeben" : ""}
                            />
                        </Stack>
                    </Stack>
                    {hasPermission && (
                        <Stack
                            direction="row"
                            spacing={2}
                            sx={{
                                justifyContent: "flex-start",
                                alignItems: "flex-start",
                                paddingTop: .4,
                            }}
                        >
                            <IconButton aria-label="delete">
                                <DeleteIcon onClick={handleDelete(i)} />
                            </IconButton>
                        </Stack>
                    )}
                </Stack>
            ))}
            {hasPermission && (
                <Stack
                    direction="row"
                    spacing={1}
                    sx={{
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                    }}
                >
                    <Button
                        onClick={handleCreate}
                        size="small"
                        sx={{
                            background: '#fff',
                            border: '1px solid rgba(0,0,0,.2)',
                            color: '#0085E9',
                            "&:hover": {
                                color: '#fff',
                                background: '#0085E9',
                            }
                        }}
                    >
                        <AddCircleIcon sx={{marginRight: 1}} /> Neu
                    </Button>
                    {isUpdated && (
                        <FormButtonGroup 
                            handleReset={handleReset}
                            handleUpdate={handleUpdate}
                            updateValidation={updateValidation()}
                        />
                    )}
                </Stack>
            )}
        </Stack>
    );
}

import * as React from "react";

import AccountBoxIcon from '@mui/icons-material/AccountBox';
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import LogoutIcon from '@mui/icons-material/Logout';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";

import UserDialog from "./UserDialog";

import { useApp } from "../../context/App";


export default function UserButton() {
    const { client } = useApp();
    const user = client.UserConfig();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [openDialog, setOpenDialog] = React.useState(false);
    const handleClickOpenDialog = () => {
        if (user && (user.type === "user" || user.type === "superuser")) {
            setOpenDialog(true);
            setAnchorEl(null);
        }
    };
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const logout = async () => {
        await client.logout();
    };

    return (
        <>
            <Avatar
                variant="rounded"
                src={user.avatar_url ? user.avatar_url : null}
                children={user.avatar_url ? null : `${user.first_name[0]}${user.last_name[0]}`}
                onClick={handleOpen}
                aria-controls={open ? 'user-button' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                sx={{
                    width: 48,
                    height: 48,
                    borderRadius: 2,
                    cursor: "pointer",
                    fontSize: 22,
                    fontWeight: 500,
                    color: user.avatar_url ? "white" : "#0085E9",
                    backgroundColor: user.avatar_url ? "black" : "rgba(0,0,0,.065)",
                    "&:hover": {
                        backgroundColor: user.avatar_url ? "black" : "rgba(0,0,0,0.15)",
                    },
                    "& .MuiAvatar-img": {
                        "&:hover": {
                            opacity: .75,
                        },
                    },
                }}
            />
            <Menu
                id="user-menu"
                aria-labelledby="user-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                sx={{
                    "& .MuiPaper-root": {
                        marginLeft: 2,
                        marginTop: -3,
                    },
                }}
            >
                <MenuList size="small">
                    <MenuItem onClick={handleClickOpenDialog}>
                        <ListItemIcon
                            sx={{
                                color: "inherit",
                                "& :selected": {
                                    color: "inherit",
                                },
                            }}
                        >
                            <AccountBoxIcon />
                        </ListItemIcon>
                        <ListItemText>Profil</ListItemText>
                    </MenuItem>
                    <Divider style={{ borderTop: 1 }} />
                    <MenuItem onClick={logout}>
                        <ListItemIcon
                            sx={{
                                color: "inherit",
                                "& :selected": {
                                    color: "inherit",
                                },
                            }}
                        >
                            <LogoutIcon />
                        </ListItemIcon>
                        <ListItemText>Abmelden</ListItemText>
                    </MenuItem>
                </MenuList>
            </Menu>
            <UserDialog
                user={user}
                open={openDialog}
                handleClose={handleCloseDialog}
            />
        </>
    );
}

UserButton.propTypes = {};

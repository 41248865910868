import * as React from 'react';

import 'moment-timezone';
import moment from 'moment';
import 'moment/locale/de';

import CircularProgress from '@mui/material/CircularProgress';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import HelpIcon from '@mui/icons-material/Help';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import CameraStreamStats from './CameraStreamStats';

import { useApp } from '../../../context/App';

const StreamDetail = ({ stream }) => {
	let status = 'ok';
	let message = 'Alles in Ordnung.';

	const resolutions = {
		'320x180': { bitrate_ok: 90, bitrate_bad: 50, recommended: 128 },
		'426x240': { bitrate_ok: 200, bitrate_bad: 100, recommended: 256 },
		'640x360': { bitrate_ok: 480, bitrate_bad: 300, recommended: 512 },
		'800x450': { bitrate_ok: 850, bitrate_bad: 650, recommended: 1024 },
		'854x480': { bitrate_ok: 850, bitrate_bad: 650, recommended: 1024 },
		'1280x720': { bitrate_ok: 1000, bitrate_bad: 500, recommended: 2048 },
		'1024x768': { bitrate_ok: 1000, bitrate_bad: 500, recommended: 2048 },
		'1920x1056': { bitrate_ok: 3000, bitrate_bad: 2000, recommended: 4096 },
		'1920x1080': { bitrate_ok: 3000, bitrate_bad: 2000, recommended: 4096 },
		'2560x1440': { bitrate_ok: 5000, bitrate_bad: 3000, recommended: 6144 },
		'2688x1520': { bitrate_ok: 5000, bitrate_bad: 3000, recommended: 6144 },
		'1088x1920': { bitrate_ok: 3000, bitrate_bad: 2000, recommended: 4096 },
		'3840x2160': { bitrate_ok: 10000, bitrate_bad: 8000, recommended: 12288 },
		NonexNone: { bitrate_ok: 1, bitrate_bad: 0, recommended: 1 },
	};

	if (stream.fps < 15) {
		status = 'fps';
		message = 'Es werden mindestens 15 Bilder pro Sekunde (FPS) benötigt.';
	} else if (resolutions[stream.resolution]) {
		const res = resolutions[stream.resolution];
		if (stream.bitrate_kbits < res.bitrate_bad) {
			status = 'bitrate_bad';
			message = `Bei ${stream.resolution} Pixeln werden ${res.recommended} kbit/s empfohlen.`;
		} else if (stream.bitrate_kbits < res.bitrate_ok) {
			status = 'bitrate_ok';
			message = `Bei ${stream.resolution} Pixeln werden ${res.recommended} kbit/s empfohlen.`;
		}
	} else {
		status = 'resolution';
		message = 'Die Auflösung wird nicht unterstützt.';
	}
	return (
		<Grid
			container
			direction="row"
			justifyContent="flex-start"
			alignItems="flex-start"
			style={{
				//marginTop: 5,
				//backgroundColor: stream.profile !== 'tv' ? 'rgba(0,0,0,.035)' : 'rgba(29,173,34,.15)',
				borderRadius: 4,
				whiteSpace: 'break-spaces!important',
			}}
		>
			<Grid
				item
				xs={2.8}
				style={{
					padding: '10px 0px 0px 0px',
					whiteSpace: 'break-spaces!important',
					borderRadius: '4px 0 0 4px',
				}}
			>
				<Typography variant="body2" color="text.secondary" fontSize={11}>
					{stream.profile !== 'tv' ? 'Web' : 'TV'} Qualität 
					{status !== 'ok' && (
						<Tooltip title={message} arrow>
							<HelpIcon fontSize="small" style={{ verticalAlign: 'middle', marginLeft: 3 }} />
						</Tooltip>
					)}
				</Typography>
				<Chip
					size="small"
					color={status === 'ok' || status === 'bitrate_ok' ? 'success' : 'danger'}
					label={status === 'ok' ? 'Sehr gut' : status === 'fps' || status === 'bitrate_bad' ? 'Schlecht' : 'Gut'}
					sx={{ paddingLeft: 0, paddingRight: 0 }}
				/>
			</Grid>
			<Grid
				item
				xs={2.5}
				style={{
					padding: '10px 0px 10px 5px',
					whiteSpace: 'break-spaces!important',
				}}
			>
				<Typography variant="body2" color="text.secondary" fontSize={11}>
					Codec
				</Typography>
				<Typography variant="body2" color="text.primary" marginTop={0.4} textTransform={'uppercase'}>
					{stream.vcodec}{stream.acodec ? `, ${stream.acodec}` : ''}
				</Typography>
			</Grid>
			<Grid
				item
				xs={2.5}
				style={{
					padding: '10px 0px 10px 0px',
					whiteSpace: 'break-spaces!important',
				}}
			>
				<Typography variant="body2" color="text.secondary" fontSize={11}>
					Auflösung
				</Typography>
				<Typography variant="body2" color="text.primary" marginTop={0.4}>
					{stream.resolution}
				</Typography>
			</Grid>
			<Grid
				item
				xs={1.5}
				style={{
					padding: '10px 0px 10px 0px',
					whiteSpace: 'break-spaces!important',
				}}
			>
				<Typography variant="body2" color="text.secondary" fontSize={11}>
					FPS
				</Typography>
				<Typography variant="body2" color="text.primary" marginTop={0.4}>
					{stream.fps}
				</Typography>
			</Grid>
			<Grid
				item
				xs={2.7}
				style={{
					padding: '10px 0px 10px 0px',
					whiteSpace: 'break-spaces!important',
				}}
			>
				<Typography variant="body2" color="text.secondary" fontSize={11}>
					Bitrate (kbit/s)
				</Typography>
				<Typography variant="body2" color="text.primary" marginTop={0.4}>
					{stream.bitrate_kbits.toLocaleString('de-DE')}
				</Typography>
			</Grid>
		</Grid>
	);
};

function CameraStream({ camera }) {
	const { client } = useApp();
	const [stats, setStats] = React.useState(null);
	const [statsTick, setStatsTick] = React.useState(new Date());
    const [statsRange, setStatsRange] = React.useState("30");
	const [statsMetric, setStatsMetric] = React.useState("bitrate_kbits");
	const statsRef = React.useRef(stats);
	const loadingRef = React.useRef(!stats);

    function setStep() {
        if (statsRange === "720") {
            return 600;
        } else if (statsRange === "1440") {
            return 600;
        } else {
            return 60;
        }
    }

    async function fetchCameraStats() {
        const data = await client.GetCustomerCameraIdStats({
            camera_id: camera.id,
            from_date: moment().utc().subtract(statsRange, 'minutes').format('YYYY-MM-DDTHH:mm:ss'),
			to_date: moment().utc().format('YYYY-MM-DDTHH:mm:ss'),
            step: setStep(),
        });
        setStats(data);
		setStatsTick(new Date());
    }

	React.useEffect(() => {
        const fetchStats = async () => {
            if (!statsRef.current) {
                try {
                    await fetchCameraStats();
                    loadingRef.current = false;
                } catch (error) {
                    console.log(error.response.data);
                }
            }
        };
        fetchStats();
        const intervalId = setInterval(fetchStats, 60000);
        return () => clearInterval(intervalId);
	// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statsRange]);

	const loading = loadingRef.current;

	if (loading) {
		return (
			<React.Fragment>
				<Typography padding="5px 0px 0px 0px" marginTop={0.5} marginBottom={1.5} variant="h6">
				</Typography>
				<Stack
					style={{
						padding: '10px 15px 15px 15px',
						marginBottom: 50,
						backgroundColor: 'rgba(0,0,0,.035)',
						borderRadius: 4,
					}}
					spacing={0}
				>
					<CircularProgress color="inherit" size={20} />
				</Stack>
			</React.Fragment>
		);
	}

	return (
		<Stack direction="column" justifyContent="flex-start" alignItems="flex-start">
			<Stack direction="row" justifyContent="flex-start" alignItems="flex-start" width="100%" padding="5px 0px 0px 0px">
				{camera && stats && (
					<CameraStreamStats key={statsTick} camera={camera} stats={stats} statsRange={statsRange} setStatsRange={setStatsRange} statsMetric={statsMetric} setStatsMetric={setStatsMetric} />
				)}
			</Stack>
			<Divider />
			<Stack direction="column" justifyContent="flex-start" alignItems="flex-start" width="100%" padding="5px 0px 0px 0px" marginBottom={-1}>
				{camera && camera.streams && camera.streams.map((stream, index) => {
					return <StreamDetail key={index} stream={stream} />;
				})}
			</Stack>
		</Stack>
	);
}

CameraStream.propTypes = {
	camera: PropTypes.object,
};

CameraStream.defaultProps = {
	camera: {},
};

export default CameraStream;

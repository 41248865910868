import * as React from "react";

import "moment-timezone";
import moment from "moment";

import { saveAs } from "file-saver";

import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';

import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import Stack from "@mui/material/Stack";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";

import VideoJS from "../../../components/VideoJS";
import PageSidebarHeader from "../../../components/PageSidebarHeader";
import SidebarTabPanel from "../../../components/SidebarTabPanel";
import SidebarTabPanelProps from "../../../components/SidebarTabPanelProps";

import CameraMeta from "../../Camera/CameraSidebar/CameraMeta";
import CameraTag from "../../Camera/CameraSidebar/CameraTag";

import VideoTag from "./VideoTag";
import VideoSidebarMenu from "./VideoSidebarMenu";


const FileDetail = ({ video_source }) => {
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            style={{
                marginTop: 5,
                backgroundColor: "rgba(0,0,0,.035)",
                borderRadius: 4,
                whiteSpace: "break-spaces!important",
            }}
        >
            <Grid
                item xs={1.5}
                style={{
                    padding: "10px 0px 10px 15px",
                    whiteSpace: "break-spaces!important",
                    borderRadius: "4px 0 0 4px",
                }}
            >
                <Typography variant="body2" color="text.secondary" fontSize={11}>
                    Format
                </Typography>
                <Typography variant="body2" color="text.primary" marginTop={.4} textTransform={"uppercase"}>
                    MP4
                </Typography>
            </Grid>
            <Grid
                item xs={1.5}
                style={{
                    padding: "10px 15px 10px 15px",
                    whiteSpace: "break-spaces!important",
                }}
            >
                <Typography variant="body2" color="text.secondary" fontSize={11}>
                    Codec
                </Typography>
                <Typography variant="body2" color="text.primary" marginTop={.4} textTransform={"uppercase"}>
                    {video_source.video_codec}
                </Typography>
            </Grid>
            <Grid
                item xs={2.5}
                style={{
                    padding: "10px 15px 10px 15px",
                    whiteSpace: "break-spaces!important",
                }}
            >
                <Typography variant="body2" color="text.secondary" fontSize={11}>
                    Auflösung
                </Typography>
                <Typography variant="body2" color="text.primary" marginTop={.4}>
                    {video_source.video_width}x{video_source.video_height}
                </Typography>
            </Grid>
            <Grid
                item xs={1.5}
                style={{
                    padding: "10px 15px 10px 15px",
                    whiteSpace: "break-spaces!important",
                }}
            >
                <Typography variant="body2" color="text.secondary" fontSize={11}>
                    FPS
                </Typography>
                <Typography variant="body2" color="text.primary" marginTop={.4}>
                    {video_source.video_fps}
                </Typography>
            </Grid>
            <Grid
                item xs={2.5}
                style={{
                    padding: "10px 15px 10px 15px",
                    whiteSpace: "break-spaces!important",
                }}
            >
                <Typography variant="body2" color="text.secondary" fontSize={11}>
                    Bitrate (kbit/s)
                </Typography>
                <Typography variant="body2" color="text.primary" marginTop={.4}>
                    {video_source.video_bitrate_kbit.toLocaleString("de-DE")}
                </Typography>
            </Grid>
        </Grid>
    );
}


const FileDetails = ({ video_sources }) => {
    // [
    //     {
    //       "video_height": 1080,
    //       "video_width": 1920,
    //       "video_codec": "h264",
    //       "video_fps": 25.83,
    //       "video_bitrate_kbit": 1557.575
    //     }
    //   ]
    return (
        <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={0.5}
        >
            {video_sources.map((video_source, index) => {
                return (
                    <FileDetail key={index} video_source={video_source} />
                );
            })}
        </Stack>
    );
}


function VideoSidebar({
    drawerWidth,
    video,
    playerConfig,
    handleItemOnDownloadList,
    downloadList,
    handleSidebarToggle,
}) {
    // TABS
    const [tab, setTab] = React.useState(0);
    const handleChangeTab = (event, newValue) => {
        setTab(newValue);
    };

    // // PERMISSION CHECK
    const videoAdminRoles = ["admin", "video:admin"]
    const videoEditorRoles = videoAdminRoles.concat(["editor", "video:editor"])

    // VIDEOJS
    const videoJsOptions = {
        autoplay: false,
        controls: true,
        responsive: true,
        fluid: true,
        playsinline: true,
        muted: true,
        poster:
            video.video_sources[0].video_poster.replace(".jpg", "_360.jpg") +
            "?token=" +
            playerConfig.token,
        sources: [
            {
                src:
                    video.video_sources[0].video_source +
                    "?token=" +
                    playerConfig.token,
                type: "video/mp4",
            },
        ],
    };

    // VIDEO DOWNLOADS
    const saveFile = () => {
        saveAs(
            video.video_sources[0].video_source +
                "?token=" +
                playerConfig.token,
            `${moment(video.created_at).format("DD-MM-YYYY_HH-mm-ss")}_${
                video.camera_meta.city
            }_${video.tag}.mp4`,
        );
    };

    return (
        <Stack sx={{ maxWidth: drawerWidth }} width={"100%"}>
            <Stack>
                <PageSidebarHeader
					title={video.camera_meta.name}
					share={true}
					handleToggle={handleSidebarToggle}
					submenu={
						<VideoSidebarMenu 
                            videoAdminRoles={videoAdminRoles}
                            videoEditorRoles={videoEditorRoles}
                            video={video} 
                        />
					}
					player={
						videoJsOptions && <VideoJS key={video.id} {...videoJsOptions} />
					}
				/>
            </Stack>
            <Divider sx={{width: "100%", marginTop: "0px", marginBottom: "-2px", border: "2px solid rgba(0,0,0,.1)", borderTop: "1px solid rgba(0,0,0,.2)"}} />
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
                marginTop={0}
                borderBottom={"1px solid rgba(0, 0, 0, 0.12)"}
                maxWidth={drawerWidth}
            >
                <Tabs value={tab} onChange={handleChangeTab}>
                    <Tab label="Meta" {...SidebarTabPanelProps(0)} />
                    <Tab label="Datei" {...SidebarTabPanelProps(1)} />
                </Tabs>
                <Stack
                    direction="row"
                    justifyContent="flex-right"
                    alignItems="center"
                    spacing={2}
                    paddingRight={"25px"}
                >
                    <IconButton
                        onClick={saveFile}
                        variant="outlined"
                        color="success"
                        size="small"
                    >
                        <FileDownloadIcon sx={{ color: "#0085E9", fontSize: "24px", marginTop: .5 }} />
                    </IconButton>
                    <IconButton
                        onClick={() => handleItemOnDownloadList(video)}
                        variant="outlined"
                        color={
                            downloadList.find(
                                (vendor) => vendor["id"] === video.id,
                            )
                                ? "danger"
                                : "success"
                        }
                        size="small"
                    >
                        {downloadList.find(
                            (vendor) => vendor["id"] === video.id,
                        )
                            ? <PlaylistRemoveIcon sx={{ color: "#0085E9", fontSize: "24px" }} />
                            : <PlaylistAddIcon sx={{ color: "#0085E9", fontSize: "24px" }} />}
                    </IconButton>
                </Stack>
            </Stack>
            <SidebarTabPanel value={tab} index={0} padding={0}>
                <Stack
                    padding="5px 20px 0px 20px"
                    spacing={0}
                >
                    <Stack
                        style={{
                            padding: "10px 25px 10px 15px",
                            marginBottom: 15,
                            backgroundColor: "rgba(0,0,0,.035)",
                            borderRadius: 4,
                        }}
                        spacing={0.5}
                    >
                        <Typography fontSize={14}>
                            <strong>ID:</strong> {video.id}
                        </Typography>
                        <Typography fontSize={14}>
                            <strong>Kamera ID:</strong> {video.camera_id}
                        </Typography>
                        <Typography fontSize={14}>
                            <strong>Erstellt:</strong>{" "}
                            {moment
                                .utc(video.created_at)
                                .local()
                                .format("DD.MM.YYYY HH:mm:ss")}
                        </Typography>
                        {video.expires_date && (
                            <Typography fontSize={14}>
                                <strong>Ablaufdatum:</strong>{" "}
                                {moment
                                    .utc(video.expires_date)
                                    .local()
                                    .format("DD.MM.YYYY HH:mm:ss")}
                            </Typography>
                        )}
                    </Stack>
                    <Typography marginTop={2} marginBottom={1.5} variant="h4">
                        Tagging
                    </Typography>
                    <Stack
                        style={{
                            padding: "10px 15px 15px 15px",
                            marginBottom: 5,
                            backgroundColor: "rgba(0,0,0,.035)",
                            borderRadius: 4,
                        }}
                        spacing={0}
                    >
                        <VideoTag tags={video.tags} />
                    </Stack>
                    <Typography marginTop={2} marginBottom={1.5} variant="h4">
                        Standort
                    </Typography>
                    <Stack
                        style={{
                            padding: "10px 15px 15px 15px",
                            marginBottom: 5,
                            backgroundColor: "rgba(0,0,0,.035)",
                            borderRadius: 4,
                        }}
                        spacing={0}
                    >
                        <CameraMeta meta={video.camera_meta} />
                    </Stack>
                    <Stack
                        style={{
                            padding: "10px 15px 15px 15px",
                            marginBottom: 20,
                            backgroundColor: "rgba(0,0,0,.035)",
                            borderRadius: 4,
                        }}
                        spacing={0}
                    >
                        <CameraTag meta={video.camera_meta} />
                    </Stack>
                </Stack>
            </SidebarTabPanel>
            <SidebarTabPanel value={tab} index={1} padding={0}>
                <Stack
                    padding="5px 20px 0px 20px"
                    spacing={0}
                >
                    <Typography marginTop={0} marginBottom={1.5} variant="h4">
                        Videodatei
                    </Typography>
                    <Stack
                        style={{
                            padding: "10px 25px 10px 15px",
                            marginBottom: 5,
                            backgroundColor: "rgba(0,0,0,.035)",
                            borderRadius: 4,
                            fontSize: 14,
                            whiteSpace: "break-spaces!important",
                        }}
                        spacing={0}
                    >
                        <Typography
                            fontSize={14}
                            paddingTop={1.5}
                            marginBottom={1.5}
                        >
                            <strong>Videolänge:</strong>{" "}
                            {video.video_duration_seconds} Sekunden
                        </Typography>                        
                    </Stack>
                    <FileDetails video_sources={video.video_sources} />
                </Stack>
            </SidebarTabPanel>
        </Stack>
    );
}

VideoSidebar.propTypes = {
    drawerWidth: PropTypes.number,
    video: PropTypes.object,
    playerConfig: PropTypes.object,
    handleItemOnDownloadList: PropTypes.func,
    downloadList: PropTypes.array,
};

VideoSidebar.defaultProps = {
    drawerWidth: 450,
    downloadList: [],
};

export default VideoSidebar;

import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from "prop-types";

import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import ErrorIcon from '@mui/icons-material/Error';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import VideoJS from "./VideoJS";


export default function CameraPreviewOverlay({ 
    dialogOpen = true,
    setDialogOpen = () => {}, 
    mousePosition = { x: 0, y: 0 }, 
    camera = null, 
    playerConfig = {}, 
}) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    React.useEffect(() => {
        if (camera) {
          setAnchorEl(mousePosition ? document.elementFromPoint(mousePosition.x, mousePosition.y) : null);
        }
    }, [camera, mousePosition]);
      
    const handleCloseMenu = () => {
        setDialogOpen(false);
    };

    function CameraProfile({ 
        streams = []
    }) {
        return (
            <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={.5}
                style={{
                    wordWrap: "break-word",
                }}
            >
                {streams.map((stream, index) => {
                    return (
                        <Chip
                            size="small"
                            key={index}
                            label={stream.resolution.split("x")[1]}
                            color="primary"
                            style={{
                                padding: 0,
                                fontSize: 12,
                                height: 20,
                            }}
                        />
                    );
                })}
            </Stack>
        );
    }

    // VIDEOJS
    const videoJsOptions = {
        autoplay: true,
        controls: true,
        responsive: true,
        fluid: true,
        poster: playerConfig.url + camera.id + "/live/main_240.jpg",
        playsinline: true,
        muted: true,
        // html5: {
        //     hls: {
        //         overrideNative: true,
        //         limitRenditionByPlayerDimensions: true,
        //         useDevicePixelRatio: true
        //     },
        //     nativeAudioTracks: false,
        //     nativeVideoTracks: false,
        //     useBandwidthFromLocalStorage: true
        // },
        sources: [
            {
                src:
                    playerConfig.url +
                    camera.id +
                    "/live/main.m3u8?token=" +
                    playerConfig.token,
                type: "application/vnd.apple.mpegurl",
            },
        ],
    };

    return (
        <Menu
            open={Boolean(dialogOpen && camera)}
            onClose={handleCloseMenu}
            anchorEl={anchorEl}
        >
            <MenuList dense sx={{
                paddingTop: 0
            }}>
                <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={1}
                    padding={"5px 15px 5px 15px"}
                    sx={{
                        backgroundColor: "rgba(0,0,0,.03)",
                        height: 42
                    }}
                >
                    {camera.status === "healthy" && (
                        <>
                            <CheckCircleIcon style={{ color: "#39B54A" }} />
                            <Typography variant="h6">
                                Verbunden
                            </Typography>
                        </>
                    )}
                    {camera.status === "unhealthy" && (
                        <>
                            <ErrorIcon style={{ color: "#fff", backgroundColor: "rgba(193,39,45,.9)" }} />
                            <Typography variant="h6">
                                Verbindungsfehler
                            </Typography>
                        </>
                    )}
                    {camera.status === "unknown" && (
                        <>
                            <CancelIcon style={{ color: "#fff", backgroundColor: "rgba(193,39,45,.9)" }} />
                            <Typography variant="h6">
                                Unbekannter Zustand
                            </Typography>
                        </>
                    )}
                </Stack>
                <Divider style={{ borderWidth: 1, marginTop: 0, marginBottom: 0 }} />
                <VideoJS {...videoJsOptions} skinName={"vjs-public2"} />
                <Stack
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={.5}
                    padding={"10px 15px 12px 15px"}
                    maxWidth={500}
                    width={400}
                    sx={{
                        backgroundColor: "rgba(0,0,0,.02)",
                    }}
                >
                    {camera.meta.producer && (
                        <Stack
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            spacing={1}
                            style={{
                                wordWrap: "break-word",
                                marginBottom: 0,
                                //color: "#0085e9"
                            }}
                        >
                            <Typography variant="body2" textTransform={"uppercase"}>{camera ? `${camera.meta.producer}` : ""}</Typography>
                        </Stack>
                    )}
                    <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={1}
                        style={{
                            wordWrap: "break-word",
                            marginBottom: -5
                        }}
                    >
                        <Typography variant="body2">{camera ? `${camera.meta.postal_code} ${camera.meta.city}` : ""}</Typography>
                    </Stack>
                    <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={1}
                        style={{
                            wordWrap: "break-word",
                            marginBottom: 5
                        }}
                    >
                        <Typography variant="body2">{camera ? `${camera.meta.country}` : ""}</Typography>
                    </Stack>
                    <CameraProfile streams={camera ? camera.streams : []} />
                </Stack>
                <Divider sx={{marginTop: 0}} />
                <MenuItem
                    component={Link}
                    to={`/live/camera/${camera.id}`}
                >
                    <ListItemText>Zur Kamera wechseln</ListItemText>
                </MenuItem>
                <Divider />
                <MenuItem
                    component={Link}
                    to={`/analytics/camera?camera_id=${camera.id}`}
                >
                    <ListItemText>Analytics aufrufen</ListItemText>
                </MenuItem>
                {(camera.sla === "2" || camera.sla === "8") && (
                    <>
                        <Divider />
                        <MenuItem
                            component={Link}
                            to={`/video/camera/?camera_id=${camera.id}&latest=50`}
                        >
                            <ListItemText>Videos anzeigen</ListItemText>
                        </MenuItem>
                    </>
                )}
            </MenuList>
        </Menu>
    );
}

CameraPreviewOverlay.propTypes = {
    dialogOpen: PropTypes.bool,
    setDialogOpen: PropTypes.func,
    mousePosition: PropTypes.object,
    camera: PropTypes.object,
    playerConfig: PropTypes.object,
};
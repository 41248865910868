import React from 'react';
import { Collapse, Fade, Stack, Typography } from '@mui/material';

import FormTextField from '../../../components/FormTextField';
import FormButtonGroup from '../../../components/FormButtonGroup';
import { removeEmptyStrings, useFormSection} from '../../../components/misc/useForm';

export default function CameraMetadataShareForm({
    camera = {},
    updateCamera = () => { },
    hasPermission = false,
    playerConfig = {},
}) {
    const cameraDefault = {
        name_share: camera.meta.name_share || "",
        description_share: camera.meta.description_share || "",
    };
    const { 
        isUpdated, 
        setIsUpdated, 
        formUpdate: cameraUpdate, 
        handleChange,
        handleReset,
    } = useFormSection({
        formDefault: cameraDefault,
    });

    async function handleUpdate() {
        updateCamera({
            camera_id: camera.id,
            camera: { meta: removeEmptyStrings(cameraUpdate) }
        });
        setIsUpdated(false);
    }

    const truncateText = (
        text = "",
        maxLength = 160
    ) => {
        if (!text) return "";
        if (text.length <= maxLength) return text;
        const truncated = text.slice(0, maxLength);
        return truncated.slice(0, truncated.lastIndexOf(" ")) + "…";
    };

    const showPreview = (
        cameraUpdate.name_share && cameraUpdate.name_share !== "" && cameraUpdate.description_share && cameraUpdate.description_share !== ""
    );

    const backgroundUrl = (
        camera.poster_ul && camera.poster_ul !== ""
            ? camera.poster_ul
            : `${playerConfig.url}${camera.id}/live/main_240.jpg`
    );

    return (
        <>
            <Collapse in={showPreview} timeout={500} sx={{ width: "100%" }} unmountOnExit>
                <Fade in={showPreview} timeout={350}>
                    <Stack
                        direction="column"
                        spacing={1}
                        sx={{
                            width: "100%",
                            padding: 0,
                            border: "1px solid rgba(0,0,0,.2)",
                            borderRadius: 1,
                            backgroundColor: "#fff",
                        }}
                    >
                        <Stack
                            direction="row"
                            spacing={2}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            sx={{
                                width: "100%",
                                height: 160,
                                paddingBottom: 2,
                                backgroundImage: `url(${backgroundUrl})`,
                                backgroundSize: "cover",
                                backgroundPosition: "bottom",
                                borderRadius: "4px 4px 0 0",
                            }}
                        >
                        </Stack>
                        <Stack
                            direction="row"
                            spacing={2}
                            justifyContent={"flex-start"}
                            alignItems={"center"}
                            sx={{ width: "100%", padding: "2px 15px 0px 15px" }}
                        >
                            <Typography fontSize={18} lineHeight={1.2} color="blue">{truncateText(cameraUpdate.name_share)}</Typography>
                        </Stack>
                        <Stack
                            direction="row"
                            spacing={2}
                            justifyContent={"flex-start"}
                            alignItems={"center"}
                            sx={{ width: "100%", padding: "0px 15px 10px 15px" }}
                        >
                            <Typography fontSize={15} sx={{ color: "rgba(0,0,0,.7)" }}>{truncateText(cameraUpdate.description_share)}</Typography>
                        </Stack>
                    </Stack>
                </Fade>
            </Collapse>
            <Stack
                direction="column"
                spacing={1}
                sx={{
                    width: "100%", paddingTop: showPreview ? 1 : 0,
                }}
            >
                <FormTextField
                    name="name_share"
                    label="Name"
                    object={cameraUpdate}
                    handleChange={handleChange}
                    hasPermission={hasPermission}
                    maxLength={160}
                />
                <FormTextField
                    name="description_share"
                    label="Beschreibung"
                    object={cameraUpdate}
                    handleChange={handleChange}
                    hasPermission={hasPermission}
                    maxLength={160}
                />
            </Stack>
            {hasPermission && isUpdated && (
                <Stack
                    direction="row"
                    spacing={1}
                    sx={{
                        justifyContent: "flex-end",
                        alignItems: "center",
                        width: "100%",
                        paddingTop: 1,
                    }}
                >
                    <FormButtonGroup
                        handleReset={handleReset}
                        handleUpdate={handleUpdate}
                    />
                </Stack>
            )}
        </>
    );
}
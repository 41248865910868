import React from "react";
import { Chip, Stack } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";


export default function CameraStatusChip({
    camera = null,
}) {
    if (!camera) return null;

    return (
        <Stack
            direction="row"
            alignItems="center"
            spacing={1}
        >
            {(camera.status === "healthy" && camera.streams && camera.streams.length > 0) && (
                <Chip
                    size="small"
                    icon={<CheckCircleIcon style={{ color: "#fff", fontSize: 16 }} />}
                    color="success"
                    label="Verbunden"
                    sx={{
                        color: "#fff", fontSize: 12
                    }}
                />
            )}
            {(camera.status !== "healthy" || !camera.streams || !camera.streams.length > 0) && (
                <Chip
                    size="small"
                    icon={<ReportProblemIcon style={{ color: "#fff",  fontSize: 16 }} />}
                    color="danger"
                    label="Verbindungsfehler"
                    sx={{
                        color: "#fff", fontSize: 12
                    }}
                />
            )}
        </Stack>
    );
}
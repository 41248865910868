import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import PropTypes from "prop-types";
import InputAdornment from "@mui/material/InputAdornment";
import WarningIcon from "@mui/icons-material/Warning";


export default function CameraSelect({
    cameras = [],
    index = null,
    camera_id = null,
    handleSelectedCamera = () => { },
}) {
    const [options, setOptions] = React.useState(null);
    if (!cameras || !cameras.length || index === null || index < 0) {
        return null;
    }

    if (!options) {
        const newData = cameras
            .map(({ id, status, meta, sla }) => ({
                camera_id: id,
                name: meta.name,
                city: meta.city,
                country: meta.country,
                status: status,
            }))
            .filter((obj, index, array) => {
                const currentIndex = array.findIndex(
                    (item) => item.camera_id === obj.camera_id
                );
                return currentIndex === index;
            })
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .sort((a, b) => -(a.country || '').localeCompare(b.country || ''));

        setOptions(newData);
    }

    const selectedCameraValue = (camera_id, cameras) => {
        if (!camera_id || !cameras) {
            return null;
        }
        const camera = cameras.find((camera) => camera.id === camera_id);
        if (!camera) { return null; }
        const { id, status, meta } = camera;
        return {
            camera_id: id,
            name: meta.name,
            city: meta.city,
            country: meta.country,
            status: status,
        };
    };

    return (
        <Autocomplete
            value={camera_id ? selectedCameraValue(camera_id, cameras) : null}
            size="small"
            id="playlist-item-camera-select"
            options={options ? options : []}
            groupBy={(option) => option.country}
            getOptionLabel={(option) => `${option.name} (${option.city})`}
            onChange={(event, newValue) => {
                handleSelectedCamera(index, newValue.camera_id);
            }}
            isOptionEqualToValue={(option, value) =>
                option.camera_id === value.camera_id
            }
            renderOption={(props, option) => (
                <li {...props}>
                    {option.name} {option.city ? '(' + option.city + ')' : ''}
                    {option.status !== 'healthy' && <WarningIcon color="danger" style={{ marginLeft: 10 }} />}
                </li>
            )}
            fullWidth
            disableClearable
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="outlined"
                    //label="Kamera Auswahl"
                    fullWidth
                    sx={{
                        border: "1px solid #f1f1f1",
                        borderRadius: 1,
                    }}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment:
                            <>
                                {params.InputProps.endAdornment}
                                <InputAdornment position="end">{selectedCameraValue(camera_id, cameras)?.status !== "healthy" ? <WarningIcon color="danger" style={{ marginLeft: 10 }} /> : null}</InputAdornment>
                            </>
                    }}
                />
            )}
        />
    );
}

CameraSelect.propTypes = {
    playlist: PropTypes.array.isRequired,
    selectedCamera: PropTypes.any,
    setSelectedCamera: PropTypes.any,
};
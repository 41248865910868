import React from 'react';
import { Stack } from '@mui/material';

import FormTextField from '../../../components/FormTextField';
import FormButtonGroup from '../../../components/FormButtonGroup';
import { removeEmptyStrings, useFormSection } from '../../../components/misc/useForm';

export default function CameraMetadataLocationForm({ 
    camera = {},
    updateCamera = () => {},
    hasPermission = false, 
}) {
    const cameraDefault = {
        postal_code: camera.meta.postal_code || "",
        city: camera.meta.city || "",
        county: camera.meta.county || "",
        region: camera.meta.region || "",
        country_code: camera.meta.country_code || "",
        country: camera.meta.country || "",
        latitude: camera.meta.latitude || "",
        longitude: camera.meta.longitude || "",
    };
    const { 
        isUpdated, 
        setIsUpdated, 
        formUpdate: cameraUpdate, 
        handleChange,
        handleReset,
    } = useFormSection({
        formDefault: cameraDefault,
    });

    async function handleUpdate() {    
        updateCamera({
            camera_id: camera.id, 
            camera: {meta: removeEmptyStrings(cameraUpdate)}
        });
        setIsUpdated(false);
    }

    return (
        <>
            <Stack
                direction="row"
                spacing={1}
                sx={{
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                }}
            >
                <FormTextField
                    name="postal_code"
                    label="Postleitzahl"
                    object={cameraUpdate}
                    handleChange={handleChange}
                    hasPermission={hasPermission}
                    maxLength={30}
                />
                <FormTextField
                    name="city"
                    label="Stadt"
                    object={cameraUpdate}
                    handleChange={handleChange}
                    hasPermission={hasPermission}
                    maxLength={100}
                />
            </Stack>
            <FormTextField
                name="county"
                label="Landkreis"
                object={cameraUpdate}
                handleChange={handleChange}
                hasPermission={hasPermission}
                maxLength={100}
            />
            <FormTextField
                name="region"
                label="Region"
                object={cameraUpdate}
                handleChange={handleChange}
                hasPermission={hasPermission}
                maxLength={100}
            />
            <Stack
                direction="row"
                spacing={1}
                sx={{
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                }}
            >
                <FormTextField
                    name="country_code"
                    label="Ländercode"
                    object={cameraUpdate}
                    handleChange={handleChange}
                    hasPermission={hasPermission}
                    maxLength={2}
                />
                <FormTextField
                    name="country"
                    label="Land"
                    object={cameraUpdate}
                    handleChange={handleChange}
                    hasPermission={hasPermission}
                    maxLength={100}
                />
            </Stack>
            <Stack
                direction="row"
                spacing={1}
                sx={{
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                }}
            >
                <FormTextField
                    name="latitude"
                    label="Breitengrad"
                    object={cameraUpdate}
                    handleChange={handleChange}
                    hasPermission={hasPermission}
                    maxLength={15}
                    type="number"
                />
                <FormTextField
                    name="longitude"
                    label="Längengrad"
                    object={cameraUpdate}
                    handleChange={handleChange}
                    hasPermission={hasPermission}
                    maxLength={15}
                    type="number"
                />
            </Stack>
            {hasPermission && isUpdated && (
                <Stack
                    direction="row"
                    spacing={1}
                    sx={{
                        justifyContent: "flex-end",
                        alignItems: "center",
                        width: "100%",
                        paddingTop: 1,
                    }}
                >
                    <FormButtonGroup 
                        handleReset={handleReset}
                        handleUpdate={handleUpdate}
                    />
                </Stack>
            )}
        </>
    );
}
import React from 'react';
import { Stack } from '@mui/material';

import ImageUploadField from '../../../components/ImageUploadField';
import FormButtonGroup from '../../../components/FormButtonGroup';
import FormTextField from '../../../components/FormTextField';
import CameraTagSelect from "./CameraTagSelect";
import { removeEmptyStrings, validHttpUrl, useFormSection } from '../../../components/misc/useForm';


export default function CameraMetadataForm({
    camera = {},
    updateCamera = () => {},
    hasPermission = false, 
}) {
    const cameraDefault = {
        poster_url: camera.poster_url || "",
    };
    const { 
        isUpdated, 
        setIsUpdated, 
        formUpdate: cameraUpdate,
        setFormUpdate: setCameraUpdate,
        handleChange,
    } = useFormSection({
        formDefault: cameraDefault,
    });
    const [posterImage, setPosterImage] = React.useState(null);
    React.useEffect(() => {
        if (posterImage && posterImage !== cameraUpdate.poster_url) {
            setIsUpdated(true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [posterImage]);

    // Custom form section for camera metadata
    const cameraMetaDefault = {
        name: camera.meta.name || "",
        name_short: camera.meta.name_short || "",
        description: camera.meta.description || "",
        logo_url: camera.meta.logo_url || "",
        logo_background_color: camera.meta.logo_background_color || "",
        website: camera.meta.website || "",
        producer: camera.meta.producer || "",
        category: camera.meta.category || "",
        tags: camera.meta.tags || [],
        license: camera.meta.license || "private",
    };
    const [cameraMetaUpdate, setCameraMetaUpdate] = React.useState(cameraMetaDefault);
    const [logoImage, setLogoImage] = React.useState(null);
    React.useEffect(() => {
        if (logoImage && logoImage !== cameraMetaUpdate.logo_url) {
            setIsUpdated(true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [logoImage]);

    function handleChangeMeta(e) {
        setCameraMetaUpdate({
            ...cameraMetaUpdate,
            [e.target.name]: e.target.value
        });
        setIsUpdated(true);
    }

    function handleMetaTagChange(newTags) {
        setCameraMetaUpdate((prev) => ({
          ...prev,
          tags: newTags,
        }));
        setIsUpdated(true);
    }

    function handleReset() {
        setCameraUpdate((prev) => {
            return {
                ...prev,
                ...cameraDefault,
            };
        });
        setCameraMetaUpdate((prev) => {
            return {
                ...prev,
                ...cameraMetaDefault,
            };
        });
        setLogoImage((prev) => {
            return null
        });
        setPosterImage((prev) => {
            return null
        });
        setIsUpdated(false);
    }

    async function handleUpdate() {
        updateCamera({
            camera_id: camera.id, 
            camera: {
                ...removeEmptyStrings(cameraUpdate), 
                meta: removeEmptyStrings(cameraMetaUpdate)
            },
            camera_logo_image: logoImage !== cameraMetaUpdate.logo_url ? logoImage : null,
            camera_poster_image: posterImage !== cameraUpdate.poster_url ? posterImage : null,
        });
        setIsUpdated(false);
    }

    const category = [
        {
            value: 'fluss',
            label: 'Fluss',
        },
        {
            value: 'gebirge',
            label: 'Gebirge',
        },
        {
            value: 'industrie',
            label: 'Industrie',
        },
        {
            value: 'küste',
            label: 'Küste',
        },
        {
            value: 'landschaft',
            label: 'Landschaft',
        },
        {
            value: 'see',
            label: 'See',
        },
        {
            value: 'stadt',
            label: 'Stadt',
        },
        {
            value: 'tier',
            label: 'Tier',
        },
    ];

    const license = [
        {
            value: 'private',
            label: 'Keine Lizenz',
        },
        {
            value: 'livespotting',
            label: 'livespotting Content-Hub',
        },
        {
            value: 'cco_1.0',
            label: 'CC0 1.0',
        },
        {
            value: 'cc_by_4.0',
            label: 'CC BY 4.0',
        },
        {
            value: 'cc_by_sa_4.0',
            label: 'CC BY-SA 4.0',
        },
        {
            value: 'cc_by_nc_4.0',
            label: 'CC BY-NC 4.0',
        },
        {
            value: 'cc_by_nc_sa_4.0',
            label: 'CC BY-NC-SA 4.0',
        },
        {
            value: 'cc_by_nd_4.0',
            label: 'CC BY-ND 4.0',
        },
        {
            value: 'cc_by_nc_nd_4.0',
            label: 'CC BY-NC-ND 4.0',
        },
    ];

    return (
        <>
            <FormTextField
                name="name"
                label="Name"
                object={cameraMetaUpdate}
                handleChange={handleChangeMeta}
                hasPermission={hasPermission}
                maxLength={180}
            />
            <FormTextField
                name="name_short"
                label="Name (Kurz)"
                object={cameraMetaUpdate}
                handleChange={handleChangeMeta}
                hasPermission={hasPermission}
                maxLength={35}
            />
            <FormTextField
                name="description"
                label="Beschreibung"
                object={cameraMetaUpdate}
                handleChange={handleChangeMeta}
                hasPermission={hasPermission}
                maxLength={5000}
                multiline
                maxRows={5}
            />
            <Stack direction="row" spacing={2} alignItems="center" width={"100%"}>
                <ImageUploadField
                    id="logo_url"
                    uploadedFile={logoImage || cameraMetaUpdate.logo_url}
                    setUploadedFile={setLogoImage}
                    width={72}
                    height={42}
                    readOnly={!hasPermission}
                />
                <FormTextField
                    name="logo_url"
                    label="Logo"
                    object={cameraMetaUpdate}
                    handleChange={handleChangeMeta}
                    hasPermission={hasPermission}
                />
            </Stack>
            <FormTextField
                name="logo_background_color"
                label="Logo Hintergrundfarbe (Hex)"
                placeholder='#FFFFFF'
                object={cameraMetaUpdate}
                handleChange={handleChangeMeta}
                hasPermission={hasPermission}
            />
            <Stack direction="row" spacing={2} alignItems="center" width={"100%"}>
                <ImageUploadField
                    id="poster_url"
                    uploadedFile={posterImage || cameraUpdate.poster_url}
                    setUploadedFile={setPosterImage}
                    width={72}
                    height={42}
                    readOnly={!hasPermission}
                />
                <FormTextField
                    name="poster_url"
                    label="Poster"
                    object={cameraUpdate}
                    handleChange={handleChange}
                    hasPermission={hasPermission}
                />
            </Stack>
            <FormTextField
                name="website"
                label="Webseite"
                object={cameraMetaUpdate}
                handleChange={handleChangeMeta}
                hasPermission={hasPermission}
                maxLength={200}
                error={!validHttpUrl(cameraMetaUpdate.website)}
                helperText={!validHttpUrl(cameraMetaUpdate.website) ? "Bitte eine gültige URL eingeben" : ""}
            />
            <FormTextField
                name="producer"
                label="Produzent"
                object={cameraMetaUpdate}
                handleChange={handleChangeMeta}
                hasPermission={hasPermission}
                maxLength={150}
            />
            <FormTextField
                name="category"
                label="Kategorie"
                object={cameraMetaUpdate}
                handleChange={handleChangeMeta}
                hasPermission={hasPermission}
                select
                selectOptions={category}
            />
            <CameraTagSelect
                name="tags"
                tags={cameraMetaUpdate.tags || []}
                onChange={handleMetaTagChange}
                readOnly={!hasPermission}
            />
            <FormTextField
                name="license"
                label="Lizenz"
                object={cameraMetaUpdate}
                handleChange={handleChangeMeta}
                hasPermission={hasPermission}
                select
                selectOptions={license}
            />
            {hasPermission && isUpdated && (
                <Stack
                    direction="row"
                    spacing={1}
                    sx={{
                        justifyContent: "flex-end",
                        alignItems: "center",
                        width: "100%",
                        paddingTop: 1,
                    }}
                >
                    <FormButtonGroup 
                        handleReset={handleReset}
                        handleUpdate={handleUpdate}
                    />
                </Stack>
            )}
        </>
    );
}
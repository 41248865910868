import * as React from "react";

import Avatar from "@mui/material/Avatar";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ImageIcon from '@mui/icons-material/Image';

function ImageUploadField({
    uploadedFile = null,
    setUploadedFile = () => {},
    width = 100,
    height = 100,
    readOnly = false,
}) {
    const [isHovered, setIsHovered] = React.useState(false);
    const [isDragging, setIsDragging] = React.useState(false);

    const onDrop = (acceptedFiles) => {
        const file = acceptedFiles.find(
            (file) => file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/svg+xml",
        );
        if (file) {
            setUploadedFile(URL.createObjectURL(file));
        }
    };

    const handleDragEnter = (event) => {
        if (readOnly) return;
        event.preventDefault();
        setIsHovered(true);
        setIsDragging(true);
    };

    const handleDragOver = (event) => {
        if (readOnly) return;
        event.preventDefault();
        setIsDragging(true);
    };

    const handleDragLeave = () => {
        if (readOnly) return;
        setIsDragging(false);
    };

    const handleDrop = (event) => {
        if (readOnly) return;
        event.preventDefault();
        setIsHovered(false);
        setIsDragging(false);

        const files = Array.from(event.dataTransfer.files);
        onDrop(files);
    };

    const handleMouseEnter = () => {
        if (readOnly) return;
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        if (readOnly) return;
        setIsHovered(false);
        setIsDragging(false);
    };

    const handleFileUpload = () => {
        if (readOnly) return;
        const fileInput = document.createElement("input");
        fileInput.type = "file";
        fileInput.accept = "image/jpeg, image/png, image/svg+xml";
        fileInput.onchange = (event) => {
            const files = Array.from(event.target.files);
            onDrop(files);
        };
        fileInput.click();
    };

    const avatarSrc = (
        uploadedFile && uploadedFile.includes("blob:")
    ) ? uploadedFile : (
        uploadedFile
    ) ? uploadedFile + "?t=" + new Date().getTime() : null;

    return (
        <div
            style={{
                position: "relative",
                display: "inline-block",
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onDragEnter={handleDragEnter}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
        >
            <div
                style={{
                    width: width,
                    height: height,
                    borderRadius: 1,
                    overflow: "hidden",
                }}
            >
                <Avatar
                    variant="rounded"
                    alt="Remy Sharp"
                    src={avatarSrc}
                    sx={{ width: width, height: height, borderRadius: 1 }}
                >
                    <ImageIcon />
                </Avatar>
            </div>

            {isHovered && (
                <div
                    style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        height: "100%",
                        backgroundColor: isDragging
                            ? "rgba(0, 0, 0, 0.7)"
                            : "rgba(0, 0, 0, 0.5)",
                        borderRadius: 4,
                        cursor: "pointer",
                        zIndex: 1,
                    }}
                    onClick={handleFileUpload}
                >
                    {isDragging ? (
                        <CloudUploadIcon
                            style={{ fontSize: height / 2, color: "white" }}
                        />
                    ) : (
                        <CloudUploadIcon
                            style={{ fontSize: height / 2, color: "white" }}
                        />
                    )}
                </div>
            )}
        </div>
    );
}

ImageUploadField.propTypes = {};

export default ImageUploadField;

import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from "prop-types";

import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';


export default function PlayerPreviewOverlay({ 
    dialogOpen = true,
    setDialogOpen = () => {}, 
    mousePosition = { x: 0, y: 0 },
    player = {}, 
}) {

    const [anchorEl, setAnchorEl] = React.useState(null);
    React.useEffect(() => {
        if (player) {
            setAnchorEl(mousePosition ? document.elementFromPoint(mousePosition.x, mousePosition.y) : null);
        }
    }, [player, mousePosition]);

    const handleCloseMenu = () => {
        setDialogOpen(false);
    };

    return (
        <Menu
            open={Boolean(dialogOpen && player)}
            onClose={handleCloseMenu}
            anchorEl={anchorEl}
        >
            <MenuList dense sx={{
                paddingTop: 0
            }}>
                <Stack
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={.5}
                    padding={"10px 15px 10px 15px"}
                    maxWidth={500}
                    width={250}
                    sx={{
                        backgroundColor: "rgba(0,0,0,.02)",
                    }}
                >
                    <Stack
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={0}
                        style={{
                            wordWrap: "break-word",
                            marginBottom: -5,
                        }}
                    >
                        <Typography variant="h6">Domains:</Typography>
                    </Stack>
                    {player.domain_whitelist
                        .filter(domain => !domain.startsWith('*')) // Filtern von Domains, die mit "*" beginnen
                        .sort((a, b) => a.localeCompare(b)) // Sortieren der verbleibenden Domains
                        .map((domain, index) => (
                            <Typography fontSize={15} key={index}>
                                <a href={`https://${domain}`} target="_blank" rel="noopener noreferrer" style={{ color: '#0085e9' }}>
                                    {domain}
                                </a>
                            </Typography>
                        ))
                    }
                    {!player.domain_whitelist.length && (
                        <Typography variant="body2">-</Typography>
                    )}
                </Stack>
                <Divider sx={{marginTop: 0}} />
                <MenuItem
                    component={Link}
                    to={`/playback/player/${player.id}`}
                >
                    <ListItemText>Zum Player wechseln</ListItemText>
                </MenuItem>
                <Divider />
                <MenuItem
                    component={Link}
                    to={`/analytics?player_id=${player.id}`}
                >
                    <ListItemText>Analytics aufrufen</ListItemText>
                </MenuItem>
            </MenuList>
        </Menu>
    );
}

PlayerPreviewOverlay.propTypes = {
    dialogOpen: PropTypes.bool,
    setDialogOpen: PropTypes.func,
    mousePosition: PropTypes.object,
    player: PropTypes.object,
};

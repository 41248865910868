
import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";



export default function FormDialog({
    open = false,
    handleClose = () => {},
    dialogTitle = "",
    dialogContent = null,
    dialogActions = null,
}) {
    const theme = useTheme();
    const downMd = useMediaQuery(theme.breakpoints.down("md"));
    return (
        <Dialog
            open={open} 
            onClose={handleClose}
            sx={{
                borderRadius: 0,
                "& .MuiDialog-paper": {
                    borderRadius: 1,
                    padding: 0,
                    minWidth: downMd ? "auto" : 650,
                },
            }}
        >
            <DialogTitle fontSize={22} sx={{padding: "10px 24px"}}>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{
                        color: "#0085E9"
                    }}
                >
                    <span>{dialogTitle}</span>
                    <IconButton
                        aria-label="open-edit"
                        onClick={handleClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <Divider sx={{marginTop: 0, marginBottom: 0}} />
            <DialogContent sx={{paddingBottom: 0}}>
                {dialogContent}
            </DialogContent>
            <DialogActions 
                sx={{
                    paddingLeft: 3,
                    paddingRight: 3,
                    paddingTop: 2,
                    paddingBottom: 2,
                }}
            >
                {dialogActions}
            </DialogActions>
        </Dialog>
    );
}
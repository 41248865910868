import * as React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import 'moment-timezone';

import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';

import Page from '../../components/Page';

import PlayoutSidebar from './PlayoutSidebar';
import PlayoutTilesView from './PlayoutTilesView';
import PlayoutDetails from './PlayoutDetails';

import { useApp } from '../../context/App';

function Playout({ rootPath }) {

	const { client, backdrop, setSelectedPage, reload, hasPermission } = useApp();
	const [ready, setReady] = React.useState(false);

	// PERMISSION CHECK
	const playoutAdminRoles = ["admin", "playout:admin"];
	const playoutEditorRoles = playoutAdminRoles.concat([
		"editor",
		"playout:editor",
	]);

	// PAGE SIDEBAR
	const [openSidebar, setOpenSidebar] = React.useState(false);
	const handleSidebarToggle = () => {
		setOpenSidebar(!openSidebar);
	};

	// PLAYER OBJECT
	const [playerConfig, setPlayerConfig] = React.useState(false);
	const getPlayerConfig = async () => {
		if (!playerConfig) {
			const data = client.PlayerConfig();
			setPlayerConfig(data);
		}
		return playerConfig;
	};

	// CAMERA OBJECTS
	const navigate = useNavigate();
	const { playout_id } = useParams();
	const [playouts, setPlayouts] = React.useState(false);
	const [playout, setPlayout] = React.useState(false);
	const handleSelectPlayout = React.useCallback(
		(playout) => () => {
			setPlayout(playout);
			navigate(`${rootPath}/${playout.id}`);
			setOpenSidebar(true);
		},
		[navigate],
	);

	// PAGINATION
	const [page, setPage] = React.useState(1);
	const handleChange = (event, value) => {
		setPage(value);
	};
	const [pageSize, setPageSize] = React.useState(50);
	const handlePageSize = (event) => {
		setPageSize(event.target.value);
		setPage(1);
	};

	const getPlayouts = async () => {
		const data = await client.GetCustomerPlayout({});
		setPlayouts(data);
		if (data && playout_id && !playout) {
			for (const p in data) {
				if (data[p].id === playout_id) {
					setPlayout(data[p]);
					setOpenSidebar(true);
				}
			}
		}
		setPage(1);
	};

	const updatePlayout = async ({
		playout_id = null,
		playout = null,
		playout_logo_image = null,
		playout_background_image = null,
	}) => {
		if (!playout_id) return;
		let data = null;
		if (playout) {
			data = await client.PutCustomerPlayoutId({
				playout_id: playout_id, playout: playout
			});
		}
		if (playout_logo_image) {
			data = await client.PutCustomerPlayoutIdLogoImage({
				playout_id: playout_id, image: playout_logo_image
			});
		}
		if (playout_background_image) {
			data = await client.PutCustomerPlayoutIdBackgroundImage({
				playout_id: playout_id, image: playout_background_image
			});
		}
		if (data) {
			setPlayout(data);
			setPlayouts(playouts.map((item) => (item.id === playout_id ? data : item)));
		}
	};

	const [cameras, setCameras] = React.useState([]);
	const getCaneras = async () => {
		const data = await client.GetCustomerCamera({});
		setCameras(data || []);
	};

	const filteredCameras = React.useMemo(
		() =>
			cameras
				.filter((obj) => obj.meta.name_short && obj.meta.name_short.trim() !== "")
				.filter((obj) => obj.meta.logo_url && obj.meta.logo_url.trim() !== "")
				.filter((obj) => obj.sla || obj.meta.producer === "livespotting.com"),
		[cameras]
	);

	React.useEffect(() => {
		(async () => {
			setSelectedPage('live_playout');
			await getPlayouts();
			await getCaneras();
			await getPlayerConfig();
			setReady(true);
			return () => {
				setReady(false);
			};
		})();
		// eslint-disable-next-line
	}, [reload]);

	React.useEffect(() => {
		backdrop(!ready);
	}, [ready, backdrop]);

	React.useEffect(() => {
		if (playouts && playout_id) {
			if (!playout || playout.id !== playout_id) {
				const data = playouts.find((playout) => playout.id === playout_id);
				if (!data) {
					navigate(rootPath);
				} else {
					setPlayout(data);
					setOpenSidebar(true);
				}
			}
		} else {
			setPlayout(null);
			setOpenSidebar(false);
		}
	}, [playouts, playout_id]);

	const drawerWidth = 480;

	const breadcrumbs = () => {
		if (playout) {
			return [
				{ title: 'Live', link: '#' },
				{ title: 'Playout', link: rootPath },
				{ title: `${playout.name}`, link: `${rootPath}/${playout_id}` }
			]
		} else {
			return [
				{ title: 'Live', link: '#' },
				{ title: 'Playout', link: rootPath }
			]
		}
	}

	return (
		<Page
			pageTitel={"Playout"}
			drawerWidth={drawerWidth}
			breadcrumbs={breadcrumbs ? breadcrumbs() : []}
			headerContentRight={
				!openSidebar && playout && (
					<IconButton
						color="inherit"
						aria-label="open drawer"
						edge="end"
						onClick={handleSidebarToggle}
					>
						<MenuIcon />
					</IconButton>
				)
			}
			openSidebar={openSidebar}
			handleSidebarToggle={handleSidebarToggle}
			sidebarContent={
				playout && playerConfig && cameras && (
					<PlayoutSidebar
						key={playout.id}
						drawerWidth={'100%'}
						playout={playout}
						updatePlayout={updatePlayout}
						playerConfig={playerConfig}
						cameras={cameras}
						hasPermission={hasPermission(playoutEditorRoles)}
						openSidebar={openSidebar}
						handleSidebarToggle={handleSidebarToggle}
					/>
				)
			}
		>
			{playouts && playout && playout.playlist && playerConfig && (
				<PlayoutDetails
					playout={playout}
					updatePlayout={updatePlayout}
					cameras={filteredCameras}
					playerConfig={playerConfig}
					handleSelectPlayout={handleSelectPlayout}
					hasPermission={hasPermission(playoutEditorRoles)}
				/>
			)}
			{playouts && !playout && playerConfig && (
				<PlayoutTilesView
					playouts={playouts}
					page={page}
					pageSize={pageSize}
					playerConfig={playerConfig}
					handleSelectPlayout={handleSelectPlayout}
					handleChange={handleChange}
					handlePageSize={handlePageSize}
				/>
			)}
		</Page>
	);
}

Playout.propTypes = {
	openMenu: PropTypes.bool,
	handleMenuToggle: PropTypes.func,
	setSelectedPage: PropTypes.func,
};

Playout.defaultProps = {
	openMenu: false,
};

export default Playout;

import React from 'react';
import { Stack, Paper, FormControl, Select, MenuItem } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import fallback from '../../images/fallback.png';


function PlayoutItem({ playout, playerConfig, handleSelectPlayout }) {
	const [backgroundImage, setBackgroundImage] = React.useState(`url(${fallback})`);

	React.useEffect(() => {
		const img = new Image();
		const ts = Date.now();
		img.src = `${playerConfig.url}${playout.camera_id}/live/main_240.jpg?${ts}`;
		img.onload = () => {
			setBackgroundImage(`url(${img.src})`);
		};
	}, []);

	return (
		<Grid
			item
			key={playout.id}
			xs={12}
			sm={12}
			md={6}
			lg={4}
			xl={4}
			xxl={4}
			alignItems="flex-start"
			style={{ cursor: 'pointer' }}
			onClick={handleSelectPlayout(playout)}
		>
			<Paper
				elevation={0}
				sx={{
					position: 'relative',
					backgroundImage: backgroundImage,
					backgroundSize: 'cover',
					backgroundColor: 'transparent',
					padding: 0,
					paddingTop: '56.25%',
					height: 0,
					width: 'auto',
					borderRadius: 1,
				}}
			>
				<Stack
					direction="column"
					justifyContent="space-between"
					alignItems="stretch"
					spacing={2}
					width={'100%'}
					height={'100%'}
					sx={{ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0 }}
				>
					<Stack
						direction="row"
						justifyContent="space-between"
						alignItems="flex-start"
						spacing={0}
						width={'100%'}
						marginTop={0.8} 
						marginBottom={1.4} 
						sx={{
							background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0))',
							//background: 'linear-gradient(to bottom, rgba(193,39,45,.9), rgba(193,39,45,0))',
							// 
							width: '100%',
							borderRadius: 1,
							height: '35%',
							padding: '0.15em 0.8em',
						}}
					>
						<Stack 
							direction="column" 
							width={'100%'}
						>
							<Typography
								variant="h5"
								//marginBottom={0}
								sx={{
									fontSize: '1rem',
									fontWeight: 500,
									color: '#ffffff',
									lineHeight: '1.1rem',
									marginBottom: '0.3em',
									marginTop: '0.3em',
								}}
							>
								{playout.name}
							</Typography>
						</Stack>
						{/* {!playout.status || playout.status !== 'healthy' && (
							<Stack 
							direction="column" 
							width={'auto%'}
							marginTop={.3}
						>
							<ReportProblemIcon style={{ width: 17, color: "white" }} />
						</Stack> )}*/}
						
					</Stack>
					<Stack 
						direction="row" 
						marginTop={0.8} 
						spacing={2}
						sx={{
							background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6))',
							width: '100%',
							borderRadius: 1,
							height: '25%',
							justifyContent: "space-between",
   							alignItems: "flex-end",
							paddingBottom: 1.2,
							paddingLeft: .2,
						}}
					>
					</Stack>
				</Stack>
			</Paper>
		</Grid>
	);
}

function PlayoutList({ playouts, page, pageSize, playerConfig, handleSelectPlayout }) {
	const paginateArray = (array, pageNumber, pageSize) => {
		const page = array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
		return page;
	};

	const ts = Math.floor(Date.now() / 1000 / 60); // timestamp in minutes

	return (
		<Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={1} marginTop={'-7px'}>
			{paginateArray(playouts, page, pageSize).map((playout) => (
				<PlayoutItem key={playout.id} playout={playout} playerConfig={playerConfig} handleSelectPlayout={handleSelectPlayout} />
			))}
		</Grid>
	);
}

PlayoutList.propTypes = {
	playouts: PropTypes.array.isRequired,
	page: PropTypes.number.isRequired,
	pageSize: PropTypes.number.isRequired,
	playerConfig: PropTypes.object.isRequired,
	handleSelectPlayout: PropTypes.func.isRequired,
};


export default function PlayoutTilesView({
    playouts, page, pageSize, playerConfig, handleSelectPlayout, handleChange, handlePageSize
}) {
    return (
        <Stack direction="row" justifyContent="flex-start" alignItems="stretch" spacing={2} marginTop={1.5}>
            <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={2} width={'100%'}>
                <Stack direction="row" justifyContent="flex-start" alignItems="stretch" spacing={0} paddingBottom={5}>
                    {playouts && (
                        <PlayoutList
                            playouts={playouts}
                            page={page}
                            pageSize={pageSize}
                            playerConfig={playerConfig}
                            handleSelectPlayout={handleSelectPlayout}
                        />
                    )}
                </Stack>
                <Stack direction="row" justifyContent="center" alignItems="center" spacing={2} marginBottom={2}>
                    <Paper
                        sx={{
                            p: '4px 8px',
                            borderRadius: 2,
                            display: 'flex',
                            alignItems: 'center',
                        }}
                        component="form"
                    >
                        <Pagination
                            count={playouts ? Math.ceil(playouts.length / pageSize) : 0}
                            page={page}
                            onChange={handleChange}
                            variant="outlined"
                            shape="rounded"
                        />
                        <FormControl size="small" variant="outlined" color="primary">
                            <Select value={pageSize} onChange={handlePageSize}>
                                <MenuItem value={25}>25</MenuItem>
                                <MenuItem value={50}>50</MenuItem>
                                <MenuItem value={100}>100</MenuItem>
                                <MenuItem value={250}>250</MenuItem>
                            </Select>
                        </FormControl>
                    </Paper>
                </Stack>
            </Stack>
        </Stack>
    );
}
import axios from 'axios';
import moment from 'moment';
import { Buffer } from 'buffer';

const STORAGE_KEY = 'cm.livespotting.com';

class API {
	constructor(address) {
		this.base = '/api/v1';
		this.address = address;
		this.cache = new Map();
		this.axiosInstance = null;
		this.notificator = null;
		this.precallAction = null;
		this.postcallAction = null;
		this.storage = null;
	}

	RefreshPage() {
		window.location.reload(false);
	}

	SetNotificator(notifyFunc) {
		this.notificator = notifyFunc;
	}

	SetPrePostCallActions(pre, post) {
		this.precallAction = pre;
		this.postcallAction = post;
	}

	// Browser storage

	writeStorage(data) {
		localStorage.setItem(STORAGE_KEY, JSON.stringify(data));
	}

	readStorage() {
		if (!this.storage) {
			const data = localStorage.getItem(STORAGE_KEY);
			if (data) {
				try {
					this.storage = JSON.parse(data);
				} catch (e) {
					this.storage = null;
				}
			}
		}
		return this.storage;
	}

	deleteStorage() {
		this.storage = null;
		localStorage.removeItem(STORAGE_KEY);
	}

	// JWT methods

	getAccessToken() {
		const data = this.readStorage();
		if (data && 'access_token' in data) {
			return data.access_token;
		}
		return '';
	}

	setAccessToken(token) {
		const data = this.readStorage();
		if (data) {
			data.access_token = token;
			this.storage = data;
			this.writeStorage(data);
		}
	}

	getRefreshToken() {
		const data = this.readStorage();
		if (data && 'refresh_token' in data) {
			return data.refresh_token;
		}
		return '';
	}

	getCustomerId() {
		const data = this.readStorage();
		if (data && 'customer' in data) {
			return data.customer.id;
		}
		return '';
	}

	setCustomer(customer) {
		const data = this.readStorage();
		if (data) {
			data.customer = customer;
			this.storage = data;
			this.writeStorage(data);
		}
	}

	PlayerConfig() {
		const data = this.readStorage();
		if (data.customer?.core) {
			return {
				url: data.customer.core.streaming_url + '/memfs/' + data.customer.id + '/',
				videoUrl: data.customer.core.streaming_url + '/s3/' + data.customer.id + '/',
				token: data.customer.core.streaming_token,
			};
		} else {
			return {
				url: data.customer.streaming_url + '/memfs/' + data.customer.id + '/',
				videoUrl: data.customer.streaming_url + '/s3/' + data.customer.id + '/',
				token: data.customer.streaming_token,
			};
		}
	}

	async getFiledata(image, filename) {
		let imageBlob = null;

		if (image instanceof Blob) {
			imageBlob = image;
			if (image.name) {
				filename = image.name;
			}

			return [imageBlob, filename];
		}

		if (this.precallAction !== null) {
			this.precallAction();
		}

		try {
			const response = await axios.get(image, {
				responseType: 'blob',
			});
			imageBlob = response.data;
			if (response.headers['content-disposition']) {
				const contentDisposition = response.headers['content-disposition'];
				const matches = contentDisposition.match(/filename="([^"]+)"/);
				if (matches && matches.length > 1) {
					filename = matches[1];
				}
			}
		} catch (error) {
			if (this.notificator !== null) {
				this.notificator('warning', error.message);
			}
		} finally {
			if (this.postcallAction !== null) {
				this.postcallAction();
			}
		}

		return [imageBlob, filename];
	}

	// Axios instance with JWT interceptor

	getAxiosInstance() {
		if (!this.axiosInstance) {
			this.axiosInstance = axios.create({
				baseURL: this.address,
				headers: this.getAccessHeader(),
			});

			this.axiosInstance.interceptors.request.use(
				(config) => {
					const headers = this.getAccessHeader();
					if (headers) {
						config.headers = headers;
					}
					return config;
				},
				(error) => {
					return Promise.reject(error);
				},
			);
		}

		return this.axiosInstance;
	}

	async call(method, url, params = {}, data = {}, headers = {}) {
		if (this.precallAction !== null) {
			this.precallAction();
		}

		try {
			const response = await this.getAxiosInstance().request({
				method: method,
				url: url,
				params: params,
				data: data,
				headers: headers,
			});
			return response.data;
		} catch (error) {
			if (error.response?.status === 401) {
				const token = await this.refreshToken();
				if (token === false) {
					this.handleError(error);
					await this.logout(true);
					return null;
				}

				// Retry the original request with the new token
				try {
					const response = await this.getAxiosInstance().request({
						method: method,
						url: url,
						params: params,
						data: data,
						headers: headers,
					});
					return response.data;
				} catch (error) {
					this.handleError(error);
					await this.logout(true);
					return null;
				}
			} else {
				this.handleError(error);
			}

			return null;
		} finally {
			if (this.postcallAction !== null) {
				this.postcallAction();
			}
		}
	}

	handleError(error, message) {
		if (this.notificator === null) {
			return;
		}

		if (message) {
			this.notificator('warning', message);
			return;
		}

		message = 'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später nochmals.';

		if (error.response) {
			const location = error.config.method.toUpperCase() + ' ' + error.config.url;

			if (error.response.status === 401) {
				return;
			}

			if (error.response.status !== 422) {
				message = 'Fehler beim Aufruf von ' + location + ': ' + error.response.data.detail;
			} else {
				message = 'Fehler beim Aufruf von ' + location + ': Fehler bei der Validierung. Details in der browser console.';

				for (let e of error.response.data.detail) {
					console.warn(`In the ${e.loc[0]} the value for ${e.loc[1]}: ${e.msg}`);
				}
			}

			this.notificator('warning', message);
		} else if (error.request) {
			this.notificator('warning', message);
		} else {
			this.notificator('warning', message);
		}
	}

	async get(url, params = {}, headers = {}) {
		return await this.call('get', url, params, {}, headers);
	}

	async post(url, data = {}, params = {}, headers = {}) {
		return await this.call('post', url, params, data, headers);
	}

	async put(url, data = {}, params = {}, headers = {}) {
		return await this.call('put', url, params, data, headers);
	}

	async delete(url, data = {}, params = {}, headers = {}) {
		return await this.call('delete', url, params, data, headers);
	}

	getAccessHeader() {
		const accessToken = this.getAccessToken();
		if (accessToken) {
			return { Authorization: `Bearer ${accessToken}` };
		}
		return {};
	}

	getRefreshHeader() {
		const refreshToken = this.getRefreshToken();
		if (refreshToken) {
			return {
				Authorization: `Bearer ${refreshToken}`,
			};
		}
		return {};
	}

	// JWT refresh

	async refreshToken() {
		try {
			const response = await axios({
				method: 'put',
				url: `${this.address}/login/refresh`,
				headers: this.getRefreshHeader(),
			});
			const newAccessToken = response.data.access_token;
			this.setAccessToken(newAccessToken);
			return true;
		} catch (error) {
			this.handleError(error);
			return false;
		}
	}

	// LOGIN

	async login(username, password) {
		if (this.precallAction !== null) {
			this.precallAction();
		}

		try {
			const formData = axios.toFormData({
				username: username,
				password: password,
			});
			const response = await axios({
				method: 'post',
				url: `${this.address}/login`,
				headers: {
					'content-type': 'application/x-www-form-urlencoded',
				},
				data: formData,
			});
			this.writeStorage(response.data);
			this.RefreshPage();
			return response.data;
		} catch (error) {
			this.handleError(error, 'Login fehlgeschlagen');
			console.log('error', error);
			return null;
		} finally {
			if (this.postcallAction !== null) {
				this.postcallAction();
			}
		}
	}

	// LOGIN OTP

	async loginTwoFactorAuth({ otp_token }) {
		if (this.precallAction !== null) {
			this.precallAction();
		}

		try {
			const response = await axios({
				method: 'put',
				url: `${this.address}/login/two-factor-auth`,
				headers: this.getAccessHeader(),
				data: {
					otp_token: otp_token,
				},
			});
			this.writeStorage(response.data);
			this.RefreshPage();
			return response.data;
		} catch (error) {
			this.handleError(error, 'Login fehlgeschlagen');
			//this.deleteStorage();
			//this.RefreshPage();
			console.log('error', error);
			return null;
		} finally {
			if (this.postcallAction !== null) {
				this.postcallAction();
			}
		}
	}

	// LOGOUT

	async logout(internal = false) {
		if (!internal && this.precallAction !== null) {
			this.precallAction();
		}

		try {
			await axios({
				method: 'delete',
				headers: this.getAccessHeader(),
				url: `${this.address}/login`,
			});
			this.deleteStorage();
			this.RefreshPage();
		} catch (error) {
			if (!internal) {
				this.handleError(error);
			}
			this.deleteStorage();
			this.RefreshPage();
			return false;
		} finally {
			if (!internal && this.postcallAction !== null) {
				this.postcallAction();
			}
		}

		return true;
	}

	// MISC

	LoggedIn() {
		const data = this.readStorage();
		if (data && !('verify_otp' in data)) {
			const elements = data.access_token.split('.');
			if (elements.length !== 3) {
				return false;
			}
			let payload = Buffer.from(elements[1], 'base64').toString();
			try {
				const data = JSON.parse(payload);
				if (!('exp' in data)) {
					return false;
				}

				if (moment.unix() <= data.exp) {
					return false;
				}
			} catch (e) {
				return false;
			}

			return true;
		}
		return false;
	}

	LoggedInTwoFactor() {
		const data = this.readStorage();
		if (data && 'verify_otp' in data) {
			return true;
		}
		return false;
	}

	UserSetup() {
		const data = this.readStorage();
		if (data && 'setup_wizard' in data) {
			return data.setup_wizard;
		}
		return false;
	}

	CustomerId() {
		return this.getCustomerId();
	}

	CustomerConfig() {
		const data = this.readStorage();
		if (data && 'customer' in data) {
			return {
				name: data.customer.name,
				website: data.customer.name,
				avatar_url: data.customer.avatar_url,
				crm_id: data.customer.crm_id,
			};
		}
		return {};
	}

	UserConfig() {
		const data = this.readStorage();
		if (data && 'user' in data) {
			return {
				type: data.user.type,
				first_name: data.user.first_name,
				last_name: data.user.last_name,
				avatar_url: data.user.avatar_url,
				email: data.user.email,
			};
		}
		return {};
	}

	UserConfigUpdate({ first_name, last_name, avatar_url }) {
		const data = this.readStorage();
		if (data && 'user' in data) {
			data.user.first_name = first_name;
			data.user.last_name = last_name;
			data.user.avatar_url = avatar_url;
			this.writeStorage(data);
		}
	}

	UserPermissionRoles() {
		const data = this.readStorage();
		if (data && 'user' in data) {
			return data.user.permission_roles;
		}
		return [];
	}

	UserPermissionCheck(requiredRoles = []) {
		const roles = this.UserPermissionRoles();
		for (const role of requiredRoles) {
			if (roles.includes(role)) {
				return true;
			}
		}
		return false;
	}

	// USER

	async GetUser() {
		return await this.get(`/v1/user`);
	}

	async PutUser({ data }) {
		return await this.put(`/v1/user`, data);
	}

	async PutUserAvatar({ image }) {
		const [data, filename] = await this.getFiledata(image, '7462030.jpg');
		if (data === null) {
			return null;
		}

		const formData = new FormData();
		formData.append('image', data, filename);

		return await this.put(
			`/v1/user/avatar`,
			formData,
			{},
			{
				accept: 'application/json',
				'Content-Type': 'multipart/form-data',
			},
		);
	}

	async PutUserPasswordChange({ old_password, password, password_confirm } = {}) {
		return await this.put(`/v1/user`, {
			old_password: old_password,
			password: password,
			password_confirm: password_confirm,
		});
	}

	async PostUserTwoFactorAuth({ data }) {
		return await this.post(`/v1/user/two-factor-auth`, data);
	}

	async DeleteUserTwoFactorAuth({ data } = {}) {
		return await this.delete(`/v1/user/two-factor-auth`, data);
	}

	async PutUserRegistration({ data }) {
		return await this.put(`/v1/user/registration`, data);
	}

	// CUSTOMER CAMERA

	async GetCustomerCamera({ camera_id, alias_id, acl_id, meta_search, status, from_date, to_date, skip = '0', limit = '0', hide } = {}) {
		const res = await this.post(`/v1/customer/${this.CustomerId()}/cameras`, {
			camera_id: camera_id,
			alias_id: alias_id,
			acl_id: acl_id,
			meta_search: meta_search,
			status: status,
			from_date: from_date,
			to_date: to_date,
			skip: skip,
			limit: limit,
			hide: hide,
		});

		if (!res) {
			return [];
		}

		return res;
	}

	async GetCustomerCamerasStats({ ...props } = {}) {
		return await this.post(`/v1/customer/${this.CustomerId()}/cameras/stats`, { ...props});
	}

	async GetCustomerCameraId({ camera_id, hide } = {}) {
		const res = await this.get(`/v1/customer/${this.CustomerId()}/camera/${camera_id}`, {
			hide: hide,
		});

		if (!res) {
			return [];
		}

		return res;
	}

	async GetCustomerCameraIdRole({ camera_id }) {
		return await this.get(`/v1/customer/${this.CustomerId()}/camera/${camera_id}/role`);
	}

	async GetCustomerCameraIdAlert({ camera_id, ...props }) {
		return await this.get(`/v1/customer/${this.CustomerId()}/camera/${camera_id}/alert`, { ...props });
	}

	async GetCustomerCameraIdIngest({ camera_id }) {
		return await this.get(`/v1/customer/${this.CustomerId()}/camera/${camera_id}/ingest`);
	}

	async GetCustomerCameraIdStats({ camera_id, ...props }) {
		return await this.post(`/v1/customer/${this.CustomerId()}/camera/${camera_id}/stats`, { ...props});
	}

	async PutCustomerCameraId({ camera_id, camera = {} }) {
		return await this.put(`/v1/customer/${this.CustomerId()}/camera/${camera_id}`, camera);
	}

	async PutCustomerCameraIdLogoImage({ camera_id, image }) {
		const [data, filename] = await this.getFiledata(image, '7462030.jpg');
		if (data === null) {
			return null;
		}

		const formData = new FormData();
		formData.append('image', data, filename);

		return await this.put(
			`/v1/customer/${this.CustomerId()}/camera/${camera_id}/logo-image`,
			formData,
			{},
			{
				accept: 'application/json',
				'Content-Type': 'multipart/form-data',
			},
		);
	}

	async PutCustomerCameraIdPosterImage({ camera_id, image }) {
		const [data, filename] = await this.getFiledata(image, '7462030.jpg');
		if (data === null) {
			return null;
		}

		const formData = new FormData();
		formData.append('image', data, filename);

		return await this.put(
			`/v1/customer/${this.CustomerId()}/camera/${camera_id}/poster-image`,
			formData,
			{},
			{
				accept: 'application/json',
				'Content-Type': 'multipart/form-data',
			},
		);
	}

	// Playout

	async GetCustomerPlayout({ playout_id, acl_id, meta_search, skip = '0', limit = '0', hide } = {}) {
		const res = await this.post(`/v1/customer/${this.CustomerId()}/playouts`, {
			playout_id: playout_id,
			acl_id: acl_id,
			meta_search: meta_search,
			skip: skip,
			limit: limit,
			hide: hide,
		});

		if (!res) {
			return [];
		}

		return res;
	}

	async GetCustomerPlayoutId({ playout_id, hide = 'none' }) {
		const res = await this.get(`/v1/customer/${this.CustomerId()}/playout/${playout_id}`, {
			hide: hide,
		});

		if (!res) {
			return [];
		}

		return res;
	}

	async PutCustomerPlayoutId({ playout_id, playout = {} }) {
		return await this.put(`/v1/customer/${this.CustomerId()}/playout/${playout_id}`, playout);
	}

	async PutCustomerPlayoutIdLogoImage({ playout_id, image }) {
		const [data, filename] = await this.getFiledata(image, '7462030.jpg');
		if (data === null) {
			return null;
		}

		const formData = new FormData();
		formData.append('image', data, filename);

		return await this.put(
			`/v1/customer/${this.CustomerId()}/playout/${playout_id}/logo-image`,
			formData,
			{},
			{
				accept: 'application/json',
				'Content-Type': 'multipart/form-data',
			},
		);
	}

	async PutCustomerPlayoutIdBackgroundImage({ playout_id, image }) {
		const [data, filename] = await this.getFiledata(image, '7462030.jpg');
		if (data === null) {
			return null;
		}

		const formData = new FormData();
		formData.append('image', data, filename);

		return await this.put(
			`/v1/customer/${this.CustomerId()}/playout/${playout_id}/background-image`,
			formData,
			{},
			{
				accept: 'application/json',
				'Content-Type': 'multipart/form-data',
			},
		);
	}

	// restream
	// get ({ args })
	// post ({ obj })
	// get ({ id, args })
	// put ({ id, obj })
	// delete ({ id })

	async GetCustomerRestream({ restream_id, camera_id = null, acl_id = null, status = null, skip = '0', limit = '0' }) {
		const res = await this.post(`/v1/customer/${this.CustomerId()}/restreams`, {
			restream_id: restream_id,
			camera_id: camera_id,
			acl_id: acl_id,
			status: status,
			skip: skip,
			limit: limit,
		});

		if (!res) {
			return [];
		}

		return res;
	}

	async GetCustomerRestreamsStats({ ...props } = {}) {
		return await this.post(`/v1/customer/${this.CustomerId()}/restreams/stats`, { ...props});
	}

	async GetCustomerRestreamId({ restream_id, hide = 'any' }) {
		const res = await this.get(`/v1/customer/${this.CustomerId()}/restream/${restream_id}`, {
			hide: hide,
		});

		if (!res) {
			return [];
		}

		return res;
	}

	async PostCustomerRestream({ restream = {} }) {
		return await this.post(`/v1/customer/${this.CustomerId()}/restream`, restream);
	}

	async PutCustomerRestreamId({ restream_id, restream = {} }) {
		return await this.put(`/v1/customer/${this.CustomerId()}/restream/${restream_id}`, restream);
	}

	async DeleteCustomerRestreamId({ restream_id }) {
		return await this.delete(`/v1/customer/${this.CustomerId()}/restream/${restream_id}`);
	}

	async GetCustomerRestreamIdAlert({ restream_id }) {
		return await this.get(`/v1/customer/${this.CustomerId()}/restream/${restream_id}/alert`);
	}

	async GetCustomerRestreamIdStats({ restream_id, ...props } = {}) {
		return await this.post(`/v1/customer/${this.CustomerId()}/restream/${restream_id}/stats`, { ...props});
	}

	// CUSTOMER VIDEO

	async GetCustomerVideo({ video_id, camera_id, camera_alias_id, acl_id, meta_search, from_date, to_date, skip, limit, hide = 'video_source' }) {
		const res = await this.post(`/v1/customer/${this.CustomerId()}/videos`, {
			video_id: video_id,
			camera_id: camera_id,
			camera_alias_id: camera_alias_id,
			acl_id: acl_id,
			meta_search: meta_search,
			from_date: from_date,
			to_date: to_date,
			skip: skip,
			limit: limit,
			hide: hide,
		});

		if (!res) {
			return [];
		}

		return res;
	}

	// CUSTOMER ACL

	async GetCustomerACL({ hide = 'any' } = {}) {
		return await this.get(`/v1/customer/${this.CustomerId()}/acl`, {
			hide: hide,
		});
	}

	async GetCustomerACLId({ acl_id = null, hide = 'any' } = {}) {
		return await this.get(`/v1/customer/${this.CustomerId()}/acl/${acl_id}`, {
			hide: hide,
		});
	}

	async GetCustomerACLIdLink({ acl_id }) {
		return await this.get(`/v1/customer/${this.CustomerId()}/acl/${acl_id}/link`);
	}

	// CUSTOMER PLAYER

	async GetCustomerPlayer({ acl_id }) {
		const res = await this.get(`/v1/customer/${this.CustomerId()}/player`, {
			acl_id: acl_id,
		});

		if (!res) {
			return [];
		}

		return res;
	}

	async GetCustomerPlayerIdPlaylist({ player_id }) {
		return await this.get(`/v1/customer/${this.CustomerId()}/player/${player_id}/playlist`);
	}

	// CUSTOMER PLAYBACK USER

	async GetCustomerPlaybackUser() {
		const res = await this.get(`/v1/customer/${this.CustomerId()}/playback/user`);

		if (!res) {
			return [];
		}

		return res;
	}

	async GetCustomerPlaybackUserId({ playback_user_id }) {
		const res = await this.get(`/v1/customer/${this.CustomerId()}/playback/user/${playback_user_id}`);

		if (!res) {
			return [];
		}

		return res;
	}

	// CUSTOMER TICKET

	async GetCustomerTicket() {
		return await this.get(`/v1/customer/${this.CustomerId()}/ticket`);
	}

	async PostCustomerTicket({ ticket }) {
		return await this.post(`/v1/customer/${this.CustomerId()}/ticket`, ticket);
	}

	async GetCustomerTicketId({ ticket_id }) {
		return await this.get(`/v1/customer/${this.CustomerId()}/ticket/${ticket_id}`);
	}

	async PutCustomerTicketId({ ticket_id, ticket }) {
		return await this.put(`/v1/customer/${this.CustomerId()}/ticket/${ticket_id}`, ticket);
	}

	async DeleteCustomerTicketId({ ticket_id }) {
		return await this.delete(`/v1/customer/${this.CustomerId()}/ticket/${ticket_id}`);
	}

	async PostCustomerTicketIdMessage({ ticket_id, ticket_message }) {
		return await this.post(`/v1/customer/${this.CustomerId()}/ticket/${ticket_id}/message`, ticket_message);
	}

	async PutCustomerTicketIdMessage({ ticket_id, message_id, ticket_message }) {
		return await this.post(`/v1/customer/${this.CustomerId()}/ticket/${ticket_id}/message/${message_id}`, ticket_message);
	}

	async DeleteCustomerTicketIdMessage({ ticket_id, message_id }) {
		return await this.delete(`/v1/customer/${this.CustomerId()}/ticket/${ticket_id}/message/${message_id}`);
	}

	// CUSTOMER ALERT

	async GetCustomerAlert({ item_type = null } = {}) {
		const res = await this.get(`/v1/customer/${this.CustomerId()}/alert`, {
			item_type: item_type,
		});

		if (!res) {
			return [];
		}

		return res;
	}

	async PutCustomerAlertCamera({ camera_id, alert } = {}) {
		return await this.put(`/v1/customer/${this.CustomerId()}/alert/camera/${camera_id}`, alert);
	}

	async PutCustomerAlertRestream({ restream_id, alert } = {}) {
		return await this.put(`/v1/customer/${this.CustomerId()}/alert/restream/${restream_id}`, alert);
	}

	// CUSTOMER USER

	async GetCustomerUser() {
		return await this.get(`/v1/customer/${this.CustomerId()}/user`);
	}

	async GetCustomerUserId({ username }) {
		return await this.get(`/v1/customer/${this.CustomerId()}/user/${username}`);
	}

	async PutCustomerUserId({ username, user }) {
		return await this.put(`/v1/customer/${this.CustomerId()}/user/${username}`, user);
	}

	async PutCustomerUserIdLogout({ username }) {
		return await this.put(`/v1/customer/${this.CustomerId()}/user/${username}/logout`);
	}

	async PutCustomerUserIdResetPassword({ username }) {
		return await this.put(`/v1/customer/${this.CustomerId()}/user/${username}/password-reset`);
	}

	async DeleteCustomerUserId({ username }) {
		return await this.delete(`/v1/customer/${this.CustomerId()}/user/${username}`);
	}

	// CUSTOMER

	async GetAllCustomers() {
		const customers = await this.get(`/v1/customer`);
		if (customers === null) {
			return [];
		}

		return customers;
	}

	async GetCustomer(id) {
		if (!id) {
			id = this.CustomerId();
		}

		return await this.get(`/v1/customer/${id}`);
	}

	async PostCustomer(customer) {
		return await this.post(`/v1/customer`, customer);
	}

	async PutCustomer(id, customer) {
		return await this.put(`/v1/customer/${id}`, customer);
	}

	async DeleteCustomer(id) {
		return await this.delete(`/v1/customer/${id}`);
	}

	async PutCustomerAvatar(id, image) {
		const [data, filename] = await this.getFiledata(image, '7462031.jpg');
		if (data === null) {
			return null;
		}

		const formData = new FormData();
		formData.append('image', data, filename);

		return await this.put(
			`/v1/customer/${id}/avatar`,
			formData,
			{},
			{
				accept: 'application/json',
				'Content-Type': 'multipart/form-data',
			},
		);
	}

	// CUSTOMER BILLING

	async GetCustomerBilling() {
		return await this.get(`/v1/customer/${this.CustomerId()}/billing`);
	}

	// CUSTOMER LIVE ANALYTICS

	async PostCustomerAnalyticsLive({
		player_id = null,
		camera_id = null,
		device_type = null,
		country_iso = null,
		region_iso = null,
		type = 'live',
		group_by = null,
		from_date = null,
		to_date = null,
		step = 'minute',
	}) {
		const res = await this.post(`/v1/customer/${this.CustomerId()}/analytics/live`, {
			player_id: player_id,
			camera_id: camera_id,
			device_type: device_type,
			country_iso: country_iso,
			region_iso: region_iso,
			type: type,
			group_by: group_by,
			from_date: from_date,
			to_date: to_date,
			step: step,
		});

		if (!res) {
			return [];
		}

		return res;
	}

	// CUSTOMER HISTORICAL ANALYTICS

	async PostCustomerAnalyticsHistory({
		player_id = null,
		camera_id = null,
		content_type = null,
		content_profile = null,
		device_type = null,
		country_iso = null,
		region_iso = null,
		segment1 = 'camera',
		dimension1 = null,
		dimension2 = null,
		dimension3 = null,
		from_date = null,
		to_date = null,
		step = 'days',
	}) {
		const res = await this.post(`/v1/customer/${this.CustomerId()}/analytics/history`, {
			player_id: player_id,
			camera_id: camera_id,
			content_type: content_type,
			content_profile: content_profile,
			device_type: device_type,
			country_iso: country_iso,
			region_iso: region_iso,
			segment1: segment1,
			dimension1: dimension1,
			dimension2: dimension2,
			dimension3: dimension3,
			from_date: from_date,
			to_date: to_date,
			step: step,
		});

		if (!res) {
			return [];
		}

		return res;
	}

	// CUSTOMER REPORT ANALYTICS

	async PostCustomerAnalyticsReport({
		type = "summary",
		from_date = null,
		to_date = null,
	}) {
		const res = await this.post(`/v1/customer/${this.CustomerId()}/analytics/report`, {
			type: type,
			from_date: from_date,
			to_date: to_date,
		});

		if (!res) {
			return null;
		}

		return res;
	}

	// PROTOCOL

	async GetCustomerProtocol({
		api_method = null,
		api_path = null,
		editor_name = null,
		editor_email = null,
		from_date = null,
		to_date = null,
	}) {
		const res = await this.get(`/v1/customer/${this.CustomerId()}/protocol`, {
			api_method: api_method,
			api_path: api_path,
			editor_name: editor_name,
			editor_email: editor_email,
			from_date: from_date,
			to_date: to_date,
		});
	
		if (!res) {
			return [];
		}

		return res;
	}

	// CORE

	async GetCores() {
		return await this.get(`/v1/core`);
	}
}

export default API;

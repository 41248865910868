import * as React from "react";
import { TextField, Chip } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import PropTypes from "prop-types";

function CameraTagSelect({
    name = "tags",
    tags = [],
    readOnly = true,
    onChange = () => { },
}) {
    return (
        <Autocomplete
            name={name}
            multiple
            freeSolo
            size="small"
            id="tags-filled"
            value={tags.filter((tag) => tag !== "")}
            options={[]}
            readOnly={readOnly}
            fullWidth
            onChange={(event, newValue) => {
                onChange(newValue);
            }}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                    const { key, ...tagProps } = getTagProps({ index });
                    return (
                        <Chip
                            variant="standard"
                            size="small"
                            label={option}
                            key={key}
                            {...tagProps}
                        />
                    );
                })
            }
            renderInput={(params) => (
                <TextField {...params} name={name} variant="standard" label="Tags" />
            )}
        />
    );
}

CameraTagSelect.propTypes = {
    name: PropTypes.string,
    tags: PropTypes.array.isRequired,
    readOnly: PropTypes.bool,
    onChange: PropTypes.func,
};

export default CameraTagSelect;
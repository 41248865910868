import * as React from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import LockIcon from "@mui/icons-material/Lock";
import LogoutIcon from "@mui/icons-material/Logout";
import MenuList from "@mui/material/MenuList";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PropTypes from "prop-types";
import SyncLockIcon from "@mui/icons-material/SyncLock";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { useApp } from "../../../../context/App";

function LogoutDialog({
    client,
    username,
    openLogoutDialog,
    handleCloseLogoutDialog,
    reloadPage,
}) {
    const logoutUser = async () => {
        await client.PutCustomerUserIdLogout({ username: username });
        reloadPage();
        handleCloseLogoutDialog();
    };
    return (
        <Dialog
            open={openLogoutDialog}
            onClose={handleCloseLogoutDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Zugang abmelden"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Möchten Sie den Zugang <strong>{username}</strong> wirklich
                    abmelden?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="default"
                    color="warning"
                    onClick={handleCloseLogoutDialog}
                >
                    Nein
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={logoutUser}
                    autoFocus
                >
                    Ja
                </Button>
            </DialogActions>
        </Dialog>
    );
}

LogoutDialog.propTypes = {
    client: PropTypes.object.isRequired,
    username: PropTypes.string.isRequired,
    openLogoutDialog: PropTypes.bool.isRequired,
    handleCloseLogoutDialog: PropTypes.func.isRequired,
    reloadPage: PropTypes.func.isRequired,
};

LogoutDialog.defaultProps = {};

function PasswordResetDialog({
    client,
    username,
    openPasswordResetDialog,
    handleClosePasswordResetDialog,
    reloadPage,
}) {
    const passwordResetUser = async () => {
        await client.PutCustomerUserIdResetPassword({ username: username });
        reloadPage();
        handleClosePasswordResetDialog();
    };

    return (
        <Dialog
            open={openPasswordResetDialog}
            onClose={handleClosePasswordResetDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Passwort zurücksetzen"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Möchten Sie das Passwort für den Zugang{" "}
                    <strong>{username}</strong> wirklich zurücksetzen?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="default"
                    color="warning"
                    onClick={handleClosePasswordResetDialog}
                >
                    Nein
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={passwordResetUser}
                    autoFocus
                >
                    Ja
                </Button>
            </DialogActions>
        </Dialog>
    );
}

PasswordResetDialog.propTypes = {
    client: PropTypes.object.isRequired,
    username: PropTypes.string.isRequired,
    openPasswordResetDialog: PropTypes.bool.isRequired,
    handleClosePasswordResetDialog: PropTypes.func.isRequired,
    reloadPage: PropTypes.func.isRequired,
};

PasswordResetDialog.defaultProps = {};


function EnableDialog({
    client,
    username,
    openEnableDialog,
    handleCloseEnableDialog,
    reloadPage,
}) {
    const enableUser = async () => {
        await client.PutCustomerUserId({
            username: username,
            user: { enabled: true },
        });
        reloadPage();
        handleCloseEnableDialog();
    };

    return (
        <Dialog
            open={openEnableDialog}
            onClose={handleCloseEnableDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Zugang erlauben"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Möchten den Zugang <strong>{username}</strong> erlauben?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="default"
                    color="warning"
                    onClick={handleCloseEnableDialog}
                >
                    Nein
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={enableUser}
                    autoFocus
                >
                    Ja
                </Button>
            </DialogActions>
        </Dialog>
    );
}

EnableDialog.propTypes = {
    client: PropTypes.object.isRequired,
    username: PropTypes.string.isRequired,
    openEnableDialog: PropTypes.bool.isRequired,
    handleCloseEnableDialog: PropTypes.func.isRequired,
    reloadPage: PropTypes.func.isRequired,
};


function DisableDialog({
    client,
    username,
    openDisableDialog,
    handleCloseDisableDialog,
    reloadPage,
}) {
    const disableUser = async () => {
        await client.PutCustomerUserId({
            username: username,
            user: { enabled: false },
        });
        reloadPage();
        handleCloseDisableDialog();
    };

    return (
        <Dialog
            open={openDisableDialog}
            onClose={handleCloseDisableDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Zugang sperren"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Möchten den Zugang <strong>{username}</strong> wirklich
                    sperren?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="default"
                    color="warning"
                    onClick={handleCloseDisableDialog}
                >
                    Nein
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={disableUser}
                    autoFocus
                >
                    Ja
                </Button>
            </DialogActions>
        </Dialog>
    );
}

DisableDialog.propTypes = {
    client: PropTypes.object.isRequired,
    username: PropTypes.string.isRequired,
    openDisableDialog: PropTypes.bool.isRequired,
    handleCloseDisableDialog: PropTypes.func.isRequired,
    reloadPage: PropTypes.func.isRequired,
};

DisableDialog.defaultProps = {};

function DeleteDialog({
    client,
    username,
    openDeleteDialog,
    handleCloseDeleteDialog,
    reloadPage,
}) {
    const deleteUser = async () => {
        await client.DeleteCustomerUserId({ username: username });
        reloadPage();
        handleCloseDeleteDialog();
    };

    return (
        <Dialog
            open={openDeleteDialog}
            onClose={handleCloseDeleteDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Zugang löschen"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Möchten den Zugang <strong>{username}</strong> wirklich
                    löschen?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="default"
                    color="warning"
                    onClick={handleCloseDeleteDialog}
                >
                    Nein
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={deleteUser}
                    autoFocus
                >
                    Ja
                </Button>
            </DialogActions>
        </Dialog>
    );
}

DeleteDialog.propTypes = {
    client: PropTypes.object.isRequired,
    username: PropTypes.string.isRequired,
    openDeleteDialog: PropTypes.bool.isRequired,
    handleCloseDeleteDialog: PropTypes.func.isRequired,
    reloadPage: PropTypes.func.isRequired,
};

DeleteDialog.defaultProps = {};

function AdminUserSidebarMenu({ user, adminRoles }) {
    const { client, hasPermission, reloadPage } = useApp();
    const [anchorEl, setAnchorEl] = React.useState(false);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    // Logout Dialog
    const [openLogoutDialog, setOpenLogoutDialog] = React.useState(false);
    const handleClickOpenLogoutDialog = () => {
        setOpenLogoutDialog(true);
    };
    const handleCloseLogoutDialog = () => {
        setOpenLogoutDialog(false);
    };

    // Password Reset Dialog
    const [openPasswordResetDialog, setOpenPasswordResetDialog] =
        React.useState(false);
    const handleClickOpenPasswordResetDialog = () => {
        setOpenPasswordResetDialog(true);
    };
    const handleClosePasswordResetDialog = () => {
        setOpenPasswordResetDialog(false);
    };

    // Enable Dialog
    const [openEnableDialog, setOpenEnableDialog] = React.useState(false);
    const handleClickOpenEnableDialog = () => {
        setOpenEnableDialog(true);
    };
    const handleCloseEnableDialog = () => {
        setOpenEnableDialog(false);
    };

    // Disable Dialog
    const [openDisableDialog, setOpenDisableDialog] = React.useState(false);
    const handleClickOpenDisableDialog = () => {
        setOpenDisableDialog(true);
    };
    const handleCloseDisableDialog = () => {
        setOpenDisableDialog(false);
    };

    // Delete Dialog
    const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
    const handleClickOpenDeleteDialog = () => {
        setOpenDeleteDialog(true);
    };
    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };

    if (!user.username) {
        return;
    }
    const username = user.username;

    return (
        <>
            <IconButton
                color="primary"
                size="small"
                id="message-menu-button"
                aria-controls={open ? "message-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="message-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": "message-menu-button",
                }}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <MenuList>
                    <MenuItem
                        onClick={handleClickOpenLogoutDialog}
                        disabled={!hasPermission(adminRoles)}
                    >
                        <ListItemIcon>
                            <LogoutIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Abmelden</ListItemText>
                    </MenuItem>
                    <MenuItem
                        onClick={handleClickOpenPasswordResetDialog}
                        disabled={!hasPermission(adminRoles)}
                    >
                        <ListItemIcon>
                            <SyncLockIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Passwort zurücksetzen</ListItemText>
                    </MenuItem>
                    {user.enabled && (
                        <MenuItem
                            onClick={handleClickOpenDisableDialog}
                            disabled={!hasPermission(adminRoles)}
                        >
                            <ListItemIcon>
                                <LockIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>Sperren</ListItemText>
                        </MenuItem>
                    )}
                    {!user.enabled && (
                        <MenuItem
                            onClick={handleClickOpenEnableDialog}
                            disabled={!hasPermission(adminRoles)}
                        >
                            <ListItemIcon>
                                <LockIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>Freischalten</ListItemText>
                        </MenuItem>
                    )}
                    <Divider style={{ borderTop: 1 }} />
                    <MenuItem
                        onClick={handleClickOpenDeleteDialog}
                        disabled={!hasPermission(adminRoles)}
                    >
                        <ListItemIcon>
                            <DeleteIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Löschen</ListItemText>
                    </MenuItem>
                </MenuList>
            </Menu>
            <LogoutDialog
                client={client}
                username={username}
                openLogoutDialog={openLogoutDialog}
                handleCloseLogoutDialog={handleCloseLogoutDialog}
                reloadPage={reloadPage}
            />
            <PasswordResetDialog
                client={client}
                username={username}
                openPasswordResetDialog={openPasswordResetDialog}
                handleClosePasswordResetDialog={handleClosePasswordResetDialog}
                reloadPage={reloadPage}
            />
            {user.enabled && (
                <DisableDialog
                    client={client}
                    username={username}
                    openDisableDialog={openDisableDialog}
                    handleCloseDisableDialog={handleCloseDisableDialog}
                    reloadPage={reloadPage}
                />
            )}
            {!user.enabled && (
                <EnableDialog
                    client={client}
                    username={username}
                    openEnableDialog={openEnableDialog}
                    handleCloseEnableDialog={handleCloseEnableDialog}
                    reloadPage={reloadPage}
                />
            )}
            <DeleteDialog
                client={client}
                username={username}
                openDeleteDialog={openDeleteDialog}
                handleCloseDeleteDialog={handleCloseDeleteDialog}
                reloadPage={reloadPage}
            />
        </>
    );
}

AdminUserSidebarMenu.propTypes = {
    username: PropTypes.string,
    adminRoles: PropTypes.array,
};

AdminUserSidebarMenu.defaultProps = {
    adminRoles: [],
};

export default AdminUserSidebarMenu;

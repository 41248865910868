import * as React from "react";
import PropTypes from "prop-types";

import "moment-timezone";
import moment from "moment";

import Avatar from "@mui/material/Avatar";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid2";
import Moment from "react-moment";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import FormBox from "../../../../components/FormBox";
import PageSidebarHeader from "../../../../components/PageSidebarHeader";
import SidebarAccordion from "../../../../components/SidebarAccordion";

import AdminUserSecurityForm from "./AdminUserSecurityForm";
import AdminUserSidebarMenu from "./AdminUserSidebarMenu";

import { useApp } from "../../../../context/App";


function Role({ name }) {
    return (
        <Chip
            size="small"
            Typographyvariant="outlined"
            style={{ textTransform: "initial", fontWeight: 500, margin: 3, maxWidth: 480 }}
            label={`${name}`}
        />
    );
}
Role.propTypes = {
    name: PropTypes.string,
};
Role.defaultProps = {};


function AdminUserSidebar({
    drawerWidth,
    user,
    adminRoles,
    handleSidebarToggle,
    hasPermission,
    updateUser,
}) {
    const { client } = useApp();
    // SIDEBAR TABPANEL
    const [tab, setTab] = React.useState(0);
    const handleTabChange = (event, newValue) => {
        setTab(newValue);
    };

    const [userDetail, setUserDetail] = React.useState(null);

    React.useEffect(() => {
        (async () => {
            if (!userDetail) {
                client
                    .GetCustomerUserId({
                        username: user.username,
                    })
                    .then((data) => setUserDetail(data));
            }
        })();
        // eslint-disable-next-line
    }, []);

    const translateRole = (role) => {
        switch (role) {
            case "billing":
                return "Buchhaltung";
            case "publisher":
                return "Herausgeber";
            case "marketing":
                return "Marketing";
            case "producer":
                return "Produzent";
            case "editor":
                return "Redaktion";
            case "developer":
                return "Software Entwickler";
            case "technical":
                return "Techniker";
            case "supporter":
                return "Technischer Support";
            default:
                return role;
        }
    }

    return (
        <Stack
            direction="column"
            spacing={0}
            sx={{
                justifyContent: "flex-start",
                alignItems: "flex-start",
                width: "100%",
                paddingBottom: 4,
            }}
        >
            <PageSidebarHeader
                title={`${user.first_name} ${user.last_name}`}
                share={true}
                handleToggle={handleSidebarToggle}
                submenu={
                    <AdminUserSidebarMenu
                        user={user}
                        adminRoles={adminRoles}
                    />
                }
                player={
                    <Stack
                        direction="row"
                        spacing={1}
                        alignItems="flex-start"
                        justifyContent={"space-between"}
                        sx={{
                            width: "100%",
                            paddingBottom: 1,
                        }}
                    >
                        <Paper
                            elevation={0}
                            sx={{
                                borderRadius: 2,
                                padding: 1,
                                p: "0px 0px",
                                display: "flex",
                                alignItems: "center",
                                backgroundColor: "#fff",
                                width: "100px",
                                height: "73px",
                            }}
                        >
                            <Avatar
                                variant="rounded"
                                alt="Remy Sharp"
                                src={user.avatar_url ? user.avatar_url : null}
                                children={user.avatar_url ? null : `${user.name.split(' ')[0][0]}${user.name.split(' ')[1][0]}`}
                                sx={{
                                    width: "100%",
                                    height: "100%",
                                    borderRadius: 1,
                                    fontSize: 42,
                                    color: user.avatar_url ? null : "#000",
                                    backgroundColor: user.avatar_url ? null : "rgba(0,0,0,.045)",
                                }}
                            ></Avatar>
                        </Paper>
                        <FormBox spacing={0}>
                            <Typography fontSize={14}>
                                <strong>Firma:</strong> {user.company}
                            </Typography>
                            <Typography fontSize={14}>
                                <strong>Funktion:</strong> {translateRole(user.role)}
                            </Typography>
                        </FormBox>
                    </Stack>
                }
            />
            <Divider sx={{ marginTop: 0, marginBottom: 0 }} />
            <SidebarAccordion
                first
                title="Beschreibung"
                content={
                    <FormBox>
                        <Grid
                            container
                            justifyContent="space-between"
                            fontSize={14}
                            spacing={1}
                        >
                            {user.username !== user.email && (
                                <Grid item size={12}>
                                    <TextField
                                        label="Anmeldename"
                                        value={user.username}
                                        size="small"
                                        variant="standard"
                                        fullWidth
                                        slotProps={{
                                            input: {
                                                readOnly: true
                                            },
                                            inputLabel: {
                                                shrink: true
                                            }
                                        }}
                                    />
                                </Grid>
                            )}
                            <Grid item size={12}>
                                <TextField
                                    label="Beschreibung"
                                    value={user.description}
                                    size="small"
                                    multiline
                                    variant="standard"
                                    fullWidth
                                    slotProps={{
                                        input: {
                                            readOnly: true
                                        },
                                        inputLabel: {
                                            shrink: true
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item size={12}>
                                <TextField
                                    label="Firma"
                                    value={user.company}
                                    size="small"
                                    multiline
                                    variant="standard"
                                    fullWidth
                                    slotProps={{
                                        input: {
                                            readOnly: true
                                        },
                                        inputLabel: {
                                            shrink: true
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item size={12}></Grid>
                            <Grid item size={12}>
                                <TextField
                                    label="E-Mail"
                                    value={user.email}
                                    size="small"
                                    variant="standard"
                                    fullWidth
                                    slotProps={{
                                        input: {
                                            readOnly: true
                                        },
                                        inputLabel: {
                                            shrink: true
                                        }
                                    }}
                                />
                            </Grid>

                            {user.type === "user" && (
                                <>
                                    <Grid item size={12}>
                                        <TextField
                                            label="Telefon"
                                            value={user.phone}
                                            size="small"
                                            variant="standard"
                                            fullWidth
                                            slotProps={{
                                                input: {
                                                    readOnly: true
                                                },
                                                inputLabel: {
                                                    shrink: true
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item size={12}>
                                        <TextField
                                            label="Mobil"
                                            value={user.mobile ? user.mobile : ""}
                                            size="small"
                                            variant="standard"
                                            fullWidth
                                            slotProps={{
                                                input: {
                                                    readOnly: true
                                                },
                                                inputLabel: {
                                                    shrink: true
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item size={12}>
                                        <Stack
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="center"
                                            width={"100%"}
                                        >
                                            <FormGroup>
                                                <FormControlLabel
                                                    label="E-Mail Opt-In"
                                                    control={
                                                        <Checkbox
                                                            checked={user.email_opt_in}
                                                            disabled
                                                        />
                                                    }
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <FormControlLabel
                                                    label="Telefon Opt-In"
                                                    control={
                                                        <Checkbox
                                                            checked={user.phone_opt_in}
                                                            disabled
                                                        />
                                                    }
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <FormControlLabel
                                                    label="Mobil Opt-In"
                                                    control={
                                                        <Checkbox
                                                            checked={user.mobile_opt_in}
                                                            disabled
                                                        />
                                                    }
                                                />
                                            </FormGroup>
                                        </Stack>
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </FormBox>
                }
            />
            <SidebarAccordion
                title="Berechtigungen"
                expanded
                content={
                    <FormBox>
                        <AdminUserSecurityForm
                            user={user}
                            updateUser={updateUser}
                            hasPermission={hasPermission}
                        />
                    </FormBox>
                }
            />
            <SidebarAccordion
                title="Sitzungen"
                content={
                    <>
                        <FormBox title={"Letzter Zugriff"} spacing={0}>
                            {userDetail && userDetail.session_last && (
                                <>
                                    <Typography fontSize={14} fontWeight={600}>
                                        Standort
                                    </Typography>
                                    <Typography
                                        fontSize={14}
                                        marginBottom={0.5}
                                    >
                                        {userDetail.session_last.location}
                                    </Typography>
                                    <Typography fontSize={14} fontWeight={600}>
                                        IP-Adresse
                                    </Typography>
                                    <Typography
                                        fontSize={14}
                                        marginBottom={0.5}
                                    >
                                        {userDetail.session_last.ip}
                                    </Typography>
                                    <Typography fontSize={14} fontWeight={600}>
                                        Browser Agent
                                    </Typography>
                                    <Typography fontSize={14}>
                                        {userDetail.session_last.agent}
                                    </Typography>
                                    {userDetail.session_last.created_at && userDetail.session_last.expires_at && (
                                        <>
                                            <Typography fontSize={14} fontWeight={600}>
                                                Erstellt:
                                            </Typography>
                                            <Typography fontSize={14}>
                                                <Moment
                                                    format="DD.MM.YYYY HH:mm:ss"
                                                    locale="de"
                                                >
                                                    {moment(userDetail.session_last.created_at).tz(
                                                        moment.tz.guess(),
                                                    )}
                                                </Moment>
                                            </Typography>
                                            <Typography fontSize={14} fontWeight={600}>
                                                Gültig bis:
                                            </Typography>
                                            <Typography fontSize={14}>
                                                <Moment
                                                    format="DD.MM.YYYY HH:mm:ss"
                                                    locale="de"
                                                >
                                                    {moment(userDetail.session_last.expires_at).tz(
                                                        moment.tz.guess(),
                                                    )}
                                                </Moment>
                                            </Typography>
                                        </>
                                    )}
                                </>
                            )}
                            {userDetail && !userDetail.session_last && (
                                <Typography fontSize={14}>
                                    Noch nie eingeloggt.
                                </Typography>
                            )}
                        </FormBox>
                        <FormBox title={"Aktiver Zugriff"} spacing={0}>
                            {userDetail && userDetail.session_active && (
                                <>
                                    <Typography fontSize={14} fontWeight={600}>
                                        Standort
                                    </Typography>
                                    <Typography
                                        fontSize={14}
                                        marginBottom={0.5}
                                    >
                                        {userDetail.session_active.location}
                                    </Typography>
                                    <Typography fontSize={14} fontWeight={600}>
                                        IP-Adresse
                                    </Typography>
                                    <Typography
                                        fontSize={14}
                                        marginBottom={0.5}
                                    >
                                        {userDetail.session_active.ip}
                                    </Typography>
                                    <Typography fontSize={14} fontWeight={600}>
                                        Browser Agent
                                    </Typography>
                                    <Typography fontSize={14}>
                                        {userDetail.session_active.agent}
                                    </Typography>
                                    {userDetail.session_active.created_at && userDetail.session_active.expires_at && (
                                        <>
                                            <Typography fontSize={14} fontWeight={600}>
                                                Erstellt:
                                            </Typography>
                                            <Typography fontSize={14}>
                                                <Moment
                                                    format="DD.MM.YYYY HH:mm:ss"
                                                    locale="de"
                                                >
                                                    {moment(userDetail.session_active.created_at).tz(
                                                        moment.tz.guess(),
                                                    )}
                                                </Moment>
                                            </Typography>
                                            <Typography fontSize={14} fontWeight={600}>
                                                Gültig bis:
                                            </Typography>
                                            <Typography fontSize={14}>
                                                <Moment
                                                    format="DD.MM.YYYY HH:mm:ss"
                                                    locale="de"
                                                >
                                                    {moment(userDetail.session_active.expires_at).tz(
                                                        moment.tz.guess(),
                                                    )}
                                                </Moment>
                                            </Typography>
                                        </>
                                    )}
                                </>
                            )}
                            {userDetail && !userDetail.session_active && (
                                <Typography fontSize={14}>
                                    Schon länger nicht gesehen.
                                </Typography>
                            )}
                        </FormBox>
                    </>
                }
                disabled={!hasPermission}
            />
            <SidebarAccordion
                last
                title="Protokoll"
                content={
                    <FormBox spacing={0}>
                        <Typography fontSize={14}>
                            <strong>Typ:</strong> {user.type === "user" ? "Mensch" : "API"}
                        </Typography>
                        <Typography fontSize={14}>
                            <strong>Erstellt:</strong>{" "}
                            <Moment format="DD.MM.YYYY HH:mm:ss" locale="de">
                                {moment(user.created_at).tz(
                                    moment.tz.guess(),
                                )}
                            </Moment>
                        </Typography>
                        {user.updated_at && (
                            <Typography fontSize={14}>
                                <strong>Aktualisiert:</strong>{" "}
                                <Moment
                                    format="DD.MM.YYYY HH:mm:ss"
                                    locale="de"
                                >
                                    {moment(user.updated_at).tz(
                                        moment.tz.guess(),
                                    )}
                                </Moment>
                            </Typography>
                        )}
                    </FormBox>
                }
                disabled={!hasPermission}
            />
        </Stack>
    );
}

AdminUserSidebar.propTypes = {
    drawerWidth: PropTypes.number.isRequired,
    user: PropTypes.object.isRequired,
    adminRoles: PropTypes.array,
};

export default AdminUserSidebar;

import { Stack, Typography } from "@mui/material";

export default function FormBox({ 
    title = "", 
    children = null, 
    spacing = 1, 
}) {
    return (
        <>
            {title && (
                <Stack 
                    direction="column" 
                    spacing={spacing}
                    sx={{
                        width: "100%", 
                        paddingTop: 1, 
                        paddingBottom: 1
                    }}
                >
                    <Typography fontSize={16} fontWeight={500}>
                        {title}
                    </Typography>
                </Stack>
            )}
            <Stack
                direction="column"
                spacing={spacing}
                sx={{
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    width: "100%",
                    padding: "15px 20px",
                    backgroundColor: "rgba(0,0,0,.045)",
                    borderRadius: 1,
                }}
            >
                {children}
            </Stack>
        </>
    );
}
import * as React from "react";

import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Popover from "@mui/material/Popover";
import PropTypes from "prop-types";
import Stack from "@mui/material/Stack";

import { useApp } from "../../context/App";

function CustomerButton(props) {
    const { client } = useApp();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const customer = client.CustomerConfig();

    return (
        <Paper
            elevation={0}
            sx={{
                borderRadius: '8px',
                //border: '1px solid rgba(0,0,0,.1)',
                padding: 1,
                p: "0px 0px",
                display: "flex",
                alignItems: "center",
                backgroundColor: "#fff",
            }}
            component="form"
        >
            <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
            >
                <Avatar
                    variant="rounded"
                    alt="Remy Sharp"
                    src={customer.avatar_url}
                    sx={{ width: 48, height: 48, borderRadius: 2 }}
                    aria-owns={open ? "mouse-over-popover" : undefined}
                    aria-haspopup="true"
                    onMouseEnter={handlePopoverOpen}
                    onMouseLeave={handlePopoverClose}
                ></Avatar>
                <Popover
                    id="mouse-over-popover"
                    sx={{
                        pointerEvents: "none",
                    }}
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                    transformOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                    onClose={handlePopoverClose}
                    disableRestoreFocus
                    width={"100%"}
                >
                    <Stack
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={0}
                        width={200}
                    >
                        <Stack
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            spacing={2}
                            paddingLeft={2}
                            paddingRight={2}
                            paddingTop={1}
                            paddingBottom={1}
                            fontWeight={600}
                            fontSize={16}
                            width={"100%"}
                            backgroundColor={"rgba(0,0,0,.03)"}
                        >
                            Kundenprofil
                        </Stack>
                        <Divider style={{ borderWidth: 1, marginTop: 0 }} />
                        <Stack
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            spacing={2}
                            paddingLeft={2}
                            paddingRight={2}
                            paddingTop={0.5}
                            fontWeight={600}
                            fontSize={14}
                        >
                            Kundenname
                        </Stack>
                        <Stack
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            spacing={2}
                            paddingLeft={2}
                            paddingRight={2}
                            fontSize={14}
                        >
                            {customer.name}
                        </Stack>
                        <Stack
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            spacing={2}
                            paddingLeft={2}
                            paddingRight={2}
                            paddingTop={0.5}
                            fontWeight={600}
                            fontSize={14}
                        >
                            Kundennummer
                        </Stack>
                        <Stack
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            spacing={2}
                            paddingLeft={2}
                            paddingRight={2}
                            fontSize={14}
                        >
                            {customer.crm_id}
                        </Stack>
                        <Stack
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            spacing={2}
                            marginTop={1}
                            paddingTop={1}
                            paddingLeft={2}
                            paddingRight={2}
                            backgroundColor={"#0084E8"}
                            color={"#fff"}
                            width={"100%"}
                            fontWeight={600}
                            fontSize={16}
                        >
                            livespotting Support:
                        </Stack>
                        <Stack
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            spacing={2}
                            paddingLeft={2}
                            paddingRight={2}
                            paddingBottom={1}
                            backgroundColor={"#0084E8"}
                            color={"#fff"}
                            width={"100%"}
                            fontSize={14}
                        >
                            +49 (0) 431 556 834 20
                        </Stack>
                    </Stack>
                    {/* <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        style={{backgroundColor: '#0084E8', color: '#fff', padding: 10, width: 250}}
                    >
                        <Grid item xs={12} fontWeight={600} fontSize={16}>
                            livespottng Kontakt:
                        </Grid>
                        <Grid item xs={12} fontSize={16}>
                            +49 (0) 431 554 822 0
                        </Grid>
                    </Grid> */}
                </Popover>
            </Stack>
        </Paper>
    );
}

CustomerButton.propTypes = {
    window: PropTypes.func,
};

export default CustomerButton;

import React from 'react';
import { Stack } from '@mui/material';

import FormButtonGroup from '../../../../components/FormButtonGroup';
import FormTextField from '../../../../components/FormTextField';
import { removeEmptyStrings, useFormSection } from '../../../../components/misc/useForm';

import { useApp } from "../../../../context/App";


export default function UserPermissionRolesForm({
    user = {},
    updateUser = () => {},
    hasPermission = false, 
}) {
    const { client } = useApp();
    const userDefault = {
        acl_id: user.acl?.id || "",
        permission_roles: user.permission_roles || [],
    };
    const [acls, setAcls] = React.useState(false);
    const { 
        isUpdated, 
        setIsUpdated, 
        formUpdate: userUpdate, 
        handleChange,
        handleReset,
    } = useFormSection({
        formDefault: userDefault,
    });

    React.useEffect(() => {
        (async () => {
            let data = client.GetCustomerACL();
            data = data.then((acls) => {
                const mapped = acls.map((acl) => ({ value: acl.id, label: acl.name }));
                setAcls(mapped);
            });
        })();
        // eslint-disable-next-line
    }, [user]);

    async function handleUpdate() {    
        updateUser({
            username: user.username, 
            user: removeEmptyStrings(userUpdate)
        });
        setIsUpdated(false);
    }

    const permissionRoles = [
        {
            value: 'admin',
            label: 'Administrator',
        },
        {
            value: 'viewer',
            label: 'Uneingeschränkt (Lesen)',
        },
        {
            value: 'editor',
            label: 'Uneingeschränkt (Bearbeiten)',
        },
        {
            value: 'billing',
            label: 'Abrechnung',
        },
        {
            value: 'camera:viewer',
            label: 'Kamera (Lesen)',
        },
        {
            value: 'camera:editor',
            label: 'Kamera (Bearbeiten)',
        },
        {
            value: 'camera:admin',
            label: 'Kamera (Verwalten)',
        },
        {
            value: 'restream:viewer',
            label: 'Restreaming (Lesen)',
        },
        {
            value: 'restream:editor',
            label: 'Restreaming (Bearbeiten)',
        },
        {
            value: 'restream:admin',
            label: 'Restreaming (Verwalten)',
        },
        {
            value: 'playout:viewer',
            label: 'Playout (Lesen)',
        },
        {
            value: 'playout:editor',
            label: 'Playout (Bearbeiten)',
        },
        {
            value: 'playout:admin',
            label: 'Playout (Verwalten)',
        },
        {
            value: 'video:viewer',
            label: 'Video (Lesen)',
        },
        {
            value: 'video:editor',
            label: 'Video (Bearbeiten)',
        },
        {
            value: 'video:admin',
            label: 'Video (Verwalten)',
        },
        {
            value: 'player:viewer',
            label: 'Player/Direktzugriff (Lesen)',
        },
        {
            value: 'player:editor',
            label: 'Player/Direktzugriff (Bearbeiten)',
        },
        {
            value: 'player:admin',
            label: 'Player/Direktzugriff (Verwalten)',
        },
        {
            value: 'analytics:viewer',
            label: 'Analytics (Lesen)',
        }
    ];

    return (
        <>
            <FormTextField
                name="acl_id"
                label="Zugriffskontrollliste (ACL)"
                placeholder="Keine Einschränkung"
                object={userUpdate}
                handleChange={handleChange}
                hasPermission={hasPermission}
                select
                selectOptions={acls ? acls : []}
                clearable
            />
            <FormTextField
                name="permission_roles"
                label="Zugriffsrechte (CM)"
                object={userUpdate}
                handleChange={handleChange}
                hasPermission={hasPermission}
                select
                selectOptions={permissionRoles}
                multiSelect
            />
            {hasPermission && isUpdated && (
                <Stack
                    direction="row"
                    spacing={1}
                    sx={{
                        justifyContent: "flex-end",
                        alignItems: "center",
                        width: "100%",
                        paddingTop: 1,
                    }}
                >
                    <FormButtonGroup 
                        handleReset={handleReset}
                        handleUpdate={handleUpdate}
                    />
                </Stack>
            )}
        </>
    );
}
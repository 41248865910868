import React from "react";
import { 
    Accordion, 
    AccordionSummary, 
    AccordionDetails,
    Typography 
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";


export default function SidebarAccordion({
    title = "",
    content = null,
    expanded = null,
    first = false,
    last = false,
    disabled = false,
}) {
    return (
        <Accordion
            defaultExpanded={expanded}
            disabled={disabled}
            sx={{
                width: "100%",
                padding: "0px 5px",
                borderTop: first ? null : "2px solid rgba(0,0,0,.1)",
                borderBottom: last ? null : "1px solid rgba(0,0,0,.2)",
                "&.MuiAccordion-root::before": {
                    opacity: "0",
                },
                "&.Mui-expanded": {
                    margin: 0,
                },
                "&.MuiAccordionSummary-content": {
                    margin: 0,
                },
                "&.MuiAccordion-root.Mui-disabled": {
                    backgroundColor: "white",
                    "&:hover": {
                        backgroundColor: "rgba(0,0,0,.05)",
                    }
                
                }
            }}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{
                    "& .MuiAccordionSummary-content": {
                        margin: "-2px 0px 0px 0px",
                        "&.Mui-expanded": {
                            margin: "-2px 0px 0px 0px",
                        },
                    },
                    "&.Mui-expanded": {
                        margin: 0,
                    },
                }}
            >
                <Typography 
                    component="span" 
                    color="blue" 
                    fontSize={16}
                    fontWeight={500}
                >
                    {title}
                </Typography>
            </AccordionSummary>
            <AccordionDetails
                sx={{
                    padding: "0px 16px 16px",
                }}
            >
                {content}
            </AccordionDetails>
        </Accordion>
    );
}

import React from "react";
import { Button, IconButton, Stack } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from '@mui/icons-material/AddCircle';

import FormButtonGroup from "../../../components/FormButtonGroup";
import FormTextField from '../../../components/FormTextField';
import { useFormSection} from '../../../components/misc/useForm';


export default function CameraRecordingForm({
    camera = {},
    ingest = {},
    updateCamera = () => {},
    hasPermission = false,
}) {
    const cameraDefault = ingest?.worker_config?.recording || [];
    const { 
        isUpdated, 
        setIsUpdated, 
        formUpdate: cameraUpdate, 
        setFormUpdate: setCameraUpdate,
        handleReset,
    } = useFormSection({
        formDefault: cameraDefault,
    });

    const handleChange = (index, e) => {
        setCameraUpdate((prev) => {
            const updated = [...prev];
            updated[index] = { 
                ...updated[index], 
                [e.target.name]: e.target.value
            };
            return updated;
        });
        setIsUpdated(true);
    };

    // "worker_config": {
    //     "axis_vod": [
    //         "vv73_30_01"
    //     ],
    //     "recording": [
    //         {
    //             "id": "0",
    //             "duration_seconds": 30,
    //             "interval_seconds": 320
    //         }
    //     ]
    // },

    const setIds = (cameraUpdate) => {
        return cameraUpdate.map((item, i) => {
            return {
                ...item,
                id: i.toString(),
            };
        });
    }

    async function handleUpdate() {
        updateCamera({
            camera_id: camera.id, 
            camera: {ingest: {
                worker_config: {
                    axis_vod: [],
                    recording: setIds(cameraUpdate),
                }
            }}
        });
        setIsUpdated(false);
    }

    const handleCreate = () => {
        setCameraUpdate((prev) => {
            const updated = [...prev];
            updated.push({
                duration_seconds: "",
                interval_seconds: "",
            });
            return updated;
        });
    }

    const handleDelete = (index) => () => {
        setCameraUpdate((prev) => {
            const updated = [...prev];
            updated.splice(index, 1);
            return updated;
        });
        setIsUpdated(true);
    }

    return (
        <Stack spacing={2}>
            {cameraUpdate.map((item, i) => (
                <Stack
                    key={i}
                    direction="row"
                    spacing={2}
                    sx={{
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                        width: "100%",
                        paddingBottom: 1,
                        padding: "15px 20px 15px 20px",
                        backgroundColor: 'rgba(0,0,0,.045)',
                        borderRadius: 1,
                    }}
                >
                    <Stack
                        direction="row"
                        spacing={1}
                        sx={{
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                            width: "100%",
                        }}
                    >
                        <FormTextField
                            name="duration_seconds"
                            label="Aufnahmezeit (s)"
                            object={item}
                            handleChange={(e) => handleChange(i, e)}
                            hasPermission={hasPermission}
                            maxLength={3}
                            required
                            type="number"
                        />
                        <FormTextField
                            name="interval_seconds"
                            label="Intervall (s)"
                            object={item}
                            handleChange={(e) => handleChange(i, e)}
                            hasPermission={hasPermission}
                            maxLength={3}
                            required
                            type="number"
                        />
                    </Stack>
                    {hasPermission && (
                        <Stack
                            direction="row"
                            spacing={2}
                            sx={{
                                justifyContent: "flex-start",
                                alignItems: "flex-start",
                                paddingTop: .4,
                            }}
                        >
                            <IconButton aria-label="delete">
                                <DeleteIcon onClick={handleDelete(i)} />
                            </IconButton>
                        </Stack>
                    )}
                </Stack>
            ))}
            {hasPermission && (
                <Stack
                    direction="row"
                    spacing={1}
                    sx={{
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                    }}
                >
                    <Button
                        onClick={handleCreate}
                        size="small"
                        sx={{
                            background: '#fff',
                            border: '1px solid rgba(0,0,0,.2)',
                            color: '#0085E9',
                            "&:hover": {
                                color: '#fff',
                                background: '#0085E9',
                            }
                        }}
                    >
                        <AddCircleIcon sx={{marginRight: 1}} /> Neu
                    </Button>
                    {isUpdated && (
                        <FormButtonGroup 
                            handleReset={handleReset}
                            handleUpdate={handleUpdate}
                        />
                    )}
                </Stack>
            )}
        </Stack>
    );
}

import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Grid2 as Grid, Stack, Typography, TextField, Paper, InputAdornment, IconButton, Button } from '@mui/material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import FormButtonGroup from '../../components/FormButtonGroup';
import CameraSelect from '../../components/CameraSelect';
import CameraPreviewOverlay from '../../components/CameraPreviewOverlay';
import fallback from '../../images/fallback.png';

import PlayoutEvents from './PlayoutEvents';


export default function PlayoutDetails({
	playout = {},
	updatePlayout = () => { },
	cameras = [],
	playerConfig = {},
	hasPermission = false,
}) {
	const [playlist, setPlaylist] = useState(playout.playlist);
	const [camera, setCamera] = React.useState(null);
	const [dialogOpen, setDialogOpen] = React.useState(false);
	const [mousePosition, setMousePosition] = React.useState(null);
	React.useEffect(() => {
		if (camera) {
			setDialogOpen(prevState => true);
		}
	}, [camera]);

	const [isUpdated, setIsUpdated] = React.useState(false);

	function handleReset() {
		setPlaylist(playout.playlist);
		setIsUpdated(false);
	}

	async function handleUpdate() {
		for (const item of playlist) {
			if (!item.camera_id) {
				alert('Bitte wählen Sie eine Kamera aus.');
				return;
			}
		}
		updatePlayout({
			playout_id: playout.id,
			playout: { playlist: playlist }
		});
		setIsUpdated(false);
	}

	function handleOnDragEnd(result) {
		if (!result.destination) return;

		// Funktionales Update für setPlaylist
		setPlaylist((prevPlaylist) => {
			// Kopie der bisherigen Liste
			const newPlaylist = [...prevPlaylist];
			// Item an neuer Position einsetzen
			const [reorderedItem] = newPlaylist.splice(result.source.index, 1);
			newPlaylist.splice(result.destination.index, 0, reorderedItem);

			return newPlaylist;
		});
		setIsUpdated(true);
	}

	function handleSelectedCamera(index, camera_id) {
		if (!camera_id) return;

		setPlaylist((prevPlaylist) => {
			// Kopie der bisherigen Liste
			const newPlaylist = [...prevPlaylist];
			// Nur das geänderte Element aktualisieren
			newPlaylist[index] = {
				...newPlaylist[index],
				camera_id: camera_id
			};

			return newPlaylist;
		});
		setIsUpdated(true);
	}

	function CameraNameLabel({
		camera_id = null,
		playerConfig = {},
	}) {
		const [backgroundImage, setBackgroundImage] = React.useState(`url(${fallback})`);

		// Bild nur laden, wenn sich die URL wirklich geändert hat:
		React.useEffect(() => {
			// Falls kein camera_id vorhanden ist, nichts laden
			if (!camera_id) return;

			// Neue URL vorbereiten
			const newImageURL = `url(${playerConfig.url}${camera_id}/live/main_240.jpg)`;

			// Nur laden, wenn die URL anders ist als der bisherige Zustand
			if (backgroundImage !== newImageURL) {
				const img = new Image();
				// newImageURL enthält "url(...)", extrahieren Sie nur den Teil ohne "url(" und ")"
				const rawSrc = newImageURL.slice(4, -1);

				img.src = rawSrc;
				img.onload = () => {
					setBackgroundImage(newImageURL);
				};
			}
		}, [camera_id, playerConfig.url, backgroundImage]);

		return (
			<Paper
				elevation={0}
				sx={{
					backgroundImage: backgroundImage,
					backgroundSize: "cover",
					padding: 0,
					height: 42,
					minWidth: 73,
					borderRadius: 1,
					border: "1px solid #f1f1f1",
					cursor: "pointer",
				}}
				onClick={(ev) => {
					if (!camera_id) return;
					const camera = cameras.find((camera) => camera.id === camera_id);
					setMousePosition({ x: ev.clientX, y: ev.clientY });
					setCamera(camera);
					setDialogOpen(true);
				}}
			/>
		);
	}

	const getPlaylistTime = (playlist) => {
		let totalSeconds = 0;
		playlist.forEach((item) => {
			totalSeconds += parseInt(item.playback_sec, 10) || 0;
		});

		const hours = Math.floor(totalSeconds / 3600);
		const minutes = Math.floor((totalSeconds % 3600) / 60);
		const seconds = totalSeconds % 60;

		return [
			hours.toString().padStart(2, "0"),
			minutes.toString().padStart(2, "0"),
			seconds.toString().padStart(2, "0"),
		].join(":");
	};

	const calcPositionWidth = (playlist.length && playlist.length > 99) ? 50 : (playlist.length && playlist.length > 9) ? 35 : 25;
		

	return (
		<Stack direction="column" spacing={2}>
			<Grid container direction="row" spacing={{ xs: 1, md: 2 }}>
				<PlayoutEvents events={playout.events} />
			</Grid>
			<Stack direction="row" spacing={2} alignItems="center" justifyContent={"space-between"}>
				<Stack direction="row" spacing={2} alignItems="center" justifyContent={"flex-start"}>
					<Typography variant="h6" sx={{ marginRight: 2, }}>
						Kameras: {playlist.length}
					</Typography>
					<Typography variant="h6" sx={{ marginRight: 2, }}>
						Laufzeit: {getPlaylistTime(playlist)}
					</Typography>
				</Stack>
				{hasPermission && (
					<Stack direction="row" spacing={2} alignItems="flex-end" justifyContent={"flex-end"}>
						{isUpdated && (
							<FormButtonGroup
								handleReset={handleReset}
								handleUpdate={handleUpdate}
							/>
						)}
						<Button
							color="primary"
							size="small"
							sx={{
								height: 40,
								boxShadow: 1,
								borderRadius: 1,
								background: '#fff',
								border: '1px solid rgba(0,0,0,.2)',
								fontSize: '1em',
								padding: "0px 10px",
								color: '#0085E9',
								"&:hover": {
									color: '#fff',
									background: '#0085E9',
								}
							}}
							onClick={() => {
								const items = Array.from(playlist);
								items.push({ type: 'live', camera_id: '', playback_sec: 25 });
								setPlaylist(items);
								setIsUpdated(true);
							}}
						>
							<AddCircleIcon style={{ marginRight: 5 }} /> Kamera
						</Button>
					</Stack>
				)}
			</Stack>
			<DragDropContext onDragEnd={handleOnDragEnd}>
				<Droppable droppableId="playlist" direction="vertical">
					{(provided) => (
						<div ref={provided.innerRef} {...provided.droppableProps}>
							<Stack direction="column" spacing={2} marginTop={1.5}>
								{playlist.map((item, index) => (
									<Draggable
										key={item.camera_id + '-' + index}
										draggableId={item.camera_id + '-' + index}
										index={index}
									>
										{(provided, snapshot) => (
											<Paper
												elevation={snapshot.isDragging ? 4 : 1}
												ref={provided.innerRef}
												{...provided.draggableProps}
												style={{
													...provided.draggableProps.style,
													padding: '10px 20px',
													margin: '0 0 6px 0',
													//border: '1px solid rgba(0,0,0,.2)',
													borderRadius: 4,
													display: 'flex',
													alignItems: 'center',
													"hover": { backgroundColor: 'blue' },
												}}
											>
												<Stack direction="row" spacing={1} alignItems="center" width={"100%"}>
													<Typography variant="body1" sx={{ width: calcPositionWidth, textAlign: 'left' }}>
														{index + 1}
													</Typography>
													<span
														{...provided.dragHandleProps}
														style={{ display: 'inline-flex', cursor: 'grab', marginLeft: 12, marginRight: 12 }}
													>
														<DragIndicatorIcon />
													</span>
													{/* <Typography variant="h6" sx={{ marginRight: 2, }}>
														{item.type}
													</Typography> */}
													<CameraNameLabel 
														camera_id={item.camera_id} 
														playerConfig={playerConfig}
														setMousePosition={setMousePosition}
													/>
													<CameraSelect
														key={item}
														cameras={cameras}
														index={index}
														camera_id={item.camera_id}
														handleSelectedCamera={handleSelectedCamera}
													/>
													<TextField
														size="small"
														variant="outlined"
														value={item.playback_sec}
														sx={{
															width: 150,
															border: "1px solid #f1f1f1",
															borderRadius: 1,
														}}
														onChange={(e) => {
															const items = Array.from(playlist);
															items[index].playback_sec = e.target.value;
															setPlaylist(items);
															setIsUpdated(true);
														}}
														slotProps={{
															input: {
																endAdornment: <InputAdornment position="end">Sek.</InputAdornment>,
															},
														}}
													/>
													<IconButton
														aria-label="delete"
														style={{ marginLeft: 14, marginRight: 0 }}
														onClick={() => {
															const items = Array.from(playlist);
															items.splice(index, 1);
															setPlaylist(items);
															setIsUpdated(true);
														}}
													>
														<DeleteIcon />
													</IconButton>
												</Stack>
											</Paper>
										)}
									</Draggable>
								))}
							</Stack>
						</div>
					)}
				</Droppable>
			</DragDropContext>
			{dialogOpen && camera && (
				<CameraPreviewOverlay
					key={camera.id}
					camera={camera}
					dialogOpen={dialogOpen}
					setDialogOpen={setDialogOpen}
					mousePosition={mousePosition}
					playerConfig={playerConfig}
				/>
			)}
		</Stack>
	);
}

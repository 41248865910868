/* eslint-disable import/no-anonymous-default-export */
export default {
    styleOverrides: {
        li: {
            fontSize: '.85rem',
            background: '#0085E9',
            borderRadius: '20px',
            padding: '0px 8px',
            marginTop: '4px',
        }
    },
};

import React from 'react';
import { Stack } from '@mui/material';

import FormTextField from '../../../components/FormTextField';
import FormButtonGroup from '../../../components/FormButtonGroup';


export default function CameraIdForm({ 
    camera = {},
    updateCamera = () => {},
    hasPermission = false, 
}) {
    const cameraUpdateDefault = {
        alias_id: camera.alias_id,
    };
    const [cameraUpdate, setCameraUpdate] = React.useState(cameraUpdateDefault);
    const [isUpdated, setIsUpdated] = React.useState(false);

    function handleChange(e) {
        setCameraUpdate({
            ...cameraUpdate,
            [e.target.name]: e.target.value
        });
        setIsUpdated(true);
    }

    function handleReset() {
        setCameraUpdate((prev) => {
            return {
                ...prev,
                ...cameraUpdateDefault,
            };
        });
        setIsUpdated(false);
    }

    async function handleUpdate() {    
        updateCamera({
            camera_id: camera.id, 
            camera: cameraUpdate
        });
        setIsUpdated(false);
    }

    return (
        <>
            <FormTextField
                name="id"
                label="ID"
                object={{id: camera.id}}
            />
            <FormTextField
                name="alias_id"
                label="Alias ID"
                object={cameraUpdate}
                handleChange={handleChange}
                hasPermission={hasPermission}
                maxLength={100}
            />
            {hasPermission && isUpdated && (
                <Stack
                    direction="row"
                    spacing={1}
                    sx={{
                        justifyContent: "flex-end",
                        alignItems: "center",
                        width: "100%",
                        paddingTop: 1,
                    }}
                >
                    <FormButtonGroup 
                        handleReset={handleReset}
                        handleUpdate={handleUpdate}
                    />
                </Stack>
            )}
        </>
    );
}
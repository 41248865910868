import * as React from "react";
import PropTypes from "prop-types";

import "moment-timezone";
import moment from "moment";

import Divider from "@mui/material/Divider";
import Moment from "react-moment";
import Stack from "@mui/material/Stack";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";

import { DefaultCopyField } from "../../../../components/CopyButton";
import PageSidebarHeader from "../../../../components/PageSidebarHeader";
import SidebarTabPanel from "../../../../components/SidebarTabPanel";
import SidebarTabPanelProps from "../../../../components/SidebarTabPanelProps";
import VideoJS from "../../../../components/VideoJS";

import PlaybackPlayerSidebarCameraSelect from "./PlaybackPlayerSidebarCameraSelect";
import PlaybackPlayerSidebarSettings from "./PlaybackPlayerSidebarSettings";

import { useApp } from "../../../../context/App";

function PlayerConfig({ 
    player, 
    playerConfig, 
    selectedCamera,
}) {
    const playerColor = ({ color }) => {
        if (!color) {
            return { seekbar: "#fff", buttons: "#fff" };
        }
        return color;
    };

    const playerGa = ({ analytics }) => {
        if (analytics && analytics.ga_account && analytics.ga_name) {
            return { account: analytics.ga_account, name: analytics.ga_name };
        }
        return null;
    };

    const playerPoster = ({ poster_url, camera_poster }) => {
        if (poster_url && poster_url.length > 0) {
            return playerConfig.url + poster_url;
        } else if (camera_poster) {
            return selectedCamera.poster;
        } else {
            return selectedCamera.poster
                .replace("main.jpg", "main_240.jpg")
                .split("?token=")[0];
        }
    };

    const playerSources = () => {
        return [
            {
                src:
                    selectedCamera.source.split("?token=")[0] +
                    "?token=" +
                    playerConfig.token,
                type: "application/vnd.apple.mpegurl",
            },
        ];
    };

    const videoJsOptions = {
        autoplay: player.autoplay,
        muted: player.mute,
        color: playerColor(player),
        controls: true,
        responsive: true,
        fluid: true,
        logo: player.logo,
        ga: playerGa(player),
        aspectRatio: "16:9",
        poster: playerPoster(player),
        sources: playerSources(),
        playsinline: true,
    };

    return <VideoJS {...videoJsOptions} />;
}

PlayerConfig.propTypes = {
    player: PropTypes.object.isRequired,
    playerConfig: PropTypes.object.isRequired,
    selectedCamera: PropTypes.object.isRequired,
};

PlayerConfig.defaultProps = {};

function PlaybackPlayerSidebar({ 
    drawerWidth, 
    player, 
    playerConfig,
    handleSidebarToggle,
}) {
    const { client } = useApp();
    const customerId = client.CustomerId();
    
    const [tab, setTab] = React.useState(0);
    const handleChangeTab = (event, newValue) => {
        setTab(newValue);
    };

    const [playlist, setPlaylist] = React.useState(null);

    const [selectedCamera, setSelectedCamera] = React.useState(null);

    const getPlaylist = async () => {
        const data = await client.GetCustomerPlayerIdPlaylist({
            player_id: player.id,
        });
        setPlaylist(data);
        setSelectedCamera(data[0]);
    };

    React.useEffect(() => {
        getPlaylist();
        // eslint-disable-next-line
    }, []);

    if (!player || !playerConfig) {
        return null;
    }

    return (
        <Stack width={drawerWidth}>
            <Stack>
                <PageSidebarHeader
                    title={player.name}
                    share={true}
                    handleToggle={handleSidebarToggle}
                    player={
                        player && playerConfig && selectedCamera && (
                            <PlayerConfig
                                player={player}
                                playerConfig={playerConfig}
                                selectedCamera={selectedCamera}
                            />
                        )
                    }
                />
            </Stack>
            <Divider sx={{width: "100%", marginTop: "20px", marginBottom: "-2px", border: "2px solid rgba(0,0,0,.1)", borderTop: "1px solid rgba(0,0,0,.2)"}} />
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
                marginTop={0}
                borderBottom={"1px solid rgba(0, 0, 0, 0.12)"}
                maxWidth={drawerWidth}
            >
                <Tabs value={tab} onChange={handleChangeTab}>
                    <Tab label="Einbetten" {...SidebarTabPanelProps(0)} />
                    <Tab label="Einstellung" {...SidebarTabPanelProps(1)} />
                </Tabs>
            </Stack>
            <SidebarTabPanel value={tab} index={0} padding={0}>
                <Stack
                    padding="5px 20px 0px 20px"
                    spacing={0}
                >
                    {playlist && (
                        <PlaybackPlayerSidebarCameraSelect
                            playlist={playlist.sort((a, b) =>
                                (a.meta.name || "").localeCompare(
                                    b.meta.name || "",
                                ),
                            )}
                            selectedCamera={selectedCamera}
                            setSelectedCamera={setSelectedCamera}
                        />
                    )}
                    <Typography marginTop={2.5} marginBottom={0} variant="h4">
                        Webseite
                    </Typography>
                    {selectedCamera && (
                        <React.Fragment>
                            <Typography marginTop={1.5} marginBottom={1.5} variant="h6">
                                Eingebettet (Nativ)
                            </Typography>
                            <Stack
                                style={{
                                    padding: "15px 15px 15px 15px",
                                    marginBottom: 5,
                                    backgroundColor: "rgba(0,0,0,.035)",
                                    borderRadius: 4,
                                }}
                                spacing={2}
                            >
                                <DefaultCopyField
                                    value={`<link href="https://s3.livespotting.com/player/static/player.min.css" rel="stylesheet"/>
    <script src="https://s3.livespotting.com/player/static/player.min.js"></script>
    <video-js
        id="cmPlayer1"
        data-player="default"
        data-embed="default"
        controls
    ></video-js>
    <script>
        const player = videojs('cmPlayer1');
        player.ready(function () {
            this.cmPlayerInit({
                customerId: '${customerId}',
                playerId: '${player.id}',
                cameraId: '${selectedCamera.camera_id}',
            });
        });
    </script>`}
                                    multiline
                                    maxRows={8}
                                    noWrap
                                />
                            </Stack>
                            <Typography marginTop={1.5} marginBottom={1.5} variant="h6">
                                iFrame (Isoliert)
                            </Typography>
                            <Stack
                                style={{
                                    padding: "15px 15px 15px 15px",
                                    marginBottom: 5,
                                    backgroundColor: "rgba(0,0,0,.035)",
                                    borderRadius: 4,
                                }}
                                spacing={2}
                            >
                                <DefaultCopyField
                                    value={`<iframe src="${
                                        "https://cm.livespotting.com/v1/player/" +
                                        customerId +
                                        "/" +
                                        player.id +
                                        "/" +
                                        selectedCamera.camera_id
                                    }" width="640" height="360" frameborder="no" scrolling="no" allowfullscreen="true"></iframe>`}
                                    multiline
                                    maxRows={3}
                                />
                            </Stack>
                            <Typography marginTop={1.5} marginBottom={1.5} variant="h6">
                                Vorschaubild
                            </Typography>
                            <Stack
                                style={{
                                    padding: "20px 15px 15px 15px",
                                    marginBottom: 25,
                                    backgroundColor: "rgba(0,0,0,.035)",
                                    borderRadius: 4,
                                }}
                                spacing={2}
                            >
                                <DefaultCopyField
                                    label=""
                                    value={
                                        selectedCamera.poster.split("?token=")[0]
                                    }
                                    multiline
                                    maxRows={3}
                                />
                            </Stack>
                        </React.Fragment>
                    )}
                </Stack>
            </SidebarTabPanel>
            <SidebarTabPanel value={tab} index={1} padding={0}>
                <Stack
                    padding="5px 20px 0px 20px"
                    spacing={0}
                >
                    <Typography fontSize={14}>
                        <strong>ID:</strong> {player.id}
                    </Typography>
                    <Typography fontSize={14}>
                        <strong>Erstellt:</strong>{" "}
                        <Moment format="DD.MM.YYYY HH:mm:ss" locale="de">
                            {moment(player.created_at).tz(
                                moment.tz.guess(),
                            )}
                        </Moment>
                    </Typography>
                    {player.updated_at && (
                        <Typography fontSize={14}>
                            <strong>Aktualisiert:</strong>{" "}
                            <Moment
                                format="DD.MM.YYYY HH:mm:ss"
                                locale="de"
                            >
                                {moment(player.updated_at).tz(
                                    moment.tz.guess(),
                                )}
                            </Moment>
                        </Typography>
                    )}
                </Stack>
                <PlaybackPlayerSidebarSettings player={player} />
            </SidebarTabPanel>
        </Stack>
    );
}

PlaybackPlayerSidebar.propTypes = {
    drawerWidth: PropTypes.number,
    player: PropTypes.object.isRequired,
    playerConfig: PropTypes.object,
};

PlaybackPlayerSidebar.defaultProps = {
    drawerWidth: 540,
};

export default PlaybackPlayerSidebar;

import { Grid2 as Grid, Link, Stack, Typography } from "@mui/material";


export default function PlayoutEvents({
    events = [],
}) {
    function parseTime(str) {
        const [hh, mm] = str.split(":").map(Number);
        return hh * 60 + mm;
    }

    return (
        events && events.length > 0 && (
            events
            .sort((a, b) => parseTime(a.start_time) - parseTime(b.start_time))
            .map((event) => (
                <Grid
                    direction="row"
                    spacing={2}
                    size={{ xs: 12, sm: 12, md: 6, lg: 4, xl: 3 }}
                    sx={{
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                        backgroundColor: "rgba(0,0,0,.045)",
                        padding: "6px 20px 10px 20px",
                        borderRadius: 1,
                        border: "1px solid rgba(0,0,0,.06)",
                    }}
                >
                    <Stack
                        direction="column"
                        spacing={0}
                        sx={{
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                            width: "100%",
                        }}
                    >
                        <Typography
                            component="span"
                            fontSize={16}
                            fontWeight={500}
                            sx={{
                                width: "100%",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                            }}
                        >
                            {event.start_time} - {event.end_time} Uhr
                        </Typography>
                        <Typography
                            component="span"
                            fontSize={13}
                            fontWeight={500}
                            sx={{
                                width: "100%",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                            }}
                        >
                            {event.name}
                        </Typography>
                        <Typography
                            component="span"
                            fontSize={13}
                            fontWeight={400}
                            sx={{
                                width: "100%",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                            }}
                        >
                            <Link
                                underline="hover"
                                color="black"
                                sx={{
                                    width: "100%",
                                    overflow: "hidden",
                                }}
                                href={event.website}
                                target="_blank"
                                rel="noopener noreferrer"
                                >
                                    {event.website}
                            </Link>
                        </Typography>
                    </Stack>
                </Grid>
            ))
        )
    );
}
import { useState } from "react";

export function removeEmptyStrings(data) {
  return Object.fromEntries(
    Object.entries(data).map(([key, value]) => [key, value === "" ? null : value])
  );
}

export const validHttpUrl = (value) => {
  if (value === "") return true;
  const regex = /^https?:\/\/\S+$/;
  return regex.test(value);
};

export const validTimeFormat = (value) => {
  const regex = /^(0\d|1\d|2[0-3]):[0-5]\d$/;
  return regex.test(value);
};

export function useFormSection({
  formDefault = {}, 
}) {
  const [isUpdated, setIsUpdated] = useState(false);
  const [formUpdate, setFormUpdate] = useState(formDefault);

  function handleChange(e) {
    const { type, name, value } = e.target;
    setFormUpdate({
        ...formUpdate,
        [name]: type === "checkbox" ? !formUpdate[name] : value
    });
    setIsUpdated(true);
  }

  function handleReset() {
    setFormUpdate((prev) => {
        return formDefault;
    });
    setIsUpdated(false);
  }

  return { 
    isUpdated, 
    setIsUpdated,
    formUpdate, 
    setFormUpdate,
    handleChange, 
    handleReset
  };
}
import React from 'react';
import { Stack } from '@mui/material';

import FormButtonGroup from '../../../components/FormButtonGroup';
import FormTextField from '../../../components/FormTextField';
import { removeEmptyStrings, useFormSection,  } from '../../../components/misc/useForm';


export default function PlayoutMetadataForm({ 
    playout = {},
    updatePlayout = () => {},
    hasPermission = false, 
}) {
    const playoutDefault = {
        name: playout.name || "",
        description: playout.description || "",
    };
    const { 
        isUpdated, 
        setIsUpdated, 
        formUpdate: playoutUpdate, 
        handleChange,
        handleReset,
    } = useFormSection({
        formDefault: playoutDefault,
    });

    async function handleUpdate() {    
        updatePlayout({
            playout_id: playout.id, 
            playout: removeEmptyStrings(playoutUpdate),
        });
        setIsUpdated(false);
    }

    return (
        <>
            <FormTextField
                name="name"
                label="Name"
                object={playoutUpdate}
                handleChange={handleChange}
                hasPermission={hasPermission}
                maxLength={100}
            />
            <FormTextField
                name="description"
                label="Beschreibung"
                object={playoutUpdate}
                handleChange={handleChange}
                hasPermission={hasPermission}
                maxLength={100}
                multiline
                maxRows={5}
            />
            {hasPermission && isUpdated && (
                <Stack
                    direction="row"
                    spacing={1}
                    sx={{
                        justifyContent: "flex-end",
                        alignItems: "center",
                        width: "100%",
                        paddingTop: 1,
                    }}
                >
                    <FormButtonGroup 
                        handleReset={handleReset}
                        handleUpdate={handleUpdate}
                    />
                </Stack>
            )}
        </>
    );
}
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import BugReportIcon from '@mui/icons-material/BugReport';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { useApp } from "../context/App";


export default function AlertEditDialog({ alert }) {
    const { client, reloadPage } = useApp();
    const [open, setOpen] = React.useState(false);
    const [formValuesChanged, setFormValuesChanged] = React.useState(false);


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const isItemTypes = (item_type) => {
        if (item_type === "camera") {
            return "camera_id";
        } else if (item_type === "restream") {
            return "restream_id";
        }
    }

    const [formValues, setFormValues] = React.useState({
        [isItemTypes(alert.item_type)]: alert.item_id,
        status: alert.status,
        status_message: alert.status_message,
    });

    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;
        const newValue = type === "checkbox" ? checked : value;
        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: newValue,
        }));
        setFormValuesChanged(true);
    };

    const updateAlert = async () => {
        if (alert.item_type === "camera") {
            await client.PutCustomerAlertCamera({ 
                camera_id: formValues.camera_id, alert: formValues 
            });
        } else if (alert.item_type === "restream") {
            await client.PutCustomerAlertRestream({
                restream_id: formValues.restream_id, alert: formValues 
            });
        }
        reloadPage();
        handleClose();
    };

    return (
        <React.Fragment>
            <Button 
                variant="outlined" 
                size="small" 
                sx={{
                    color: "#fff", 
                    backgroundColor: "#0085E9", 
                    padding: 2, 
                    marginLeft: .5, 
                    minWidth: 30,
                    borderRadius: 1
                }} 
                onClick={handleClickOpen}
            >
                <BugReportIcon size="small" sx={{marginRight: 1}}/> Störungsmeldung
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-edit-dialog-title"
                aria-describedby="alert-edit-dialog-description"
                PaperProps={{ style: { padding: "10px 15px 10px 15px" } }}
            >
                <DialogTitle id="alert-dialog-title" width={500}>
                    Störungsmeldung
                </DialogTitle>
                <DialogContent>
                    <FormControl fullWidth>
                        <InputLabel sx={{marginLeft: "-13px", marginTop: 1}}id="alert-edit-dialog-status-select-label">Status</InputLabel>
                        <Select
                            labelId="alert-edit-dialog-status-select-label"
                            id="alert-edit-dialog-status-select"
                            name="status"
                            defaultValue="open"
                            value={formValues.status || "open"}
                            label="Status"
                            onChange={handleChange}
                            size="small"
                            fullWidth
                            variant="standard"
                         >
                            <MenuItem value={"open"}>Offen</MenuItem>
                            <MenuItem value={"in_progress"}>In Arbeit</MenuItem>
                            <MenuItem value={"waiting"}>Wartend</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField
                        id="alert-edit-dialog-stauts-message-textfield"
                        sx={{marginTop: 2}}
                        label="Status Hinweis"
                        multiline
                        rows={4}
                        name="status_message"
                        value={formValues.status_message || ""}
                        onChange={handleChange}
                        variant="standard"
                        size="small"
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" color="primary" onClick={handleClose}>Abbrechen</Button>
                    <Button variant="outlined" color="primary" onClick={updateAlert} disabled={!formValuesChanged} autoFocus>
                        Speichern
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
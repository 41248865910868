import * as React from "react";
import { useParams, useNavigate } from 'react-router-dom';
import PropTypes from "prop-types";

import Page from "../../components/Page";
import SearchField from "../../components/SearchField";

import AdminUser from "./AdminUser";
import AdminUserSidebar from "./AdminUser/AdminUserSidebar";

import { useApp } from "../../context/App";


function Admin(props) {
    const { rootPath } = props;
    const { client, hasPermission, backdrop, setSelectedPage, reload } = useApp();
    const [ready, setReady] = React.useState(false);

    // PERMISSION CHECK
    const adminRoles = ["admin"];

    // PAGE SIDEBAR
    const [openSidebar, setOpenSidebar] = React.useState(false);
    const handleSidebarToggle = () => {
        setOpenSidebar(!openSidebar);
    };

    const navigate = useNavigate();
    const { user_id } = useParams();
    const [users, setUsers] = React.useState(null);
    const [user, setUser] = React.useState(null);

    React.useEffect(() => {
        (async () => {
            setSelectedPage("admin_user");
            await client.GetCustomerUser().then((data) => setUsers(data));
            setReady(true);
            return () => {
                setReady(false);
            };
        })();
    }, [setSelectedPage, client, reload]);

    React.useEffect(() => {
        backdrop(!ready);
    }, [ready, backdrop]);

    React.useEffect(() => {
        if (users && user_id) {
            const data = users.find((user) => user.username === user_id);
            if (!data) {
                navigate(rootPath);
            } else {
                setUser(data);
                setOpenSidebar(true);
            }
        } else {
            setUser(null);
            setOpenSidebar(false);
        }
    }, [users, user_id]);

    const updateUser = async ({
		username = null,
		user = null,
	}) => {
		if (!user_id) return;
		let data = null;
		if (user) {
			data = await client.PutCustomerUserId({
				username: username, user: user
			});
		}
		if (data) {
			setUser(data);
			setUsers(users.map((item) => (item.username === username ? data : item)));
		}
	};

    const drawerWidth = 480;
    const [searchText, setSearchText] = React.useState("");

    const breadcrumbs = () => {
		if (user) {
            return [
                {title: 'Admin', link: '#'},
                {title: 'Zugänge', link: rootPath},
                {title: user.username, link: `${rootPath}/${user.username}`},
            ]
        } else {
            return [
                {title: 'Admin', link: '#'},
                {title: 'Zugänge', link: rootPath}
            ]
        }
	}

    return (
        <Page
            pageTitel="Zugänge"
            drawerWidth={drawerWidth}
			breadcrumbs={breadcrumbs ? breadcrumbs() : []}
            headerContentRight={
                <SearchField 
                    outlined 
                    searchText={searchText} 
                    setSearchText={setSearchText} 
                />
            }
            openSidebar={openSidebar}
            handleSidebarToggle={handleSidebarToggle}
            sidebarContent={
                user && (
                    <AdminUserSidebar
                        key={user.username}
                        drawerWidth={drawerWidth}
                        user={user}
                        adminRoles={adminRoles}
                        handleSidebarToggle={handleSidebarToggle}
                        updateUser={updateUser}
                        hasPermission={hasPermission(adminRoles)}
                    />
                )
            }
        >
            {users && (
                <AdminUser
                    rootPath={rootPath}
                    users={users}
                    user_id={user_id}
                    searchText={searchText}
                    navigate={navigate}
                />
            )}
        </Page>
    );
}

Admin.propTypes = {
    openMenu: PropTypes.bool,
    handleMenuToggle: PropTypes.func,
    setSelectedPage: PropTypes.func,
};

export default Admin;

import * as React from "react";

import PropTypes from "prop-types";

import { useApp } from "../../context/App";

import UserForm from "./UserForm";


export default function UserDialog({
    user = {},
    open = false,
    handleClose = () => { },
}) {
    const { client } = useApp();

    // USER DATA
    const [userData, setUserData] = React.useState(null);

    const getUser = async () => {
        const data = await client.GetUser({
            email: user.email
        });
        if (data) {
            setUserData((prev) => { return data });
        }
    };

    const updateUser = async ({
        user = null,
        user_avatar_image = null,
        //password_reset = false,
    }) => {
        if (!user && !user_avatar_image) return;
        let data = null;
        if (user) {
            data = await client.PutUser({
                data: user,
            });
        }
        if (user_avatar_image) {
            data = await client.PutUserAvatar({
                image: user_avatar_image,
            });
        }
        // todo: komponente fehlt im form
        // if (password_reset) {
        //     data = await client.PutUserPasswordChange({
        //         email: user.email,
        //         password: user.password,
        //     });
        // }
        if (data) {
            client.UserConfigUpdate({
                first_name: user.first_name, last_name: user.last_name, avatar_url: user.avatar_url,
            });
            setUserData((prev) => { return data });
        }
    };

    React.useEffect(() => {
        getUser();
    }, []);

    return (
        userData && (
            <UserForm
                user={userData}
                updateUser={updateUser}
                open={open}
                handleClose={handleClose}
            />
        )
    );
}

UserDialog.propTypes = {
    user: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
};

/* eslint-disable import/no-anonymous-default-export */
import base from "../base";

const root = {
    textAlign: "center",
    textTransform: "uppercase",
    userSelect: "none",
};

const contained = {
    base: {
        color: "#28AC2E",
        backgroundColor: "white",
        "&:hover": {
            color: base.palette.primary.contrastText,
            backgroundColor: "white",
        },
        "&:disabled": {
            color: base.palette.text.disabled,
        },
    },
    primary: {
        color: "#fff",
        backgroundColor: "#28AC2E",
        "&:hover": {
            color: "#fff",
            backgroundColor: "#249324",
        },
        "&:disabled": {
            backgroundColor: base.palette.background.default,
            color: base.palette.text.disabled,
        },
    },
    secondary: {
        color: "#fff",
        backgroundColor: "#0085E9",
        "&:hover": {
            color: "#fff",
            backgroundColor: "#0085E9",
        },
        "&:disabled": {
            backgroundColor: base.palette.background.default,
            color: base.palette.text.disabled,
        },
    },
};

const outlined = {
    base: {
        color: "#28AC2E",
        backgroundColor: "white",
        "&:hover": {
            color: base.palette.primary.contrastText,
            backgroundColor: "white",
        },
        "&:disabled": {
            color: base.palette.text.disabled,
        },
    },
    primary: {
        color: "#fff",
        backgroundColor: "#28AC2E",
        "&:hover": {
            color: "#fff",
            backgroundColor: "#249324",
        },
        "&:disabled": {
            backgroundColor: base.palette.background.default,
            color: base.palette.text.disabled,
        },
        boxShadow: 1,
        borderRadius: 1,
        border: '1px solid rgba(0,0,0,.2)',
    },
    secondary: {
        color: "#0085E9",
        backgroundColor: "unset",
        "&:hover": {
            color: "#0085E9",
            backgroundColor: "rgba(0,0,0,.05)",
        },
        "&:disabled": {
            backgroundColor: base.palette.background.default,
            color: base.palette.text.disabled,
        },
    },
};

const size = {
    small: {
        padding: "0px 10px",
        fontSize: '.8em',
        height: "30px",
    },
};

export default {
    styleOverrides: {
        root: { ...root },
        contained: { ...contained.base },
        containedPrimary: { ...contained.primary },
        containedSecondary: { ...contained.secondary },
        outlined: { ...outlined.base },
        outlinedPrimary: { ...outlined.primary },
        outlinedSecondary: { ...outlined.secondary },
        sizeSmall: { ...size.small },
    },
};
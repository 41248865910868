import * as React from "react";

import "moment-timezone";
import moment from "moment";

import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Moment from "react-moment";
import PropTypes from "prop-types";
import Stack from "@mui/material/Stack";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import VideocamIcon from "@mui/icons-material/Videocam";

import PageSidebarHeader from "../../../../components/PageSidebarHeader";
import SidebarTabPanel from "../../../../components/SidebarTabPanel";
import SidebarTabPanelProps from "../../../../components/SidebarTabPanelProps";

import AdminACLSidebarMenu from "./AdminACLSidebarMenu";
import AdminACLLinkSelect from "./AdminACLLinkSelect";

import { useApp } from "../../../../context/App";


function Camera({ name }) {
    return (
        <Chip
            Typographyvariant="outlined"
            icon={<VideocamIcon color="#fff" />}
            style={{ textTransform: "initial", fontWeight: 500, margin: 3, maxWidth: 480 }}
            label={`${name}`}
        />
    );
}
Camera.propTypes = {
    name: PropTypes.string,
};
Camera.defaultProps = {};


function AdminACLSidebar({ 
    drawerWidth, 
    acl,
    handleSidebarToggle,
}) {
    const { client } = useApp();
    // SIDEBAR TABPANEL
    const [tab, setTab] = React.useState(0);
    const handleTabChange = (event, newValue) => {
        setTab(newValue);
    };

    const [aclCameras, setACLCameras] = React.useState(null);

    React.useEffect(() => {
        (async () => {
            if (!aclCameras) {
                client
                    .GetCustomerACLId({
                        acl_id: acl.id,
                        hide: "none",
                    })
                    .then((data) => setACLCameras(data.cameras));
            }
        })();
        // eslint-disable-next-line
    }, []);

    return (
        <Stack width={drawerWidth}>
            <PageSidebarHeader
                title={acl.name}
                share={true}
                handleToggle={handleSidebarToggle}
                submenu={
                    <AdminACLSidebarMenu />
                }
            />
            <Divider />
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
                marginTop={0}
                borderBottom={"1px solid rgba(0, 0, 0, 0.12)"}
                maxWidth={drawerWidth}
            >
                <Tabs
                    value={tab}
                    onChange={handleTabChange}
                    variant="scrollable"
                    scrollButtons={false}
                >
                    <Tab label="Einstellung" {...SidebarTabPanelProps(0)} />
                    <Tab label="Verknüpfungen" {...SidebarTabPanelProps(1)} />
                    {/* <Tab label="Protokoll" {...SidebarTabPanelProps(2)} /> */}
                </Tabs>
                
            </Stack>
            <SidebarTabPanel value={tab} index={0} padding={0}>
                <Stack
                    padding="5px 20px 0px 20px"
                    spacing={0}
                >
                    <Stack
                        style={{
                            padding: "10px 15px 10px 15px",
                            marginBottom: 5,
                            backgroundColor: "rgba(0,0,0,.035)",
                            borderRadius: 4,
                        }}
                        spacing={0.5}
                    >
                        <Typography fontSize={14}>
                            <strong>ID:</strong> {acl.id}
                        </Typography>
                        <Typography fontSize={14}>
                            <strong>Erstellt:</strong>{" "}
                            <Moment format="DD.MM.YYYY HH:mm:ss" locale="de">
                                {moment(acl.created_at).tz(moment.tz.guess())}
                            </Moment>
                        </Typography>
                        {acl.updated_at && (
                            <Typography fontSize={14}>
                                <strong>Aktualisiert:</strong>{" "}
                                <Moment
                                    format="DD.MM.YYYY HH:mm:ss"
                                    locale="de"
                                >
                                    {moment(acl.updated_at).tz(
                                        moment.tz.guess(),
                                    )}
                                </Moment>
                            </Typography>
                        )}
                    </Stack>
                    <Grid
                        container
                        justifyContent="space-between"
                        fontSize={14}
                        style={{
                            marginTop: 6,
                        }}
                        spacing={2}

                    >
                        <Grid item xs={12}>
                            <TextField
                                label="Beschreibung"
                                value={acl.description ? acl.description : ""}
                                size="small"
                                multiline
                                readOnly
                                variant="standard"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} marginBottom={-1}>
                            <Typography variant="h6">Kameras:</Typography>
                        </Grid>
                        <Grid item xs={12} fontWeight={600}>
                            {aclCameras && 
                                aclCameras.map((c) => (
                                    <Camera key={c.id} name={c.name} />
                                ))
                            }
                        </Grid>
                    </Grid>
                </Stack>
            </SidebarTabPanel>
            <SidebarTabPanel value={tab} index={1} padding={0}>
                <Stack
                    padding="0px 20px 0px 20px"
                    spacing={0}
                >
                    <AdminACLLinkSelect acl={acl} />
                </Stack>
            </SidebarTabPanel>
        </Stack>
    );
}

AdminACLSidebar.propTypes = {
    drawerWidth: PropTypes.number.isRequired,
    acl: PropTypes.object.isRequired,
};

AdminACLSidebar.defaultProps = {
    drawerWidth: null,
    acl: null,
};

export default AdminACLSidebar;

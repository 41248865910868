import * as React from "react";
import { Link } from 'react-router-dom';

import Divider from "@mui/material/Divider";
// import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import PropTypes from "prop-types";

import { useApp } from "../../../context/App";

function CameraSidebarMenu({ 
    camera = {},
    updateCamera = () => {},
    hasEditPermission = false,
}) {
    const { hasPermission } = useApp();
    const [anchorEl, setAnchorEl] = React.useState(false);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const videoViewerRoles = [
        "admin",
        "viewer",
        "editor",
        "video:admin",
        "video:viewer",
        "video:editor",
    ];

    const analyticsViewerRoles = [
        "admin",
        "viewer",
        "editor",
        "analytics:admin",
        "analytics:viewer",
        "analytics:editor",
    ];

    async function handleUpdate(name, value) {   
        updateCamera({
            camera_id: camera.id, 
            camera: {[name]: value},
        });
    }

    return (
        <>
            <IconButton
                color="primary"
                id="message-menu-button"
                aria-controls={open ? "message-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                size="small"
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="message-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": "message-menu-button",
                }}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <MenuList>
                    <MenuItem
                        component={Link}
                        to={`/video/camera?camera_id=${camera.id}&latest=50`}
                        disabled={!hasPermission(videoViewerRoles) || !camera.sla}
                    >
                        <ListItemIcon>
                            <OpenInNewIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Video anzeigen</ListItemText>
                    </MenuItem>
                    <MenuItem
                        component={Link}
                        to={`/analytics/camera?camera_id=${camera.id}`}
                        disabled={!hasPermission(analyticsViewerRoles)}
                    >
                        <ListItemIcon>
                            <OpenInNewIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Analytics aufrufen</ListItemText>
                    </MenuItem>
                    <Divider style={{ borderTop: 1 }} />
                    {/* <MenuItem
                        onClick={handleClose}
                        disabled={!hasPermission(cameraEditorRoles)}
                    >
                        <ListItemIcon>
                            <ReportProblemIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Störung melden</ListItemText>
                    </MenuItem>
                    <Divider style={{ borderTop: 1 }} /> */}
                    <MenuItem disabled={!hasEditPermission} onClick={() => handleUpdate("enabled", !camera.enabled)}>
                        <ListItemIcon>
                            <ModeEditIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>{camera.enabled ? "Deaktivieren" : "Aktivieren"}</ListItemText>
                    </MenuItem>
                    {/* <MenuItem onClick={handleClose} disabled>
                        <ListItemIcon>
                            <DeleteIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Löschen</ListItemText>
                    </MenuItem> */}
                </MenuList>
            </Menu>
        </>
    );
}

CameraSidebarMenu.propTypes = {
    cameraAdminRoles: PropTypes.array,
    cameraEditorRoles: PropTypes.array,
    camera: PropTypes.object.isRequired,
};

CameraSidebarMenu.defaultProps = {
    cameraAdminRoles: [],
    cameraEditorRoles: [],
    camera: {},
};

export default CameraSidebarMenu;

import React from 'react';
import { Stack } from '@mui/material';

import ImageUploadField from '../../../components/ImageUploadField';
import FormButtonGroup from '../../../components/FormButtonGroup';
import FormTextField from '../../../components/FormTextField';
import { removeEmptyStrings, useFormSection,  } from '../../../components/misc/useForm';


export default function PlayoutLogoForm({ 
    playout = {}, 
    updatePlayout = () => {},
    hasPermission = false,
}) {
    const playoutDefault = {
        image: playout.logo?.image || "",
        position: playout.logo?.position || "",
        width: playout.logo?.width || "",
        margin: playout.logo?.margin || "",
    };
    const { 
        isUpdated, 
        setIsUpdated, 
        formUpdate: playoutUpdate,
        setFormUpdate: setPlayoutUpdate, 
        handleChange,
    } = useFormSection({
        formDefault: playoutDefault,
    });
    const [logoImage, setLogoImage] = React.useState(null);

    React.useEffect(() => {
        if (logoImage && logoImage !== playoutUpdate.image) {
            setIsUpdated(true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [logoImage]);

    function handleReset() {
        setPlayoutUpdate((prev) => {
            return playoutDefault;
        });
        setLogoImage((prev) => {
            return null
        });
        setIsUpdated(false);
    }

    async function handleUpdate() {    
        updatePlayout({
            playout_id: playout.id, 
            playout: { logo: removeEmptyStrings(playoutUpdate) },
            playout_logo_image: logoImage !== playoutDefault.image ? logoImage : null,
        });
        setIsUpdated(false);
    }

    const positions = [
        {
            value: 'top-left',
            label: 'Oben links',
        },
        {
            value: 'top-right',
            label: 'Oben rechts',
        },
        {
            value: 'bottom-left',
            label: 'Unten links',
        },
        {
            value: 'bottom-right',
            label: 'Unten rechts',
        },
    ];

    return (
        <>
            <Stack direction="row" spacing={2} alignItems="center" width={"100%"}>
                <ImageUploadField
                    id="logo_image"
                    uploadedFile={logoImage || playoutUpdate.image}
                    setUploadedFile={setLogoImage}
                    readOnly={!hasPermission}
                    width={72}
                    height={42}
                />
                <FormTextField
                    name="image"
                    label="Dateiname"
                    object={playoutUpdate}
                    handleChange={handleChange}
                    hasPermission={hasPermission}
                />
            </Stack>
            <Stack direction="row" spacing={2} alignItems="center" width={"100%"} justifyContent={"space-between"}>
                <FormTextField
                    name="position"
                    label="Position"
                    object={playoutUpdate}
                    handleChange={handleChange}
                    hasPermission={hasPermission}
                    select
                    selectOptions={positions}
                >
                </FormTextField>
                <FormTextField
                    name="width"
                    label="Breite (CSS)"
                    value={playoutUpdate}
                    object={playoutUpdate}
                    handleChange={handleChange}
                    hasPermission={hasPermission}
                    placeholder='15vw'
                    maxLength={5}
                />
                <FormTextField
                    name="margin"
                    label="Abstand (CSS)"
                    object={playoutUpdate}
                    handleChange={handleChange}
                    hasPermission={hasPermission}
                    maxLength={12}
                    placeholder='3% 0 0 5%'
                />
            </Stack>
            {hasPermission && isUpdated && (
                <Stack
                    direction="row"
                    spacing={1}
                    sx={{
                        justifyContent: "flex-end",
                        alignItems: "center",
                        width: "100%",
                        paddingTop: 1,
                    }}
                >
                    <FormButtonGroup 
                        handleReset={handleReset}
                        handleUpdate={handleUpdate}
                    />
                </Stack>
            )}
        </>
    );
}
import React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';


export default function FormButtonGroup({
    handleReset = () => {},
    handleUpdate = () => {},
    updateValidation = false,
}) {
    return (
        <ButtonGroup 
            size="small" 
            color="success"
            sx={{
                border: '1px solid rgba(0,0,0,.2)',
                "& .MuiButtonGroup-firstButton": {
                    borderRight: "2px solid rgba(0,0,0,.2)",
                },
            }}
        >
            <Button
                onClick={handleReset}
                sx={{
                    background: 'white',
                    color: '#000',
                    border: "1px solid transparent",
                    "&:hover": {
                        color: '#fff',
                        background: 'rgba(219, 46, 51, 0.9)',
                    }
                }}
            >
                Abbrechen
            </Button>
            <Button
                onClick={handleUpdate}
                disabled={updateValidation}
                sx={{
                    background: 'white',
                    color: '#168d1a',
                    border: "1px solid transparent",
                    "&:hover": {
                        color: '#fff',
                        background: '#168d1a',
                    }
                }}
            >
                <CheckCircleIcon sx={{marginRight: 1}} /> Speichern
            </Button>
        </ButtonGroup>
    );
}
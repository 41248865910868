import * as React from "react";

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Paper from "@mui/material/Paper";
import PropTypes from "prop-types";
import Stack from "@mui/material/Stack";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

import "moment-timezone";
import "moment/locale/de";
import moment from 'moment';

import { SparkLineChart } from '@mui/x-charts/SparkLineChart';

import fallback from "../../images/fallback.png";

import CameraPreviewOverlay from "../../components/CameraPreviewOverlay";
import PlayerPreviewOverlay from "../../components/PlayerPreviewOverlay";
import SearchField from "../../components/SearchField";
import SidebarTabPanel from "../../components/SidebarTabPanel";
import SidebarTabPanelProps from "../../components/SidebarTabPanelProps";
import Table from "../../components/Table";


function ViewerLabel({ 
    current = 0,
    trend = 0,
    series = [], 
}) {

    let sessionValues = [];
    let tsValues = [];

    if (series) {
        sessionValues = series
            .sort((a, b) => a[0] - b[0]) 
            .map(value => Number(value[1]));
        const format = 'HH:mm:ss';
        tsValues = series
            .sort((a, b) => a[0] - b[0])
            .map(value => Number(value[0]))
            .map(ts => moment(ts * 1000).format(format));
    }

    return (
        <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={2}
            width="100%"
        >
            {current}
            {trend > 0 && (<ArrowUpwardIcon sx={{ color: "black", width: 20, height: 20 }} />)}
            {trend < 0 && (<ArrowDownwardIcon sx={{ color: "black", width: 20, height: 20 }} />)}
            {trend === 0 && (<ArrowDownwardIcon sx={{ color: "black", width: 20, height: 20, opacity: 0 }} />)}
            <div sx={{ width: "50px", maxWidth: "50px" }}>
                <SparkLineChart
                    data={sessionValues}
                    xAxis={{
                        scaleType: 'band',
                        data: tsValues,
                        valueFormatter: (value) => value.toLocaleString(),
                    }}
                    showTooltip={true}
                    height={20}
                    width={50}
                    colors={['black']}
                    margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                />
            </div>
        </Stack>
    );
}

function CameraNameLabel({ 
    id = "",
    playerConfig = {}, 
    name = "", 
}) {
    const [backgroundImage, setBackgroundImage] = React.useState(`url(${fallback})`);

    React.useEffect(() => {
        const img = new Image();
        img.src = `${playerConfig.url}${id}/live/main_240.jpg`;
        img.onload = () => {
            setBackgroundImage(`url(${img.src})`);
        };
    }, [id, playerConfig.url]);

    return (
        <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
        >
            <Paper
                elevation={0}
                sx={{
                    backgroundImage: backgroundImage,
                    backgroundSize: "cover",
                    padding: 0,
                    height: 30,
                    minWidth: 55,
                    borderRadius: 1,
                    border: "1px solid #f1f1f1",
                }}
            />
            <span sx={{ marginLeft: 2 }}>{name}</span>
        </Stack>
    );
}


function Cameras({ 
    cameraMetrics = [],
    playerConfig = {}, 
    searchText = "", 
}) {

    const [rows, setRows] = React.useState([]);
    const [selectedRow, setSelectedRow] = React.useState(null);
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [mousePosition, setMousePosition] = React.useState(null);
    React.useEffect(() => {
        if (selectedRow) {
            setDialogOpen(prevState => true);
        }
    }, [selectedRow]);

    const headCells = [
        {
            id: "name",
            numeric: false,
            disablePadding: true,
            label: "Name",
        },
        {
            id: "viewer",
            numeric: true,
            disablePadding: true,
            label: "Zugriffe",
            fontSize: 20,
        },
    ];

    React.useEffect(() => {
        const newRows = [...rows];
        if (cameraMetrics) {
            for (const metric in cameraMetrics) {
                const m = cameraMetrics[metric];
                const c = cameraMetrics[metric].metric.camera;
                if (c) {
                    const newRow = {
                        id: { value: c.id, label: "" },
                        name: {
                            value: c.meta.name, label: <CameraNameLabel
                                id={c.id}
                                playerConfig={playerConfig}
                                name={c.meta.name}
                            />
                        },
                        viewer: {
                            value: m.summary.current, label: <ViewerLabel
                                current={m.summary.current}
                                trend={m.summary.trend}
                                series={m.values}
                            />
                        },
                        alias_id: { value: c.alias_id, label: c.alias_id },
                        producer: { value: c.meta.producer, label: c.meta.producer },
                        metric: m,
                    };
                    const rowIndex = newRows.findIndex(row => row.id.value === newRow.id.value);
                    if (rowIndex !== -1) {
                        newRows[rowIndex].metric = newRow.metric;
                        newRows[rowIndex].viewer = newRow.viewer;
                    } else {
                        newRows.push(newRow);
                    }
                }
            }
        }
        setRows(newRows);
    // eslint-disable-next-line
    }, [cameraMetrics]);

    const cameraMetric = selectedRow ? selectedRow.row.metric : null;
    const camera = cameraMetric ? cameraMetric.metric.camera : null;

    return (
        <>
            <Table 
                rows={rows}
                maxRows={8}
                sortBy={["viewer"]}
                sortDir={"asc"}
                rowHeight={1}
                dense={false}
                headCells={headCells}
                searchText={searchText}
                setSelectedRow={setSelectedRow}
                setMousePosition={setMousePosition}
            />
            {dialogOpen && camera && (
                <CameraPreviewOverlay
                    key={selectedRow ? selectedRow.updated : Date.now()}
                    camera={camera}
                    dialogOpenid={dialogOpen} 
                    setDialogOpen={setDialogOpen}
                    mousePosition={mousePosition}
                    playerConfig={playerConfig}
                />
            )}
        </>
    );
};


function PlayerNameLabel({ 
    logo = {},
    name = "",
}) {
    const [backgroundImage, setBackgroundImage] = React.useState(`url(${fallback})`);

    React.useEffect(() => {
        const img = new Image();
        img.src = `${logo?.image}` || fallback;
        img.onload = () => {
            setBackgroundImage(`url(${img.src})`);
        };
    // eslint-disable-next-line
    }, [name]);

    return (
        <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
        >
            <Paper
                elevation={0}
                sx={{
                    backgroundImage: backgroundImage,
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat", 
                    backgroundPosition: "center",
                    padding: 0,
                    height: 30,
                    minWidth: 55,
                    borderRadius: 1,
                    border: "1px solid #f1f1f1",
                }}
            />
            <span sx={{ marginLeft: 2 }}>{name}</span>
        </Stack>
    );
}


function Player({ 
    playerMetrics = [],
    searchText = "", 
}) {

    const [rows, setRows] = React.useState([]);
    const [selectedRow, setSelectedRow] = React.useState(null);
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [mousePosition, setMousePosition] = React.useState(null);
    React.useEffect(() => {
        if (selectedRow) {
            setDialogOpen(prevState => true);
        }
    }, [selectedRow]);

    const headCells = [
        {
            id: "name",
            numeric: false,
            disablePadding: true,
            label: "Name",
            
        },
        {
            id: "viewer",
            numeric: true,
            disablePadding: true,
            label: "Zugriffe",
            fontSize: 20,
        },
    ];

    React.useEffect(() => {
        const newRows = [...rows];
        if (playerMetrics) {
            for (const metric in playerMetrics) {
                const m = playerMetrics[metric];
                const p = m.metric.player;
                if (p) {
                    const newRow = {
                        id: { value: p.id, label: "" },
                        name: { value: p.name, label: <PlayerNameLabel logo={p.logo} name={p.name} /> },
                        viewer: {
                            value: m.summary.current, label: <ViewerLabel
                                current={m.summary.current}
                                trend={m.summary.trend}
                                series={m.values}
                            />
                        },
                        domains: { value: p.domain_whitelist, label: p.domain_whitelist },
                        metric: m,
                    };
                    const rowIndex = newRows.findIndex(row => row.id.value === newRow.id.value);
                    if (rowIndex !== -1) {
                        newRows[rowIndex].viewer = newRow.viewer;
                        newRows[rowIndex].metric = newRow.metric;
                    } else {
                        newRows.push(newRow);
                    }
                }
            }
        }
        setRows(newRows);
    // eslint-disable-next-line
    }, [playerMetrics]);

    const playerMetric = selectedRow ? selectedRow.row.metric : null;
    const player = playerMetric ? playerMetric.metric.player : null;

    return (
        <>
            <Table 
                rows={rows} 
                maxRows={8} 
                sortBy={["viewer"]} 
                sortDir={"asc"} 
                rowHeight={1} 
                dense={false} 
                headCells={headCells} 
                searchText={searchText}
                setSelectedRow={setSelectedRow}
                setMousePosition={setMousePosition}
            />
            {dialogOpen && player && (
                <PlayerPreviewOverlay
                    key={selectedRow ? selectedRow.updated : Date.now()}
                    player={player}
                    dialogOpenid={dialogOpen} 
                    setDialogOpen={setDialogOpen}
                    mousePosition={mousePosition}
                />
            )}
        </>
    );
};


function LiveBox({ 
    cameraMetrics = [],
    playerMetrics = [], 
    playerConfig = {}, 
}) {

    const [tab, setTab] = React.useState(0);
    const handleChangeTag = (event, newValue) => {
        setTab(newValue);
    };

    const [cameraSearchText, setCameraSearchText] = React.useState("");
    const [playerSearchText, setPlayerSearchText] = React.useState("");

    return (
        <>
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
                marginTop={-2}
                marginBottom={-1}
                marginLeft={"-25px"}
                marginRight={"-25px"}
                borderBottom={"1px solid rgba(0, 0, 0, 0.12)"}
            >
                <Tabs value={tab} onChange={handleChangeTag}>
                    <Tab
                        iconPosition="start"
                        label="Kameras"
                        {...SidebarTabPanelProps(0)}
                        style={{ textTransform: "initial" }}
                    />
                    <Tab
                        iconPosition="start"
                        label="Player"
                        {...SidebarTabPanelProps(1)}
                        style={{ textTransform: "initial" }}
                    />
                    {/* <Tab
                        iconPosition="start"
                        label="Regionen"
                        {...SidebarTabPanelProps(1)}
                        style={{ textTransform: "initial" }}
                    /> */}
                </Tabs>
            </Stack>
            <SidebarTabPanel value={tab} index={0} padding={0}>
                <Stack
                    style={{
                        marginBottom: -25,
                    }}
                    spacing={0}
                >
                    <Stack
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                        spacing={2}
                        paddingTop={1.5}
                        paddingBottom={0}
                        height={50}
                    >
                        <SearchField
                            searchText={cameraSearchText}
                            setSearchText={setCameraSearchText}
                            fullWidth
                        />
                    </Stack>
                    <Cameras 
                        cameraMetrics={cameraMetrics} 
                        playerConfig={playerConfig} 
                        searchText={cameraSearchText}
                    />
                </Stack>
            </SidebarTabPanel>
            <SidebarTabPanel value={tab} index={1} padding={0}>
                <Stack
                    style={{
                        marginBottom: -25,
                    }}
                    spacing={0}
                >
                    <Stack
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                        spacing={2}
                        paddingTop={1.5}
                        paddingBottom={0}
                        height={50}
                    >
                        <SearchField
                            searchText={playerSearchText}
                            setSearchText={setPlayerSearchText}
                            fullWidth
                        />
                    </Stack>
                    <Player 
                        playerMetrics={playerMetrics}
                        searchText={playerSearchText}
                    />
                </Stack>
            </SidebarTabPanel>
            <SidebarTabPanel value={tab} index={2} padding={0}>
                <Stack
                    style={{
                        marginBottom: -25,
                    }}
                    spacing={0}
                >
                    <Stack
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                        spacing={2}
                        paddingTop={1.5}
                        paddingBottom={0}
                        height={50}
                    >
                        <SearchField
                            searchText={playerSearchText}
                            setSearchText={setPlayerSearchText}
                        />
                    </Stack>
                </Stack>
            </SidebarTabPanel>
        </>
    );
};

LiveBox.propTypes = {
    cameraMetrics: PropTypes.array,
    playerMetrics: PropTypes.array,
    playerConfig: PropTypes.object
};

export default LiveBox;
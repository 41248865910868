import * as React from "react";
import { useParams, useNavigate } from 'react-router-dom';
import PropTypes from "prop-types";

import Stack from "@mui/material/Stack";

import Page from "../../components/Page";
import Alerts from "./Alerts";
import CameraSidebar from "../Camera/CameraSidebar";
import SearchField from "../../components/SearchField";
import AlertSlaFilter from "../../components/AlertSlaFilter";

import { useApp } from "../../context/App";

function Alert(props) {
    const { rootPath } = props;
    const { client, hasPermission, backdrop, reload, setSelectedPage } = useApp();
    const [ready, setReady] = React.useState(false);

    // PAGE SIDEBAR
    const [openSidebar, setOpenSidebar] = React.useState(false);
    const handleSidebarToggle = () => {
        setOpenSidebar(!openSidebar);
        navigate(rootPath);
    };

    // PERMISSION CHECK
	const cameraAdminRoles = ["admin", "camera:admin"];
	const cameraEditorRoles = cameraAdminRoles.concat([
		"editor",
		"camera:editor",
	]);

    // PLAYER OBJECT
    const [playerConfig, setPlayerConfig] = React.useState(false);
    const getPlayerConfig = async () => {
        const data = client.PlayerConfig();
        setPlayerConfig(data);
        return playerConfig;
    };

    const [alerts, setAlerts] = React.useState(null);
    const navigate = useNavigate();
    const { camera_id } = useParams();
    const [cameras, setCameras] = React.useState(null);
    const [camera, setCamera] = React.useState(null);

    const updateCamera = async ({
		camera_id = null,
		camera = null,
		camera_logo_image = null,
		camera_poster_image = null,
	}) => {
		if (!camera_id) return;
		let data = null;
		if (camera) {
			data = await client.PutCustomerCameraId({
				camera_id: camera_id, camera: camera
			});
		}
		if (camera_logo_image) {
			data = await client.PutCustomerCameraIdLogoImage({
				camera_id: camera_id, image: camera_logo_image
			});
		}
		if (camera_poster_image) {
			data = await client.PutCustomerCameraIdPosterImage({
				camera_id: camera_id, image: camera_poster_image
			});
		}
		if (data) {
			setCamera(data);
			setCameras(cameras.map((item) => (item.id === camera_id ? data : item)));
		}
	};

    React.useEffect(() => {
        (async () => {
            setSelectedPage("alert_camera");
            await getPlayerConfig();
            await client.GetCustomerAlert().then((data) => setAlerts(data));
            await client.GetCustomerCamera().then((data) => setCameras(data));
            setReady(true);
            return () => {
                setReady(false);
            };
        })();
    }, [setSelectedPage, client, reload]);

    React.useEffect(() => {
        backdrop(!ready);
    }, [ready, backdrop]);

    React.useEffect(() => {
        if (cameras && camera_id) {
            if (!camera || camera.id !== camera_id) {
                const data = cameras.find((camera) => camera.id === camera_id);
                if (!data) {
                    navigate(rootPath);
                } else {
                    setCamera(data);
                    setOpenSidebar(true);
                }
            }
        } else {
            setCamera(null);
            setOpenSidebar(false);
        }
    }, [cameras, camera_id]);

    const [searchText, setSearchText] = React.useState("");
    const [slaFilterStates, setSlaFilterStates] = React.useState({
        sla2: true,
        sla8: true,
        sla0: false,
        slaNone: false,
    });

    const breadcrumbs = () => {
        if (camera) {
            return [
                { title: 'Störungen', link: rootPath },
                { title: 'Kamera', link: rootPath },
                { title: camera.id, link: `${rootPath}/${camera.id}` }
            ];
        } else {
            return [
                { title: 'Störungen', link: rootPath },
                { title: 'Kamera', link: rootPath }
            ];
        }
    };

    const drawerWidth = 480;

    return (
        <Page
            pageTitel="Kamera Störungen"
			breadcrumbs={breadcrumbs ? breadcrumbs() : []}
            headerContentRight={
                <Stack
                    direction="row"
                    justifyContent="flex-left"
                    alignItems="center"
                    spacing={1}
                >
                    <SearchField
                        outlined
                        searchText={searchText} 
                        setSearchText={setSearchText} 
                    />
                    <AlertSlaFilter
                        outlined 
                        slaFilterStates={slaFilterStates} 
                        setSlaFilterStates={setSlaFilterStates} 
                    />
                </Stack>
            }
            drawerWidth={drawerWidth}
            openSidebar={openSidebar}
            handleSidebarToggle={handleSidebarToggle}
            sidebarContent={
                camera ? (
                    <CameraSidebar
                        key={camera.id}
						drawerWidth={drawerWidth} 
						camera={camera} 
						updateCamera={updateCamera}
						playerConfig={playerConfig} 
						openSidebar={openSidebar}
						handleSidebarToggle={handleSidebarToggle}
						hasPermission={hasPermission(cameraEditorRoles)}
                    />
                ) : null
            }
        >
            {hasPermission(cameraEditorRoles) && alerts && cameras && (
                <Alerts
                    alerts={alerts}
                    cameras={cameras}
                    camera_id={camera_id}
                    playerConfig={playerConfig}
                    navigate={navigate}
                    rootPath={rootPath}
                    slaFilterStates={slaFilterStates}
                    searchText={searchText}
                />
            )}
        </Page>
    );
}

Alert.propTypes = {
    openMenu: PropTypes.bool,
    handleMenuToggle: PropTypes.func,
    setSelectedPage: PropTypes.func,
};

Alert.defaultProps = {
    openMenu: false,
};

export default Alert;

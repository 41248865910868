import * as React from "react";

import PropTypes from "prop-types";

import Page from "../../components/Page";

import AdminBilling from "./AdminBilling";

import { useApp } from "../../context/App";

function Admin(props) {
    const { rootPath } = props;
    const { client, hasPermission, backdrop, setSelectedPage, reload } = useApp();
    const [ready, setReady] = React.useState(false);

    // PERMISSION CHECK
    const adminRoles = ["admin"];
    const billingRoles = adminRoles.concat(["billing"]);

    const [billing, setBilling] = React.useState(null);

    React.useEffect(() => {
        (async () => {
            setSelectedPage("admin_billing");
            await client
                .GetCustomerBilling()
                .then((data) => setBilling(data));
            setReady(true);
            return () => {
                setReady(false);
            };
        })();
    }, [setSelectedPage, client, reload]);

    React.useEffect(() => {
        backdrop(!ready);
    }, [ready, backdrop]);

    const drawerWidth = 480;

    return (
        <Page
            drawerWidth={drawerWidth}
            pageTitel="Abrechnung"
			breadcrumbs={[
                {title: 'Admin', link: '#'},
                {title: 'Abrechnung', link: rootPath}
            ]}
            //sidebarContent={}
        >
           {hasPermission(billingRoles) && billing && (
                <AdminBilling billing={billing} />
            )}
        </Page>
    );
}

Admin.propTypes = {
    openMenu: PropTypes.bool,
    handleMenuToggle: PropTypes.func,
    setSelectedPage: PropTypes.func,
};

export default Admin;

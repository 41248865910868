import * as React from "react";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";

import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';

function CameraMeta({ meta }) {
    return (
        <React.Fragment>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="stretch"
                spacing={1}
            >
                <Grid item xs={12}>
                    <TextField
                        label="Name"
                        value={meta.name}
                        disabled={!meta.name}
                        size="small"
                        readOnly
                        variant="standard"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Beschreibung"
                        value={meta.description}
                        disabled={!meta.description}
                        size="small"
                        multiline
                        maxRows={5}
                        readOnly
                        variant="standard"
                        fullWidth
                        slotProps={{
                            htmlInput: { 
                                max: 5000, 
                                min: 10 
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Name (Kurz)"
                        value={meta.name_short}
                        disabled={!meta.name_short}
                        size="small"
                        readOnly
                        variant="standard"
                        fullWidth
                        slotProps={{
                            htmlInput: { 
                                max: 35, 
                                min: 10 
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                            xs={{
                                "&.MuiAccordionSummary-content": {
                                    margin: "0px",
                                    padding: "0px",
                                    "&.Mui-expanded": {
                                        margin: "0px",
                                        padding: "0px",
                                    },
                                },
                                "&.MuiAccordionSummary-content.Mui-expanded": {
                                    margin: "0px",
                                    padding: "0px",
                                },
                            }}
                        >
                            <Typography component="span">Suchmaschine</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <TextField
                                label="Name"
                                value={meta.name_seo}
                                disabled={!meta.name_seo}
                                size="small"
                                readOnly
                                variant="standard"
                                fullWidth
                                slotProps={{
                                    htmlInput: { 
                                        max: 60, 
                                        min: 10 
                                    }
                                }}
                            />
                            <TextField
                                label="Beschreibung"
                                value={meta.description_seo}
                                disabled={!meta.description_seo}
                                size="small"
                                multiline
                                maxRows={5}
                                readOnly
                                variant="standard"
                                fullWidth
                                slotProps={{
                                    htmlInput: { 
                                        max: 160, 
                                        min: 10 
                                    }
                                }}
                            />
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2-content"
                            id="panel2-header"
                            xs={{
                                "&:Mui-expanded": {
                                    margin: "12px 0",
                                },
                            }}
                        >
                            <Typography component="span">Social-Media</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <TextField
                                label="Name"
                                value={meta.name_seo}
                                disabled={!meta.name_seo}
                                size="small"
                                readOnly
                                variant="standard"
                                fullWidth
                                slotProps={{
                                    htmlInput: { 
                                        max: 60, 
                                        min: 10 
                                    }
                                }}
                            />
                            <TextField
                                label="Beschreibung"
                                value={meta.description_share}
                                disabled={!meta.description_share}
                                size="small"
                                multiline
                                maxRows={5}
                                readOnly
                                variant="standard"
                                fullWidth
                                slotProps={{
                                    htmlInput: { 
                                        max: 160, 
                                        min: 10 
                                    }
                                }}
                            />
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                {meta.website_url && (
                    <Grid item xs={12}>
                        <TextField
                            label="Webseite"
                            value={meta.website_url}
                            size="small"
                            readOnly
                            variant="standard"
                            fullWidth
                        />
                    </Grid>
                )}
                {meta.producer && (
                    <Grid item xs={12}>
                        <TextField
                            label="Produzent"
                            value={meta.producer}
                            size="small"
                            readOnly
                            variant="standard"
                            fullWidth
                        />
                    </Grid>
                )}
            </Grid>
        </React.Fragment>
    );
}

CameraMeta.propTypes = {
    meta: PropTypes.object.isRequired,
};

CameraMeta.defaultProps = {};

export default CameraMeta;

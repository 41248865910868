import React from "react";
import { Chip, Stack } from "@mui/material";
import SupportIcon from "@mui/icons-material/Support";


export default function CameraSlaChip({camera}) {
    return (
        <Stack
            direction="row"
            alignItems="center"
            spacing={1}
        >
            {camera.sla && (
                <Chip
                    size="small"
                    icon={<SupportIcon style={{ color: "#fff", fontSize: 16 }} />}
                    label={
                        camera.sla > 0 ? `SLA ${camera.sla}` : `SLA`
                    }
                    sx={{
                        color: "#fff", fontSize: 12, backgroundColor: "#0085E9",
                    }}
                />
            )}
        </Stack>
    );
}
import * as React from 'react';
import PropTypes from 'prop-types';

import 'moment-timezone';
import moment from 'moment';

import Divider from '@mui/material/Divider';

import Stack from '@mui/material/Stack';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';

import PageSidebarHeader from "../../../components/PageSidebarHeader";
import SidebarTabPanel from '../../../components/SidebarTabPanel';
import SidebarTabPanelProps from '../../../components/SidebarTabPanelProps';
import VideoJS from '../../../components/VideoJS';

import CameraMeta from '../../Camera/CameraSidebar/CameraMeta';
import CameraStatusChip from '../../../components/CameraStatusChip';
import RestreamAlert from './RestreamAlert';
import RestreamSidebarSettings from './RestreamSidebarSettings';
import RestreamStream from './RestreamStream';
import RestreamSidebarMenu from './RestreamSidebarMenu';

function RestreamSidebar({ 
	drawerWidth, 
	restream, 
	playerConfig, 
	adminRoles, 
	editorRoles,
	handleSidebarToggle,
}) {
	const [tab, setTab] = React.useState(0);
	const handleChangeTab = (event, newValue) => {
		setTab(newValue);
	};

	// VIDEOJS
	const videoJsOptions = {
		autoplay: false,
		controls: true,
		responsive: true,
		fluid: true,
		poster: playerConfig.url + restream.camera_id + '/live/main_240.jpg',
		playsinline: true,
		muted: true,
		// html5: {
		//     hls: {
		//         overrideNative: true,
		//         limitRenditionByPlayerDimensions: true,
		//         useDevicePixelRatio: true
		//     },
		//     nativeAudioTracks: false,
		//     nativeVideoTracks: false,
		//     useBandwidthFromLocalStorage: true
		// },
		sources: [
			{
				src: playerConfig.url + restream.camera_id + '/live/main.m3u8?token=' + playerConfig.token,
				type: 'application/vnd.apple.mpegurl',
			},
		],
	};

	return (
		<Stack sx={{ maxWidth: drawerWidth }} width={'100%'}>
			<Stack>
				<PageSidebarHeader
					title={restream.name}
					share={true}
					handleToggle={handleSidebarToggle}
					submenu={
						<RestreamSidebarMenu restream={restream} adminRoles={adminRoles} editorRoles={editorRoles} />
					}
					chips={
						<CameraStatusChip camera={restream} />
					}
					player={
						videoJsOptions && <VideoJS key={restream.id} {...videoJsOptions} />
					}
				/>
			</Stack>
			<Divider />
			<Stack
				direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
                marginTop={0}
                borderBottom={"1px solid rgba(0, 0, 0, 0.12)"}
                maxWidth={drawerWidth}
			>
				<Tabs value={tab} onChange={handleChangeTab}>
					<Tab label="Meta" {...SidebarTabPanelProps(0)} />
					<Tab label="Monitor" {...SidebarTabPanelProps(1)} />
					<Tab label="Meldungen" {...SidebarTabPanelProps(2)} />
					<Tab label="Einstellung" {...SidebarTabPanelProps(3)} />
				</Tabs>
				
			</Stack>
			<SidebarTabPanel value={tab} index={0} padding={0}>
				<Stack
					padding="5px 20px 0px 20px"
                    spacing={0}
				>
					<Stack
						style={{
                            padding: "10px 25px 10px 15px",
							backgroundColor: 'rgba(0,0,0,.035)',
							borderRadius: 4,
						}}
						spacing={0.5}
					>
						<Typography fontSize={14}>
							<strong>ID:</strong> {restream.id}
						</Typography>
						<Typography fontSize={14}>
							<strong>Kamera ID:</strong> {restream.camera_id}
						</Typography>
						<Typography fontSize={14}>
							<strong>Erstellt:</strong> {moment.utc(restream.created_at).local().format('DD.MM.YYYY HH:mm:ss')}
						</Typography>
					</Stack>
					<Typography marginTop={2} marginBottom={1.5} variant="h4">
						Standort
					</Typography>
					<Stack
						style={{
							padding: '10px 15px 15px 15px',
							marginBottom: 20,
							backgroundColor: 'rgba(0,0,0,.035)',
							borderRadius: 4,
						}}
						spacing={0}
					>
						<CameraMeta meta={restream.camera.meta} />
					</Stack>
				</Stack>
			</SidebarTabPanel>
			<SidebarTabPanel value={tab} index={1} padding={0}>
				<Stack
					style={{
						marginBottom: 5,
					}}
					spacing={0}
				>
					<RestreamStream restream={restream} />
				</Stack>
			</SidebarTabPanel>
			<SidebarTabPanel value={tab} index={2} padding={0}>
				<Stack
                    padding="5px 20px 0px 20px"
                    spacing={0}
                >
                    <RestreamAlert restream={restream} />
                </Stack>		
			</SidebarTabPanel>
			<SidebarTabPanel value={tab} index={3} padding={0}>
				<Stack
                    padding="5px 20px 0px 20px"
                    spacing={0}
                >
                    <RestreamSidebarSettings restream={restream} />
                </Stack>	
			</SidebarTabPanel>
		</Stack>
	);
}

RestreamSidebar.propTypes = {
	drawerWidth: PropTypes.number,
	restream: PropTypes.object.isRequired,
	playerConfig: PropTypes.object,
};

RestreamSidebar.defaultProps = {
	drawerWidth: 540,
};

export default RestreamSidebar;

import React from 'react';
import { Collapse, Fade, Stack, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import FormTextField from '../../../components/FormTextField';
import FormButtonGroup from '../../../components/FormButtonGroup';
import { removeEmptyStrings, useFormSection } from '../../../components/misc/useForm';
import google from '../../../images/google.svg';

export default function CameraMetadataSeoForm({
    camera = {},
    updateCamera = () => { },
    hasPermission = false,
}) {
    const cameraDefault = {
        name_seo: camera.meta.name_seo || "",
        description_seo: camera.meta.description_seo || "",
    };
    const { 
        isUpdated, 
        setIsUpdated, 
        formUpdate: cameraUpdate, 
        handleChange,
        handleReset,
    } = useFormSection({
        formDefault: cameraDefault,
    });

    async function handleUpdate() {
        updateCamera({
            camera_id: camera.id,
            camera: { meta: removeEmptyStrings(cameraUpdate) }
        });
        setIsUpdated(false);
    }

    function googleDate() {
        const date = new Date(camera.updated_at ? camera.updated_at : camera.created_at);
        return date.toLocaleDateString("de-DE", {
            day: "numeric",
            month: "long",
            year: "numeric",
        });
    }

    const truncateText = (
        text = "",
        maxLength = 160
    ) => {
        if (!text) return "";
        if (text.length <= maxLength) return text;
        const truncated = text.slice(0, maxLength);
        return truncated.slice(0, truncated.lastIndexOf(" ")) + "…";
    };

    const showPreview = (
        cameraUpdate.name_seo && 
        cameraUpdate.name_seo !== "" && 
        cameraUpdate.description_seo && 
        cameraUpdate.description_seo !== ""
    );

    return (
        <>
            <Collapse in={showPreview} timeout={500} sx={{ width: "100%" }} unmountOnExit>
                <Fade in={showPreview} timeout={350}>
                    <Stack
                        direction="column"
                        spacing={1}
                        sx={{
                            width: "100%",
                            padding: "10px 15px",
                            border: "1px solid rgba(0,0,0,.2)",
                            borderRadius: 1,
                            backgroundColor: "#fff",
                        }}
                    >
                        <Stack
                            direction="row"
                            spacing={2}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            sx={{ width: "100%", paddingBottom: 2 }}
                        >
                            <img src={google} alt="Google" style={{ width: 70, height: 30 }} />
                            <Stack
                                direction="row"
                                spacing={0}
                                justifyContent={"flex-end"}
                                alignItems={"center"}
                                sx={{ width: "100%", height: 25, backgroundColor: "#f1f3f4", borderRadius: 10, paddingRight: 1.5 }}
                            >
                                <SearchIcon sx={{ color: "rgba(0,0,0,.5)" }} />
                            </Stack>
                        </Stack>
                        <Stack
                            direction="row"
                            spacing={2}
                            justifyContent={"flex-start"}
                            alignItems={"center"}
                            sx={{ width: "100%" }}
                        >
                            <Typography fontSize={22} lineHeight={1.2} color="blue">{truncateText(cameraUpdate.name_seo)}</Typography>
                        </Stack>
                        <Stack
                            direction="row"
                            spacing={2}
                            justifyContent={"flex-start"}
                            alignItems={"center"}
                            sx={{ width: "100%" }}
                        >
                            <Typography fontSize={14} sx={{ color: "rgba(0,0,0,.7)" }}>{googleDate()} – {truncateText(cameraUpdate.description_seo)}</Typography>
                        </Stack>
                    </Stack>
                </Fade>
            </Collapse>
            <Stack
                direction="column"
                spacing={1}
                sx={{
                    width: "100%", paddingTop: showPreview ? 1 : 0,
                }}
            >
                <FormTextField
                    name="name_seo"
                    label="Name"
                    object={cameraUpdate}
                    handleChange={handleChange}
                    hasPermission={hasPermission}
                    maxLength={160}
                />
                <FormTextField
                    name="description_seo"
                    label="Beschreibung"
                    object={cameraUpdate}
                    handleChange={handleChange}
                    hasPermission={hasPermission}
                    maxLength={160}
                />
            </Stack>
            {hasPermission && isUpdated && (
                <Stack
                    direction="row"
                    spacing={1}
                    sx={{
                        justifyContent: "flex-end",
                        alignItems: "center",
                        width: "100%",
                        paddingTop: 1,
                    }}
                >
                    <FormButtonGroup
                        handleReset={handleReset}
                        handleUpdate={handleUpdate}
                    />
                </Stack>
            )}
        </>
    );
}
import * as React from "react";
import PropTypes from "prop-types";

import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import FormBox from "../FormBox";
import FormTextField from '../FormTextField';
import FormDialog from "../FormDialog";
import { useFormSection, } from '../misc/useForm';
import UserAvatarUpload from "./UserAvatarUpload";


export default function UserForm({
    user = {},
    updateUser = () => { },
    open = false,
    handleClose = () => { },
}) {
    const theme = useTheme();
    const downMd = useMediaQuery(theme.breakpoints.down("md"));
    const hasPermission = true;

    const userDefault = {
        first_name: user.first_name || "",
        last_name: user.last_name || "",
        company: user.company || "",
        role: user.role || "",
        description: user.description || "",
        email: user.email || "",
        phone: user.phone || "",
        mobile: user.mobile || "",
        email_opt_in: user.email_opt_in || false,
        phone_opt_in: user.phone_opt_in || false,
        mobile_opt_in: user.mobile_opt_in || false,
        avatar_url: user.avatar_url || "",
    };

    const {
        isUpdated,
        setIsUpdated,
        formUpdate: userUpdate,
        setFormUpdate: setUserUpdate,
        handleChange,
    } = useFormSection({
        formDefault: userDefault,
    });

    const [avatarImage, setAvatarImage] = React.useState(null);
    React.useEffect(() => {
        if (avatarImage && avatarImage !== userUpdate.avatar_url) {
            setIsUpdated(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [avatarImage]);

    const handleReset = () => {
        setUserUpdate((prev) => {
            return userDefault;
        });
        setAvatarImage((prev) => {
            return null
        });
        setIsUpdated(false);
    };

    const handleUpdate = () => {
        updateUser({
            user: userUpdate,
            user_avatar_image: avatarImage !== userDefault.avatar_url ? avatarImage : null,
        });
        setIsUpdated(false);
    };

    const userRoleOptions = [
        { value: "billing", label: "Buchhaltung" },
        { value: "publisher", label: "Herausgeber" },
        { value: "marketing", label: "Marketing" },
        { value: "producer", label: "Produzent" },
        { value: "editor", label: "Redaktion" },
        { value: "developer", label: "Software Entwickler" },
        { value: "technical", label: "Techniker" },
        { value: "supporter", label: "Technischer Support" },
    ];

    return (
        <FormDialog
            open={open}
            handleClose={handleClose}
            dialogTitle="Profil"
            dialogContent={
                <FormBox>
                    <Stack
                        direction={downMd ? "column" : "row"}
                        justifyContent="space-between"
                        alignItems="flex-start"
                        spacing={3}
                        sx={{
                            width: "100%",
                        }}
                    >
                        <Paper
                            elevation={0}
                            sx={{
                                borderRadius: 2,
                                padding: 1,
                                p: "0px 0px",
                                display: "flex",
                                alignItems: "center",
                                backgroundColor: "#fff",
                                height: 150,
                                width: 150,
                            }}
                        >
                            <UserAvatarUpload
                                id="avatar"
                                uploadedFile={avatarImage || userUpdate.avatar_url || ""}
                                setUploadedFile={setAvatarImage}
                            />
                        </Paper>
                        <Stack
                            direction="column"
                            justifyContent="flex-start"
                            spacing={2}
                            sx={{
                                marginTop: 1,
                                marginBottom: 2,
                                width: "100%",
                            }}
                        >
                            <Stack
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                height={"100%"}
                                spacing={2}
                            >
                                <FormTextField
                                    name="first_name"
                                    label="Vorname"
                                    object={userUpdate}
                                    handleChange={handleChange}
                                    hasPermission={hasPermission}
                                />
                                <FormTextField
                                    name="last_name"
                                    label="Nachname"
                                    object={userUpdate}
                                    handleChange={handleChange}
                                    hasPermission={hasPermission}
                                />
                            </Stack>
                            <FormTextField
                                name="company"
                                label="Firma"
                                object={userUpdate}
                                handleChange={handleChange}
                                hasPermission={hasPermission}
                            />
                            <FormTextField
                                name="role"
                                label="Funktion"
                                object={userUpdate}
                                handleChange={handleChange}
                                hasPermission={hasPermission}
                                select
                                selectOptions={userRoleOptions}
                            />
                            <FormTextField
                                name="description"
                                label="Zusätzliche Beschreibung"
                                object={userUpdate}
                                handleChange={handleChange}
                                hasPermission={hasPermission}
                            />
                            <FormTextField
                                name="email"
                                label="E-Mail Adresse"
                                object={userUpdate}
                                handleChange={handleChange}
                                hasPermission={hasPermission}
                                error={!userUpdate.email_opt_in}
                                helperText={
                                    !userUpdate.email_opt_in
                                        ? "Bitte den E-Mail Opt-In bestätigen."
                                        : ""
                                }
                            />
                            <Stack
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                height={"100%"}
                                spacing={2}
                            >
                                <FormTextField
                                    name="phone"
                                    label="Telefonnummer"
                                    object={userUpdate}
                                    handleChange={handleChange}
                                    hasPermission={hasPermission}
                                    error={userUpdate.phone && !userUpdate.phone_opt_in}
                                    helperText={
                                        userUpdate.phone && !userUpdate.phone_opt_in
                                            ? "Bitte den Telefon Opt-In bestätigen."
                                            : ""
                                    }
                                />
                                <FormTextField
                                    name="mobile"
                                    label="Handynummer"
                                    object={userUpdate}
                                    handleChange={handleChange}
                                    hasPermission={hasPermission}
                                    error={userUpdate.mobile && !userUpdate.mobile_opt_in}
                                    helperText={
                                        userUpdate.mobile && !userUpdate.mobile_opt_in
                                            ? "Bitte den Handy Opt-In bestätigen."
                                            : ""
                                    }
                                />
                            </Stack>
                            <Stack
                                direction={downMd ? "column" : "row"}
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                height={"100%"}
                            >
                                <FormGroup>
                                    <FormControlLabel
                                        label="E-Mail Opt-In"
                                        control={
                                            <Checkbox
                                                color="success"
                                                name="email_opt_in"
                                                checked={userUpdate.email_opt_in}
                                                handleChange={handleChange}
                                                hasPermission={true}
                                            />
                                        }
                                    />
                                </FormGroup>
                                {userUpdate.phone !== "" && (
                                    <FormGroup>
                                        <FormControlLabel
                                            label="Telefon Opt-In"
                                            control={
                                                <Checkbox
                                                    color="success"
                                                    name="phone_opt_in"
                                                    checked={userUpdate.phone_opt_in}
                                                    onChange={handleChange}
                                                />
                                            }
                                        />
                                    </FormGroup>
                                )}
                                {userUpdate.mobile !== "" && (
                                    <FormGroup>
                                        <FormControlLabel
                                            label="Handy Opt-In"
                                            control={
                                                <Checkbox
                                                    color="success"
                                                    name="mobile_opt_in"
                                                    checked={userUpdate.mobile_opt_in}
                                                    onChange={handleChange}
                                                />
                                            }
                                        />
                                    </FormGroup>
                                )}
                            </Stack>
                        </Stack>
                    </Stack>
                </FormBox>
            }
            dialogActions={
                <Stack
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    spacing={1}
                    width={"100%"}
                >
                    {/* <Button
                        onClick={handleClose}
                        sx={{
                            textTransform: "none",
                            color: '#000',
                            border: "1px solid transparent",
                            borderRadius: 1,
                            "&:hover": {
                                color: '#fff',
                                background: 'rgba(219, 46, 51, 0.9)',
                            }
                        }}
                    >
                        Passwort zurücksetzen
                    </Button> */}
                    <Stack
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                        spacing={1}
                    >
                        <Button
                            disabled={!isUpdated}
                            onClick={handleReset}
                            sx={{
                                textTransform: "none",
                                color: '#000',
                                border: "1px solid transparent",
                                borderRadius: 1,
                                "&:hover": {
                                    color: '#fff',
                                    background: 'rgba(219, 46, 51, 0.9)',
                                }
                            }}
                        >
                            Abbrechen
                        </Button>
                        <Button
                            disabled={!isUpdated}
                            onClick={handleUpdate}
                            sx={{
                                textTransform: "none",
                                background: '#168d1a',
                                color: '#fff',
                                border: "1px solid transparent",
                                borderRadius: 1,
                                "&:hover": {
                                    color: '#fff',
                                    background: '#106f13',
                                }
                            }}
                        >
                            Speichern
                        </Button>
                    </Stack>
                </Stack>
            }
        />

    );
}

UserForm.propTypes = {
    user: PropTypes.object.isRequired,
    updateUser: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
};

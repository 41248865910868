import * as React from "react";

import { visuallyHidden } from "@mui/utils";

import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import Paper from "@mui/material/Paper";
import PropTypes from "prop-types";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";


function GetRows({ users }) {
    let rows = [];

    for (const u in users) {
        users[u].name = `${users[u].first_name} ${users[u].last_name}`
        rows = rows.concat([
            users[u]
        ]);
    }

    return rows;
} 

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {
        id: "name",
        numeric: false,
        disablePadding: true,
        label: "Name",
    },
    {
        id: "company",
        numeric: false,
        disablePadding: true,
        label: "Unternehmen",
    },
    // {
    //     id: "type",
    //     numeric: true,
    //     disablePadding: false,
    //     label: "Typ",
    // },
    // {
    //     id: "permission_roles",
    //     numeric: true,
    //     disablePadding: false,
    //     label: "Berechtigung",
    // },
    // {
    //     id: "email_opt_in",
    //     numeric: true,
    //     disablePadding: false,
    //     label: "E-Mail Opt-in",
    // },
    // {
    //     id: "otp_verified",
    //     numeric: true,
    //     disablePadding: false,
    //     label: <Stack
    //             direction="row"
    //             justifyContent="flex-end"
    //             alignItems="center"
    //         >
    //             <GppGoodIcon
    //                 style={{
    //                     marginRight: "5px",
    //                     marginTop: "-2px",
    //                 }}
    //             />
    //             2FA
    //         </Stack>
    //     ,
    // },
    {
        id: "acl",
        numeric: true,
        disablePadding: true,
        label: 'ACL'
    },
    {
        id: "enabled",
        numeric: true,
        disablePadding: true,
        label: "Aktiv",
    },
];

function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? "right" : "left"}
                        padding={"normal"}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === "desc"
                                        ? "sorted descending"
                                        : "sorted ascending"}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
};

export default function AdminUser(props) {
    const { rootPath, users, user_id, searchText, navigate } = props;
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("name");
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(50);

    const rows = GetRows({ users });

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const isSelected = (id) => {
        return user_id === id;
    };
    const handleClick = (event, row) => {
        navigate(`${rootPath}/${row.username}`);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    React.useEffect(() => {
        setPage(0);
    }, [searchText]);

    const filteredRows = React.useMemo(() => {
        const lowerSearchText = searchText.toLowerCase();
        return rows.filter(
            (row) =>
                row.name.toLowerCase().includes(lowerSearchText) ||
                row.email.toLowerCase().includes(lowerSearchText) ||
                row.company.toLowerCase().includes(lowerSearchText) ||
                row.permission_roles.toString().toLowerCase().includes(lowerSearchText)
                ,
        );
    }, [rows, searchText]);

    const visibleRows = React.useMemo(
        () =>
            stableSort(filteredRows, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            ),
        [filteredRows, order, orderBy, page, rowsPerPage],
    );

    return (
        <Box sx={{ width: "100%" }}>
            <Paper sx={{ width: "100%", mb: 2 }}>
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={"normal"}
                    >
                        <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                        />
                        <TableBody>
                            {visibleRows.map((row, index) => {
                                const labelId = `enhanced-table-checkbox-${index}`;
                                return (
                                    <TableRow
                                        hover
                                        onClick={(event) =>
                                            handleClick(event, row)
                                        }
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={row.username}
                                        selected={isSelected(row.username)}
                                        sx={{ cursor: "pointer" }}
                                    >
                                        <TableCell
                                            component="th"
                                            id={labelId}
                                            scope="row"
                                            padding="normal"
                                        >
                                            <Stack
                                                direction="row"
                                                justifyContent="flex-start"
                                                alignItems="center"
                                                spacing={3}
                                            >
                                                <Paper
                                                    elevation={0}
                                                    sx={{
                                                        borderRadius: 1,
                                                        padding: 1,
                                                        p: "0px 0px",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        backgroundColor: "#fff",
                                                        width: 40,
                                                        height: 40,
                                                    }}
                                                >
                                                    <Avatar
                                                        variant="rounded"
                                                        alt="Remy Sharp"
                                                        src={row.avatar_url ? row.avatar_url : null}
                                                        children={row.avatar_url ? null : `${row.name.split(' ')[0][0]}${row.name.split(' ')[1][0]}`}
                                                        sx={{
                                                            width: 40,
                                                            height: 40,
                                                            borderRadius: 1,
                                                            fontSize: 20,
                                                            color: row.avatar_url ? null : isSelected(row.username) ? "#fff" : "#000",
                                                            bgcolor: row.avatar_url ? null : isSelected(row.username) ? "rgb(0, 133, 233)" : "rgba(0,0,0,.045)",
                                                        }}
                                                    ></Avatar>
                                                </Paper>
                                                <span>{row.first_name} {row.last_name}</span>
                                            </Stack>
                                        </TableCell>
                                        <TableCell>
                                            {row.company}
                                        </TableCell>
                                        {/* <TableCell align="right">
                                            {row.type === "user" ? "Mensch" : "Maschine"}
                                        </TableCell>
                                        <TableCell align="right">
                                            {row.permission_roles.join(", ")}
                                        </TableCell> */}
                                        {/* <TableCell align="right">
                                            <Stack
                                                direction="row"
                                                justifyContent="flex-end"
                                                alignItems="center"
                                                spacing={1}
                                            >
                                                {(row.email_opt_in || row.type === 'application') ? (
                                                    <CheckCircleIcon
                                                        style={{
                                                            color: "rgb(40, 172, 46)",
                                                            marginLeft: 10,
                                                        }}
                                                    />
                                                ) : (
                                                    <CancelIcon
                                                        style={{
                                                            color: "rgba(193,39,45,.9)",
                                                            marginLeft: 10,
                                                        }}
                                                    />
                                                )}
                                            </Stack>
                                        </TableCell> */}
                                        {/* <TableCell align="right">
                                            {row.otp_verified ? (
                                                <CheckCircleIcon
                                                    style={{
                                                        color: "rgb(40, 172, 46)",
                                                    }}
                                                />
                                            ) : (
                                                <RadioButtonUncheckedIcon
                                                    style={{
                                                        color: "rgb(40, 172, 46)",
                                                    }}
                                                />
                                            )}
                                        </TableCell> */}
                                        <TableCell align="right">
                                            <Stack
                                                direction="row"
                                                justifyContent="flex-end"
                                                alignItems="center"
                                                spacing={1}
                                            >
                                                {row.acl ? (
                                                    <CheckCircleIcon
                                                        style={{
                                                            color: "rgb(40, 172, 46)",
                                                        }}
                                                    />
                                                ) : (
                                                    <RadioButtonUncheckedIcon
                                                        style={{
                                                            color: "rgb(40, 172, 46)",
                                                        }}
                                                    />
                                                )}
                                            </Stack>
                                        </TableCell>
                                        <TableCell align="right">
                                            <Stack
                                                direction="row"
                                                justifyContent="flex-end"
                                                alignItems="center"
                                                spacing={1}
                                            >
                                                {row.enabled ? (
                                                    <CheckCircleIcon
                                                        style={{
                                                            color: "rgb(40, 172, 46)",
                                                        }}
                                                    />
                                                ) : (
                                                    <CancelIcon
                                                        style={{
                                                            color: "rgba(193,39,45,.9)",
                                                        }}
                                                    />
                                                )}
                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    labelRowsPerPage="Zugänge pro Seite"
                    rowsPerPageOptions={[50, 100, 250]}
                    component="div"
                    count={filteredRows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </Box>
    );
}
